/*======================================
    44. Digital Store Pages Css
======================================*/
.digital-store-hero-area {
    background-image: url(../images/hero/aeroland-digital-store-hero-bg-reup.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.digital-store-hero__text {
    padding: 200px 0 90px;
    @media #{$large-mobile,$tablet-device}{
        padding: 100px 0 90px;
    }
}
.dighial-store-hero__sub {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    justify-content: center;
    & a {
        padding: 10px;
        &:hover {
            transform: translateY(-5px);
        }
    }
    p {
        color: $white;
        font-size: 18px;
    }
}
.digital-store-hero__image_meida {
    z-index: 1;
    position: relative;
}
.digital-store-main-content {
    background: url(../images/bg/aeroland-digital-store-image-01.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom 180px center;
}
.single-digital-product {
    &__wrap {
          position: relative;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
        overflow: hidden;
        transition: $transition--default;
        &:hover {
            transform: translateY(-5px);
        }
        & .s-product-badge {
            position: absolute;
            top: 0;
            right: 0;
            height: 90px;
            width: 140px;
            &::before {
                content: '';
                position: absolute;
                top: -30px;
                right: -52px;
                height: 90px;
                width: 140px;
                transform: rotate(45deg);
                background-image: linear-gradient(60deg, #ffbe00 0%, #f76b1c 100%);
            }
            & span {
                position: absolute;
                top: 22px;
                right: 5px;
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 1px;
                line-height: 1.19;
                display: block;
                color: #fff;
                transform: rotate(45deg);
                width: 50px;
            }
        }
    }
    
    &__content {
        padding: 30px 40px 40px;
        & .product-category {
            color: #6d70a6;
            margin-top: 5px;
        }
        & .product-desc {
            
        }
        & .product-footer {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 26px;
            & .regular-price {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                font-size: 18px;
                color: #ababab;
                padding: 0 5px;
                margin-right: 10px;
                text-decoration: line-through;
            }
            & .s-product-price {
                margin-left: 12px;
                & .price {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 24px;
                    color: #38cb89;
                    font-weight: 500;
                }
            }
        }
    }
}
.digital-store-countdown-wrapper.section-space--pt_120 {
    @media #{$laptop-device}{
        padding-top: 0px;
    }
    @media #{$desktop-device}{
        padding-top: 0;
    }
    @media #{$tablet-device,$large-mobile}{
        padding-top: 0;
    }
}