/*=============================================
=           32. Gallery Css           =
=============================================*/
.gallery-grid {
    display: grid;
    grid-template-columns: repeat( 3,1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    @media #{$tablet-device}{
        grid-template-columns: repeat( 2,1fr);
    }
    @media #{$large-mobile}{
        grid-template-columns: repeat( 1,1fr);
    } 
}
.grid-3 {
    display: grid;
    grid-template-columns: repeat( 3,1fr);
    @media #{$tablet-device}{
        grid-template-columns: repeat( 2,1fr);
    }
    @media #{$large-mobile}{
        grid-template-columns: repeat( 1,1fr);
    }
}
.gallery-grid-4 {
    display: grid;
    grid-template-columns: repeat( 4,1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    @media #{$tablet-device}{
        grid-template-columns: repeat( 2,1fr);
    }
    @media #{$large-mobile}{
        grid-template-columns: repeat( 1,1fr);
    }
}
.grid-height-2 {
    grid-row: span 2;
    @media #{$large-mobile}{
        grid-column: span 1;
    }
}
.grid-width-2 {
    grid-column: span 2;
    @media #{$large-mobile}{
        grid-column: span 1;
    }
}
.gallery-grid.grid-gap__none {
    grid-column-gap: 0;
    grid-row-gap: 0;
}
.masonary-item {
    overflow: hidden;
}
.single-gallery {
    display: block;
    position: relative !important;
    width: 100%;
    height: 100%;
}
.lg-actions {
    & button {
        background-image: linear-gradient(218deg,#5E61E7 0,#9C7AF2 50%,#5E61E7 100%);
        background-size: 200% auto;
        background-position: left center;
        color: $white !important;
        &:hover {
            background-image: linear-gradient(218deg,#5E61E7 0,#9C7AF2 50%,#5E61E7 100%);
            background-position: right center;
        }
    }
}
.single-gallery {
    overflow: hidden;
    display: block;
    &__item {
        position: relative; 
        & .ht-overlay {
            background-color: #5945e6;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            transition: $transition--default;
        }
        &  .overlay-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: auto;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            transition: $transition--default;
            & i {
                font-size: 24px;
                color: #fff;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &:hover {
            & .ht-overlay , & .overlay-icon {
                opacity: .9;
                visibility: visible;
            }
        }
    }
    &__thum {
        @media #{$large-mobile}{
            & img {
                width: 100%;
            }
        }
    }
}
.bg-item-images {
    position: relative;
    display: flex;
    align-items: stretch;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    &::before {
        padding-top: 67.6%;
        content: '';
        display: block;
    }
}
