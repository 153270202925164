/*=============================================
=        17. Gradation Process Css            =
=============================================*/

.icon-process-wrapper{
    display: flex;
    text-align: center;
    margin-bottom: -60px;

    @media #{$tablet-device, $large-mobile}{
        flex-direction: column;
    }
}

.icon-process-single{
    padding: 0 10px;
    position: relative;
    flex-grow: 1;
    margin-bottom: 60px;
    &__line{
        position: absolute;
        top: 60px;
        left: 100%;
        transform: translate(-50%, -5px);
        height: 10px;
        width: 100%;
        line-height: 0;

        @media #{$tablet-device, $large-mobile}{
            left: 50%;
            bottom: -30px;
            top: auto;
            transform: translate(-50%, 0);
        }

        .dot{
            display: inline-block;
            vertical-align: middle;
            background: #e4e8f6;
            border-radius: 50%;
            margin: 0 8px;
            &-1{
                width: 2px;
                height: 2px;
            }
            &-2{
                width: 4px;
                height: 4px;
            }
            &-3{
                width: 6px;
                height: 6px;
            }
            &-4{
                width: 8px;
                height: 8px;
            }
            &-5{
                width: 10px;
                height: 10px;
            }
        }
    }

    &:last-child{
        .icon-process-single__line{
            display: none;
        }
    }

    &__content-wrap{
        position: relative;
        text-align: center;
        margin: 0 auto;
        max-width: 320px;
    }

    &__media{
        position: relative;
        width: 120px;
        height: 120px;
        line-height: 120px;
        border-radius: 40px;
        font-size: 34px;
        background: $white;
        box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
        margin: 0 auto 52px;
        color: $theme-color--green;
        transition: $transition--default;

        @media #{$tablet-device, $large-mobile}{
            margin-bottom: 30px;
        }
        i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__title{
        color: #6d70a6;
        margin-bottom: 14px;
    }

    &:hover{
        .icon-process-single__media{
            background-color: $theme-color--green;
            color: $white;
        }
    }
}

/*=====  End of icon process  ======*/