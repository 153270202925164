/*============================
    CSS Index         
==============================

    01. Default Css
        - Breadvrumb 
        - preloader   
        - Newsletter popup
        - Scroll top 
        - Revulion Area
    02. Section Spacing Css
    03. Custom Animation Css
    04. container Css 
    05. Header Css 
        - Navigation Menu Css
        - Multilevel Submenu Css
        - Mega Menu Css
        - Mminicart Wrapper Css
        - Mobile Menu Overlay Css
        - offset Sidebar
        - search overlay
    06. Footer Css
    07. Sidebar Css
    08. Button Css 
    09. Accordions Css 
    10. Box Icon Css 
    11. Call To Action Css 
    12. Attribute Css 
    13. Contact Form And Newsletter Css 
    14. Countdown Css 
    15. Counters Css
    16. Listing Css 
    17. Gradation Process Css 
    18. Instagram Css 
    19. Message Box Css   
    20. Video Popup Css 
    21. Pricing table Css
    22. Pricing Plans Css  
    23. progress Bar Css 
    24. Circle Progress Css 
    25. Restaurant Menu Css
    26. Row Separators Css    
    27. Social Networks Css
    28. Tabs Css
    29. Team Member Css 
    30. Testimonial slider Css
    31. Timeline Css
    32. Gallery Css 
    33. Box image Css 
    34. Feature list Css
    35. Feature slider Css 
    36. Brand logo slider Css 
    37. Branding Page Css
    38. App Showcase Css 
    39. Software Pages Css
    40. App Landing Pages Css
    41. Digital Agency Pages Css
    42. Ebook Css
    43. Clinic Pages Css
    44. Digital Store Pages Css
    45. Product Showcase Pages Css
    46. Revolution Slider Css
    47. Blog Pages Css
    48. Portfolio Pages Css
    49. Portfolio Details Pages Css
    50. Shop Pages Css
    51. Shop Cart Css
    52. Shop Checkout Css 
    53. Preview Page Css 
    
    



/*=====  End of CSS Index  ======*/
/*=============================================
=             01. Default Css                 =
=============================================*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i");
*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.74;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: Rubik, Helvetica, Arial, sans-serif;
  color: #7e7e7e;
  position: relative;
  background-color: #ffffff;
}

body.no-overflow {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #2e3280;
  font-family: Rubik, Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.23;
}

h1 {
  font-size: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-size: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h4 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 24px;
  }
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 18px;
}

.drak-area h1, .drak-area h2, .drak-area h3, .drak-area h4, .drak-area h5, .drak-area h6 {
  color: #ffffff;
}

.drak-area .text {
  color: #A69Ec2;
}

.drak-area .check-list .list-item {
  color: #ffffff;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, span {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
  color: #5945e6;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.font-weight--light {
  font-weight: 400;
}

.font-weight--bold {
  font-weight: 500;
}

.bg-gray {
  background: #F5F7FD;
}

.bg-gray-2 {
  background: #f6f5f9;
}

.bg-gray-3 {
  background: #f5f5f5;
}

.theme_bg {
  background: #5945e6;
}

.theme_bg-2 {
  background: #2d1b6e;
}

.default-bg {
  background: linear-gradient(-259deg, #5E60E7 0, #9C7AF2 65%);
}

.gray-gradient {
  background: linear-gradient(top, #FFF 0, #F6F5F9 74%, #F6F5F9 100%);
  background: -ms-linear-gradient(top, #FFF 0, #F6F5F9 74%, #F6F5F9 100%);
}

.sub-heading {
  color: #6d70a6;
}

.black-bg {
  background: #000000;
}

.bg-gradient {
  background-color: #9C7AF2;
  background-image: -webkit-linear-gradient(134deg, #9C7AF2 0%, #5E61E7 100%);
  background-image: -o-linear-gradient(134deg, #9C7AF2 0%, #5E61E7 100%);
  background-image: linear-gradient(224deg, #9C7AF2 0%, #5E61E7 100%);
}

.border-radus-5 {
  border-radius: 5px;
}

.text-green {
  color: #38cb89;
}

/*-- Tab Content & Pane Fix --*/
select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px;
  background-color: #f8f8f8;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.fixed-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/*----------  Sharp border tippyjs theme  ----------*/
/* If `animateFill: true` (default) */
.tippy-tooltip.sharpborder__yellow-theme .tippy-backdrop {
  background-color: #5945e6;
  font-weight: 400;
  color: #fff;
}

/* If `animateFill: false` */
.tippy-tooltip.sharpborder__yellow-theme {
  font-weight: 400;
  border-radius: 0;
  font-size: 12px;
  background-color: #5945e6;
  color: #fff;
  line-height: 12px;
  white-space: nowrap;
  padding: 8px 10px;
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.sharpborder__yellow-theme .tippy-arrow {
  border-bottom-color: #5945e6;
}

/* If `animateFill: true` (default) */
.tippy-tooltip.sharpborder__black-theme .tippy-backdrop {
  background-color: #000000;
  font-weight: 400;
  color: #fff;
}

/* If `animateFill: false` */
.tippy-tooltip.sharpborder__black-theme {
  font-weight: 400;
  border-radius: 0;
  font-size: 12px;
  background-color: #000000;
  color: #fff;
  line-height: 12px;
  white-space: nowrap;
  padding: 8px 10px;
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip.sharpborder__black-theme .tippy-arrow {
  border-bottom-color: #000000;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.sharpborder__black-theme .tippy-arrow {
  border-top-color: #000000;
}

/*------- Defauld Class --------*/
.text-black {
  color: #333;
}

.border {
  border: 1px solid #ededed !important;
}

.border-top {
  border-top: 1px solid #ededed !important;
}

.border-right {
  border-right: 1px solid #ededed !important;
}

.border-bottom {
  border-bottom: 1px solid #ededed !important;
}

.border-left {
  border-left: 1px solid #ededed !important;
}

.border-top-dash {
  border-top: 1px dashed #ddd !important;
}

.border-bottom-dash {
  border-bottom: 1px dashed #ddd !important;
}

.border-top-thick {
  border-top: 2px solid #ededed !important;
}

.border-bottom-thick {
  border-bottom: 2px solid #ededed !important;
}

.border-top-drak {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-bottom-drak {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.section-title mark {
  color: #38cb89;
  padding: .0em;
  background-color: transparent;
}

.section-sub-title {
  color: #ee7455;
  font-size: 14px;
}

.thin-short-line {
  display: block;
  height: 2px;
  width: 120px;
  background: #222;
  margin: auto;
}

.thick-short-line {
  display: block;
  height: 4px;
  width: 120px;
  background: #222;
  margin: auto;
}

.separator-wrap {
  text-align: center;
  display: inline-block;
  margin-top: 40px;
}

.separator-wrap .dot {
  border-radius: 50%;
  box-shadow: 0 0 12px #def0ff;
  background: #000;
  background-color: #5945e6;
  animation: separator-bounce 1.4s ease-in-out 0s infinite both;
  width: 14px;
  height: 14px;
}

.separator-wrap .dot.second-circle {
  margin-top: 18px;
  animation-delay: -.16s;
}

.separator-wrap .dot.third-circle {
  margin-top: 14px;
  animation-delay: -.32s;
}

@-webkit-keyframes separator-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes separator-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

::selection {
  color: #ffffff;
  background-color: #5945e6;
}

.faq-banner-area, .saas-feature {
  z-index: 2;
}

/* wavify wrapper */
.wavify-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.wavify-wrapper svg {
  height: 380px;
}

.wavify-wrapper.top {
  top: 0;
}

.wavify-wrapper.app-landing-screnshot svg {
  height: 180px;
}

.wavify-wrapper.theme-two_lg svg {
  height: 550px;
}

.wavify-wrapper.rotate_wavify {
  bottom: 0;
  transform: translateY(-50%) rotateZ(180deg);
}

.wavify-wrapper.rotate_wavify svg {
  height: 450px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wavify-wrapper.rotate_wavify {
    transform: translateY(-30%) rotateZ(180deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wavify-wrapper.rotate_wavify {
    transform: translateY(-100%) rotateZ(180deg);
  }
}

@media only screen and (max-width: 767px) {
  .wavify-wrapper.rotate_wavify {
    transform: translateY(-100%) rotateZ(180deg);
  }
}

.wavify-wrapper.drak_hero {
  z-index: 3;
}

.wavify-wrapper.drak_hero svg {
  height: 200px;
}

.wavify-wrapper.wavify-lg svg {
  height: 680px;
}

.wavify-wrapper.payment_feature svg {
  height: 936px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .wavify-wrapper.payment_feature {
    transform: translateY(30%);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wavify-wrapper.payment_feature {
    transform: translateY(30%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wavify-wrapper.payment_feature {
    transform: translateY(30%);
  }
}

@media only screen and (max-width: 767px) {
  .wavify-wrapper.payment_feature {
    transform: translateY(30%);
  }
}

.wavify-wrapper.hero-white svg {
  height: 260px;
}

form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

form input:focus::-webkit-input-placeholder {
  color: transparent;
}

form input:focus:-moz-placeholder {
  color: transparent;
}

form input:focus::-moz-placeholder {
  color: transparent;
}

form input:focus:-ms-input-placeholder {
  color: transparent;
}

form input::placeholder, form textarea::placeholder {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
  color: #5945e6;
  border-color: #5945e6;
}

/* swiper default styles */
.swiper-pagination {
  position: static;
  display: block;
  line-height: 1;
}

.swiper-pagination--vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  bottom: auto;
  left: auto;
}

.swiper-pagination--vertical .swiper-pagination-bullet {
  display: block;
  margin-bottom: 10px;
  line-height: 1;
  margin-right: 0;
}

.swiper-pagination--vertical .swiper-pagination-bullet:last-child {
  margin-bottom: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: 0;
  border-radius: 0;
  background: transparent;
  opacity: 1;
  position: relative;
  outline: none;
  margin-right: 20px;
}

@media only screen and (max-width: 575px) {
  .swiper-pagination .swiper-pagination-bullet {
    margin-right: 0px;
  }
}

.swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.swiper-pagination .swiper-pagination-bullet:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background: #d8d8d8;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-pagination .swiper-pagination-bullet:hover:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #5945e6;
}

.swiper-pagination .swiper-pagination-bullet-active:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #5945e6;
}

.site-wrapper-reveal {
  background-color: #ffffff;
}

.swiper-pagination.swiper-pagination-2 .swiper-pagination-bullet {
  margin: 8px;
}

.swiper-pagination.swiper-pagination-2 .swiper-pagination-bullet:hover:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #fff;
}

.swiper-pagination.swiper-pagination-2 .swiper-pagination-bullet-active::before {
  opacity: 1;
  background: #fff;
  width: 16px;
  height: 16px;
  box-shadow: 0 0 9px #ededed;
}

.swiper-nav-button {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.1s linear 2s, opacity 0.1s linear 2s;
  background-image: none;
  text-align: center;
  user-select: none;
  outline: none;
  width: 48px;
  height: 48px;
  font-size: 24px;
  color: #6d70a6;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  opacity: 0;
  visibility: hidden;
  transform: translate(0, -50%);
  margin: 0;
  top: 50%;
}

.swiper-nav-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  color: #fff;
  background: #fff;
}

.swiper-nav-button:hover {
  color: #fff;
}

.swiper-nav-button:hover::before {
  color: #fff;
  background-color: #9C7AF2;
  background-image: linear-gradient(-129deg, #9C7AF2 0, #5E61E7 100%);
}

.swiper-container {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-container:hover .swiper-nav-button {
  opacity: 1;
  visibility: visible;
}

.swiper-nav-button i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-nav-button i::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: '\f104';
}

.swiper-nav-prev i::before {
  content: '\f104';
}

.swiper-button-next i::before {
  content: '\f105';
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.single-element-wrap ul li {
  margin-bottom: 15px;
}

.single-element-wrap ul li:last-child {
  margin-bottom: 0;
}

/*===================================
    - Breadvrumb  
=================================*/
.gradient--secondary {
  background-image: linear-gradient(-180deg, #fff 0, #eceefa 100%);
}

.breadcrumb-area {
  border-bottom-width: 0px;
  border-bottom-color: rgba(0, 0, 0, 0);
  padding-top: 196px;
  padding-bottom: 114px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.breadcrumb-list {
  margin-top: 15px;
}

.breadcrumb-list li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.58;
  text-transform: uppercase;
}

.breadcrumb-list li a {
  position: relative;
}

.breadcrumb-list li a::after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.breadcrumb-list li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
  z-index: 0;
}

.breadcrumb-list li.active {
  color: #6D70A6;
}

/* bg body color */
.bg-body-color {
  background: #3498DB;
}

.scroll_wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.scroll_wrapper .heading {
  color: #ffffff;
}

.page-pagination li {
  display: inline-block;
}

.page-pagination li a {
  font-weight: 500;
  padding: 0 10px;
  display: block;
  text-align: center;
  line-height: 41px;
  min-width: 41px;
  height: 41px;
  text-transform: uppercase;
  color: #ababab;
  letter-spacing: 2px;
  border-radius: 5px;
}

.page-pagination li a.current {
  background: #f6f5f9;
  color: #5945e6;
}

.page-pagination li a:hover {
  color: #5945e6;
}

.error404 {
  background: url("../images/bg/page-404-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.page-template-maintenance {
  width: 100%;
  height: 100%;
  text-align: center;
}

.page-template-maintenance .maintenance-image {
  height: 100vh;
}

.page-template-maintenance .left-bg {
  background-image: url("../images/other/page-maintenance-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  height: 100vh;
}

.maintenance-logo {
  margin-bottom: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .maintenance-logo {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .maintenance-logo {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .maintenance-logo {
    margin-bottom: 40px;
  }
}

.maintenance-title .section-title {
  color: #6d70a6;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .countdown-wrapper-maintenance .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 60px;
  }
}

.maintenance-newsletter {
  margin-top: 50px;
}

.maintenance-newsletter .newsletter-form--two input {
  min-width: 100%;
  text-align: center;
}

.billing-details-wrap {
  max-width: 380px;
  margin: auto;
}

.login-form-box-wrap {
  padding: 25px 20px;
  max-width: 380px;
  margin: 40px auto;
  margin-top: 40px;
  margin-top: 15px;
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0 15px 40px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 40px 5px rgba(0, 0, 0, 0.1);
}

/*=============================================
=                 - preloader                =
=============================================*/
.preloader-active {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
}

.preloader-active .preloader-area-wrap {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  display: block;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
}

.preloader-active .preloader-area-wrap .spinner div {
  background-color: #5945e6;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.preloader-active .preloader-area-wrap .spinner div.bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.preloader-active .preloader-area-wrap .spinner div.bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.preloader-area-wrap {
  display: none;
}

.open_tm_preloader {
  position: fixed;
  background-color: transparent;
  z-index: 9999;
  height: 100%;
  width: 100%;
  -webkit-transition: .2s all ease;
  -o-transition: .2s all ease;
  transition: .2s all ease;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.open_tm_preloader.loaded {
  opacity: 0;
  visibility: hidden;
}

.open_tm_preloader.loaded:before, .open_tm_preloader.loaded:after {
  width: 0%;
}

.open_tm_preloader:before, .open_tm_preloader:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
}

.open_tm_preloader:before {
  top: 0;
  left: 0;
}

.open_tm_preloader:after {
  bottom: 0;
  right: 0;
}

/*=============================================
=            - Newsletter popup            =
=============================================*/
.newsletter-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(17, 17, 17, 0.5);
  opacity: 0;
  visibility: hidden;
  text-align: center;
  cursor: url(../images/icons/light-close.png) 16 16, pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (max-width: 767px) {
  .newsletter-popup.active {
    display: none;
  }
}

.newsletter-popup__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 370px;
  max-width: 80%;
  background: #fff;
  border-radius: 5px;
  transform: translate(-50%, -50%) scale(1.1, 1.1);
  padding: 41px 30px 50px;
  cursor: default;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.newsletter-popup__close {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 6px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.newsletter-popup__close:hover {
  color: #5945e6;
}

.newsletter-popup__title {
  margin-bottom: 15px;
}

.newsletter-popup__media {
  margin-bottom: 15px;
}

.newsletter-popup__form form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

.newsletter-popup__form form input {
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}

.newsletter-popup__form form input:focus::-webkit-input-placeholder {
  color: transparent;
}

.newsletter-popup__form form input:focus:-moz-placeholder {
  color: transparent;
}

.newsletter-popup__form form input:focus::-moz-placeholder {
  color: transparent;
}

.newsletter-popup__form form input:focus:-ms-input-placeholder {
  color: transparent;
}

.newsletter-popup__form form input::placeholder, .newsletter-popup__form form textarea::placeholder {
  color: #ababab;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.newsletter-popup.active {
  visibility: visible;
  opacity: 1;
}

.newsletter-popup.active .newsletter-popup__inner {
  transform: translate(-50%, -50%) scale(1, 1);
}

/*=============================================
   - Scroll top         
=============================================*/
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #9C7AF2;
  background-image: linear-gradient(-218deg, #9C7AF2 0, #5E61E7 50%, #9C7AF2 100%);
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}

.scroll-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.scroll-top .arrow-top {
  transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
  transform: translate(-50%, 80px);
}

.scroll-top:hover {
  background-position: right center;
}

.scroll-top:hover .arrow-top {
  transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
  transform: translate(-50%, -50%);
}

/* =====================
    - Revulion Area 
========================*/
.rvbody {
  background: #3498DB;
}

.scroll_wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.scroll_wrapper .heading {
  color: #ffffff;
}

/* FullPage Css */
.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.color-bg-1 {
  background: #FF5F45;
}

.color-bg-2 {
  background: #0798EC;
}

.color-bg-3 {
  background: #FC6C7C;
}

.color-bg-4 {
  background: #FEC401;
}

#page-footer-wrapper {
  transform: translateY(100%);
}

.tm-one-page-footer-expanded #page-footer-wrapper {
  transform: translateY(-100%);
  position: relative;
  z-index: 2;
  transition: transform .6s ease;
}

#fp-nav,
.fp-slidesNav {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
}

#fp-nav {
  margin-top: -32px;
  top: 50%;
  position: fixed;
  z-index: 10;
  right: 67px;
  padding: 0;
  margin: 0;
}

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important;
}

.fp-slidesNav.fp-bottom {
  bottom: 17px;
}

.fp-slidesNav.fp-top {
  top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 8px;
  width: 8px;
  border: 0;
  background: #fff;
  left: 50%;
  top: 50%;
  margin: -4px 0 0 -4px;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}

/*google Map*/
#googleMap-1 {
  height: 500px;
}

#googleMap-2 {
  height: 500px;
}

#googleMap-3 {
  height: 500px;
}

/*=====  End of Default CSS  ======*/
/*=============================================
=              02. Section Spacing Css       =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}

.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}

.section-space--pb_100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_90 {
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 40px;
  }
}

.section-space--pb_90 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-space--pt_70 {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}

.section-space--pb_70 {
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_60 {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

.section-space--pb_60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

.section-space--pt_40 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

.section-space--pb_40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-space--mt_15 {
  margin-top: 15px;
}

.section-space--mt_20 {
  margin-top: 20px;
}

.section-space--mt_30 {
  margin-top: 30px;
}

.section-space--mt_40 {
  margin-top: 40px;
}

.section-space--mt_50 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}

.section-space--mt_60 {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}

.section-space--mt_70 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}

.section-space--mt_80 {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}

.section-space--mt_100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}

.section-space--mt_120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}

.section-space--mb_15 {
  margin-bottom: 15px;
}

.section-space--mb_20 {
  margin-bottom: 20px;
}

.section-space--mb_30 {
  margin-bottom: 30px;
}

.section-space--mb_40 {
  margin-bottom: 40px;
}

.section-space--mb_50 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}

.section-space--mb_60 {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}

.section-space--mb_100 {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}

.section-space--mb_120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .small-mt__10 {
    margin-top: 10px;
  }
  .small-mt__20 {
    margin-top: 20px;
  }
  .small-mt__30 {
    margin-top: 30px;
  }
  .small-mt__40 {
    margin-top: 40px;
  }
  .small-mb__30 {
    margin-bottom: 30px;
  }
  .small-mb__40 {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__30 {
    margin-top: 30px;
  }
  .tablet-mt__40 {
    margin-top: 40px;
  }
  .tablet-mb__30 {
    margin-bottom: 30px;
  }
  .tablet-mb__40 {
    margin-bottom: 40px;
  }
}

/*=====  End of spacing  ======*/
/*===========================================
=        03. Custom Animation Css
===========================================*/
@-webkit-keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes moveHorizontal {
  to {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveHorizontal {
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.move-up {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.move-up.animate {
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-animation: moveVertical .65s ease forwards;
  animation: moveVertical .65s ease forwards;
}

.move-up-x {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.move-up-x.animate {
  -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-animation: moveHorizontal .65s ease forwards;
  animation: moveHorizontal .65s ease forwards;
}

/* ------------------------
    Custom Animation 01 
----------------------------*/
@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}

/* --------------------------------------
    Custom Hide Animation
---------------------------------------*/
@-webkit-keyframes hide-animation {
  0% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(60px);
    opacity: 0;
  }
}

@keyframes hide-animation {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
  }
}

/* --------------------------------------
    Custom Slide Show Animation
---------------------------------------*/
@-webkit-keyframes show-animation {
  0% {
    -webkit-transform: translateY(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes show-animation {
  0% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* --------------------------------------
    Custom Slide Out To Right
---------------------------------------*/
@-webkit-keyframes slide-out-to-right {
  0% {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(40px);
    opacity: 0;
  }
}

@keyframes slide-out-to-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
    opacity: 0;
  }
}

/* --------------------------------------
    Custom Slide Out To Left
---------------------------------------*/
@-webkit-keyframes slide-in-to-left {
  0% {
    -webkit-transform: translateX(60px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-to-left {
  0% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ------------------------
    Brook Hover Effect 
---------------------------*/
.brook-transition {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.bk-hover a {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  color: #999;
}

.bk-hover a::after {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}

.bk-hover a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

/* ------------------------
    Separator Bounce 
---------------------------*/
@-webkit-keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ====================
    Toolbar Area 
========================*/
.aeroland__toolbar .inner {
  position: absolute;
  top: 200px;
  right: 100%;
  display: block;
  width: 40px;
  border: 1px solid #eee;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #fff;
  text-align: center;
  box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
}

.aeroland__toolbar .inner a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #222;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.demo-option-wrapper {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 55px;
}

.demo-option-wrapper .demo-panel-header {
  padding: 40px 30px 30px;
  text-align: center;
}

.demo-option-container {
  position: fixed;
  top: 0;
  left: 100%;
  background: #fff;
  height: 100%;
  width: 350px;
  max-width: calc(100% - 45px);
  z-index: 100000;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.demo-option-container.open {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
  box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
}

.demo-quick-option-list {
  padding: 27px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.demo-quick-option-list a img {
  border-radius: 5px;
}

.demo-quick-option-list a:hover img {
  transform: translateY(-3px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

/*=============================================
=            04. container Css            =
=============================================*/
@media (min-width: 1600px) {
  .container-fluid--cp-150 {
    padding: 0 150px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-150 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-150 {
    padding: 0 50px;
  }
}

@media (min-width: 1700px) {
  .container-fluid--cp-140 {
    padding: 0 140px !important;
  }
}

@media (min-width: 1700px) and (max-width: 1663px) {
  .container-fluid--cp-140 {
    padding: 0 100px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-140 {
    padding: 0 30px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-140 {
    padding: 0 30px;
  }
}

@media (min-width: 1600px) {
  .container-fluid--cp-120 {
    padding: 0 120px !important;
  }
}

@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-120 {
    padding: 0 110px !important;
  }
}

@media (min-width: 1200px) {
  .container-fluid--cp-120 {
    padding: 0 50px;
  }
}

.container-fluid--cp-30 {
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .container-fluid--cp-30 {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .page-content-double-sidebar .container {
    max-width: 1600px;
  }
}

@media (max-width: 1919px) {
  .container-fluid--cp-60 {
    padding: 0 30px;
  }
  .container-fluid--cp-80 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1919px) and (max-width: 767px) {
  .container-fluid--cp-80 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1919px) and (max-width: 575px) {
  .container-fluid--cp-80 {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container_fl {
  padding-left: 15px;
  padding-right: 15px;
}

.col-06__left {
  width: 600px;
  max-width: 100%;
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__left {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.col-06__right {
  width: 600px;
  max-width: 100%;
  float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__right {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.banner-image__content {
  width: 570px;
  max-width: 100%;
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--35 > [class*="col"],
.row--35 > [class*="col-"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--40 {
  margin-left: -40px;
  margin-right: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--40 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--40 > [class*="col"],
.row--40 > [class*="col-"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/*=====  End of container  ======*/
/*=============================================
=              05. Header Css            =
=============================================*/
.header-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.header-area.is-sticky .navigation-menu > ul > li > a {
  padding-top: 30px;
  padding-bottom: 30px;
}

.header-area.is-sticky .navigation-menu--text_white > ul > li > a {
  color: #444;
}

.header-area.is-sticky .navigation-menu--text_white > ul > li > a > span:after {
  background-color: #444;
}

.header-area.is-sticky .navigation-menu--text_white > ul > li.active > a {
  color: #000000;
}

.header-area.is-sticky .navigation-menu--text_white > ul > li:hover > a:after, .header-area.is-sticky .navigation-menu--text_white > ul > li.active > a:after {
  color: #000000;
}

.header-area.is-sticky .navigation-menu--text_white > ul > li:hover > a > span, .header-area.is-sticky .navigation-menu--text_white > ul > li.active > a > span {
  color: #000000;
}

.header-area.is-sticky .header-button > a.ht-btn {
  padding: 0 30px;
  height: 48px;
  line-height: 44px;
}

.header-area.is-sticky .header-button.button--white > a {
  padding: 0 28px;
  height: 46px;
  line-height: 46px;
}

.header-area.is-sticky .header-button.button--white > a.btn {
  color: #5945e6;
  border: 2px solid #5945e6;
}

.header-area.is-sticky .header-button.button--white > a.btn:hover {
  transform: translateY(-3px);
  border-color: transparent;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
}

.header-area.is-sticky .header__logo .dark-logo {
  display: inherit;
}

.header-area.is-sticky .header__logo .light-logo {
  display: none;
}

.header-area.is-sticky.header-style-three {
  min-height: 80px;
}

.header-area.is-sticky.header-style-three .search-icon > a, .header-area.is-sticky.header-style-three .minicart-wrapper .minicart-icon {
  color: #5945e6;
}

.header-area.is-sticky.header-style-three .minicart-wrapper .minicart-icon .cart_count {
  color: #fff;
  background: #5945e6;
}

.header-area.is-sticky.header-style-three .header-button a {
  color: #5945e6;
  border: 2px solid #5945e6;
  background: transparent;
  padding: 0 25px;
  line-height: 48px;
}

.header-area.is-sticky.header-style-three .header-button a:hover {
  transform: translateY(-3px);
  border-color: transparent;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
}

.header-area.is-sticky.header-style-three .hidden-icons-menu a {
  color: #6D70A6;
}

.header-area.is-sticky .icon-color-white .search-icon > a, .header-area.is-sticky .icon-color-white .minicart-wrapper .minicart-icon {
  color: #5945e6;
}

.header-area.is-sticky .icon-color-white .minicart-wrapper .minicart-icon .cart_count {
  color: #fff;
  background: #5945e6;
}

.header-area.is-sticky .open-main-menu-icon:hover i, .header-area.is-sticky .mobile-navigation-icon:hover i {
  background-color: #5945e6;
}

.header-area.is-sticky .open-main-menu-icon i, .header-area.is-sticky .mobile-navigation-icon i {
  background-color: #5945e6;
}

.header-area.is-sticky .open-main-menu-icon i:before, .header-area.is-sticky .mobile-navigation-icon i:before {
  background-color: #5945e6;
}

.header-area.is-sticky .open-main-menu-icon i:after, .header-area.is-sticky .mobile-navigation-icon i:after {
  background-color: #5945e6;
}

.header-area.is-sticky .header__actions--four .ht-social-networks .item a {
  color: #7e7e7e;
}

.header-area.is-sticky .header__actions--four .ht-social-networks .item a:hover {
  color: #ffffff;
}

.header-area--absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-area--absolute {
    position: static;
  }
}

.header-area--absolute__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-style-three.header-area--absolute {
    position: absolute;
  }
}

.header-style-three.header-area--absolute.is-sticky {
  position: fixed;
}

.header {
  display: flex;
  align-items: center;
  /* logo */
  /* navigation */
  /* header action */
}

.header__logo {
  flex-basis: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__logo {
    flex-basis: 30%;
  }
}

@media only screen and (max-width: 575px) {
  .header__logo {
    flex-basis: 50%;
  }
}

.header__logo > a img {
  width: 180px;
}

.header__logo .dark-logo {
  display: none;
}

.header__logo .light-logo {
  display: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__logo .dark-logo {
    display: inherit;
  }
  .header__logo .light-logo {
    display: none;
  }
}

.header__navigation {
  flex-grow: 1;
}

.header__navigation .navigation-menu > ul > li > a {
  padding: 50px 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header__navigation .navigation-menu > ul > li > a {
    padding: 50px 15px;
  }
}

.header__navigation--four {
  flex-grow: 1;
  display: flex !important;
  align-items: center;
  justify-content: flex-end !important;
}

.header__actions {
  flex-basis: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header__actions {
    flex-basis: 26%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__actions {
    flex-basis: 70%;
  }
}

@media only screen and (max-width: 575px) {
  .header__actions {
    flex-basis: 50%;
  }
}

.header__actions--four {
  flex-basis: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header__actions--four {
    flex-basis: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__actions--four {
    flex-basis: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__actions--four {
    flex-basis: 70%;
  }
}

@media only screen and (max-width: 575px) {
  .header__actions--four {
    flex-basis: 50%;
  }
}

.header__actions--four .ht-social-networks .item a {
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__actions--four .ht-social-networks .item a {
    color: #7e7e7e;
  }
  .header__actions--four .ht-social-networks .item a:hover {
    color: #ffffff;
  }
}

.header__actions--five {
  flex-basis: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__actions--five {
    flex-basis: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__actions--five {
    flex-basis: 70%;
  }
}

@media only screen and (max-width: 575px) {
  .header__actions--five {
    flex-basis: 50%;
  }
}

.header__actions--five .search-icon > a {
  color: #333;
}

.header__icons-wrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .header__icons-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    justify-content: center;
    padding: 10px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
    transform: translateY(30px);
    background-color: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

@media only screen and (max-width: 767px) {
  .header__icons-wrapper.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    z-index: 999;
  }
}

.header--style-center .header__share {
  flex-basis: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header--style-center .header__share {
    flex-basis: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .header--style-center .header__share {
    flex-basis: 35%;
  }
}

@media only screen and (max-width: 575px) {
  .header--style-center .header__share {
    flex-basis: 50%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header--style-center .header__navigation .navigation-menu > ul > li > a {
    padding: 40px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .header--style-center .navigation-menu {
    padding: 25px;
  }
}

.header--style-center .header__actions {
  flex-basis: 20%;
}

.header--style-center .header__actions .search-icon {
  padding-left: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header--style-center .header__actions {
    flex-basis: 22%;
  }
  .header--style-center .header__actions .ht-btn {
    padding: 0 24px;
  }
  .header--style-center .header__actions .header-button {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header--style-center .header__actions {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .header--style-center .header__actions {
    flex-basis: 50%;
  }
}

/* open main menu */
.open-main-menu-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 30px;
}

.open-main-menu-icon:hover i {
  background-color: #ffffff;
}

.open-main-menu-icon:hover i:before {
  width: 80%;
  background-color: #ffffff;
}

.open-main-menu-icon:hover i:after {
  background-color: #ffffff;
  width: 60%;
}

.open-main-menu-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.open-main-menu-icon i:before {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  content: "";
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.open-main-menu-icon i:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  content: "";
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.open-menuberger-wrapper {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #5E61E7;
  background-image: linear-gradient(138deg, #5E61E7 0%, #9C7AF2 100%);
  padding: 30px 0;
  width: 100%;
  height: 100%;
  text-align: center;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -11;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  transform: scale(0.3);
  overflow: hidden;
  overflow-y: hidden;
}

.open-menuberger-wrapper.is-visiable {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  z-index: 9999;
}

.open-menuberger-wrapper .page-close {
  position: fixed;
  top: 80px;
  right: 90px;
  z-index: 100000;
  font-size: 100px;
  line-height: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.open-menuberger-wrapper .page-close::after, .open-menuberger-wrapper .page-close::before {
  position: absolute;
  top: 19px;
  left: 4px;
  content: "";
  width: 32px;
  height: 3px;
  background: #ffffff;
  transform-origin: 50% 50%;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.open-menuberger-wrapper .page-close::before {
  transform: rotate(-45deg);
}

.open-menuberger-wrapper .page-close::after {
  transform: rotate(45deg);
}

.open-menuberger-wrapper .nav-open-menuberger li {
  margin-bottom: 25px;
}

.open-menuberger-wrapper .nav-open-menuberger li:last-child {
  margin-bottom: 0;
}

.open-menuberger-wrapper .nav-open-menuberger li a {
  color: #ffffff;
  font-weight: 500;
  line-height: 45px;
  font-size: 40px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.open-menuberger-wrapper .nav-open-menuberger li a:hover {
  color: #38cb89;
}

.icon-color-white .search-icon > a, .icon-color-white .minicart-wrapper .minicart-icon {
  color: #ffffff;
}

.icon-color-white .minicart-wrapper .minicart-icon .cart_count {
  color: #333;
  background: #fff;
}

.header-style-three {
  display: flex;
  align-items: center;
  min-height: 120px;
}

.header-style-three .search-icon > a, .header-style-three .minicart-wrapper .minicart-icon {
  color: #ffffff;
}

.header-style-three .minicart-wrapper .minicart-icon .cart_count {
  color: #333;
  background: #fff;
}

.header-style-three .header-button a {
  color: #fff;
  border-color: rgba(46, 50, 128, 0.2);
  background: rgba(46, 50, 128, 0.2);
  padding: 0 30px;
  border-radius: 5px;
  line-height: 48px;
  border: 2px solid rgba(101, 105, 187, 0.2);
}

@media only screen and (max-width: 767px) {
  .header-style-three {
    min-height: 80px;
  }
  .header-style-three .search-icon > a, .header-style-three .minicart-wrapper .minicart-icon {
    color: #5945e6;
  }
  .header-style-three .minicart-wrapper .minicart-icon .cart_count {
    color: #fff;
    background: #5945e6;
  }
  .header-style-three .header-button a {
    color: #5945e6;
    border: 2px solid #5945e6;
    background: transparent;
    padding: 0 25px;
    line-height: 48px;
  }
  .header-style-three .header-button a:hover {
    transform: translateY(-3px);
    border-color: transparent;
    background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
    background-size: 200% auto;
    background-position: left center;
    color: #ffffff;
  }
}

.header-style-three .mobile-navigation-icon {
  margin-right: 30px;
  margin-left: 0;
}

.header-style-three .mobile-navigation-icon:hover i {
  background-color: #ffffff;
}

.header-style-three .mobile-navigation-icon i {
  background-color: #ffffff;
}

.header-style-three .mobile-navigation-icon i:before {
  background-color: #ffffff;
}

.header-style-three .mobile-navigation-icon i:after {
  background-color: #ffffff;
}

.header-style-three .hidden-icons-menu a {
  color: #ffffff;
}

/*============================== 
    - Navigation Menu Css
===============================*/
.navigation-menu > ul {
  display: flex;
  justify-content: center;
}

.navigation-menu > ul > li > a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #ababab;
  padding: 50px 25px;
}

.navigation-menu > ul > li > a > span {
  position: relative;
}

.navigation-menu > ul > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #333333;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navigation-menu > ul > li.has-children > a {
  position: relative;
}

.navigation-menu > ul > li.has-children > a:after {
  position: static;
  margin-left: 5px;
  font-family: "Font Awesome 5 Pro";
  content: '\f107';
  font-size: 14px;
  vertical-align: middle;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navigation-menu > ul > li.has-children--multilevel-submenu {
  position: relative;
}

.navigation-menu > ul > li.has-children:hover .megamenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li.has-children:hover .megamenu--home-variation__item {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navigation-menu > ul > li.has-children:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li:hover > a:after, .navigation-menu > ul > li.active > a:after {
  color: #333333;
}

.navigation-menu > ul > li:hover > a > span, .navigation-menu > ul > li.active > a > span {
  color: #333333;
}

.navigation-menu > ul > li:hover > a > span:after, .navigation-menu > ul > li.active > a > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.navigation-menu--onepage > ul > li.active > a > span:after {
  display: none;
}

.navigation-menu--text_white > ul > li > a {
  color: #ffffff;
}

.navigation-menu--text_white > ul > li > a > span:after {
  background-color: #ffffff;
}

.navigation-menu--text_white > ul > li.active > a {
  color: #ffffff;
}

.navigation-menu--text_white > ul > li:hover > a:after, .navigation-menu--text_white > ul > li.active > a:after {
  color: #ffffff;
}

.navigation-menu--text_white > ul > li:hover > a > span, .navigation-menu--text_white > ul > li.active > a > span {
  color: #ffffff;
}

/*===================================
    - Multilevel Submenu Css
====================================*/
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid #5945e6;
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.3s;
  transition-duration: 0.6s;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  min-width: 270px;
  padding: 25px 0;
  z-index: 9;
}

.submenu > li {
  position: relative;
}

.submenu > li > a {
  display: block;
  padding: 11px 30px;
  color: #ababab;
  font-weight: 400;
  transition: 0s;
}

.submenu > li > a > span {
  position: relative;
}

.submenu > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #5945e6;
  transition: 0.3s;
}

.submenu > li > a:hover {
  color: #5945e6;
}

.submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.submenu > li:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  z-index: 9;
}

.submenu > li.active > a {
  color: #5945e6;
}

.submenu > li.has-children > a {
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.submenu > li.has-children > a:after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  content: '\f105';
  font-size: 14px;
  vertical-align: middle;
  color: #ababab;
}

.submenu > li.has-children > a:hover:after {
  color: #5945e6;
}

.submenu > li.has-children.active > a {
  color: #5945e6;
}

.submenu .submenu {
  top: -100px;
  left: 100%;
  right: auto;
}

.submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

/*=========================================
    - Mega Menu Css
===========================================*/
.megamenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid #5945e6;
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.3s;
  transition-duration: 0.6s;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  border-radius: 5px;
}

.megamenu--mega {
  min-width: 980px;
  width: 100%;
  padding: 20px 200px 30px;
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    min-width: 700px;
  }
}

.megamenu--mega > li {
  flex-basis: 22%;
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu--mega > li .page-list-title {
  font-size: 14px;
  margin-bottom: 20px;
  color: #333333;
}

.megamenu--mega > li > ul > li > a {
  padding: 10px 0;
  color: #ababab;
  line-height: 1.2;
  transition: 0.1s;
}

.megamenu--mega > li > ul > li > a:hover {
  color: #5945e6;
}

.megamenu--mega > li > ul > li > a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.megamenu--mega > li > ul > li > a > span {
  position: relative;
}

.megamenu--mega > li > ul > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #5945e6;
  transition: 0.3s;
}

.megamenu--mega > li > ul > li.active > a {
  color: #5945e6;
}

/*=================================
    - Mminicart Wrapper Css
==================================*/
.minicart-wrapper {
  position: relative;
  /* minicart */
}

.minicart-wrapper .minicart-icon {
  color: #6D70A6;
  position: relative;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  padding-right: 15px;
}

.minicart-wrapper .minicart-icon .cart_count {
  position: absolute;
  top: -4px;
  right: 0;
  padding: 0 4px;
  min-width: 15px;
  height: 15px;
  border-radius: 15px;
  color: #ffffff;
  background: #5945e6;
  content: attr(data-count);
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.minicart-wrapper .minicart-icon:hover {
  color: #5945e6;
  transform: scale(1.15, 1.15);
}

.minicart-wrapper .minicart-box {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99999;
  visibility: hidden;
  overflow: auto;
  padding: 30px 20px 27px;
  max-height: 700px;
  width: 360px;
  border-bottom: 2px solid #5945e6;
  background-color: #fff;
  opacity: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  transform: translateY(30px);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.minicart-wrapper .minicart-box.active {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.minicart-wrapper .minicart-product-wrapper {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product {
  display: flex;
  margin-bottom: 20px;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__image {
  flex-basis: 80px;
  border-radius: 3px;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__image img {
  border-radius: 3px;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__image a {
  display: block;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__content {
  flex-basis: calc(100% - 80px);
  margin-left: 15px;
  position: relative;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__content .close-icon {
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 700;
  color: #888;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__content .close-icon i {
  font-size: 12px;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__content .close-icon:hover {
  color: #333333;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__content .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #7e7e7e;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__content .title:hover {
  color: #5945e6;
}

.minicart-wrapper .minicart-product-wrapper .single-minicart-product__content .quantity {
  font-size: 15px;
  color: #7e7e7e;
}

.minicart-wrapper .minicart-calculations {
  font-size: 15px;
  color: #7e7e7e;
  font-weight: 500;
  margin-bottom: 30px;
}

.minicart-wrapper .minicart-calculations .value {
  float: right;
  font-weight: 400;
}

.minicart-wrapper .minicart-buttons a {
  min-width: 48%;
}

.minicart-wrapper .minicart-buttons a:nth-child(2) {
  float: right;
}

/* search */
.search-icon {
  padding-left: 30px;
}

.search-icon > a {
  font-size: 20px;
  color: #6D70A6;
}

.search-icon > a:hover {
  color: #5945e6;
  transform: scale(1.15, 1.15);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .search-icon {
    padding-left: 25px;
  }
}

/* header button */
.header-button {
  padding-left: 30px;
  padding-right: 0;
}

@media only screen and (max-width: 479px) {
  .header-button a.ht-btn {
    padding: 0 28px;
  }
}

.header-button.button--white {
  padding-left: 20px;
}

.header-button.button--white > a.btn {
  color: #5945e6;
}

@media only screen and (max-width: 479px) {
  .header-button.button--white > a.btn {
    padding: 0 28px;
  }
}

/* Mobile Mavigation icon */
.mobile-navigation-icon, .page-open-off-sidebar {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
}

.mobile-navigation-icon:hover i, .page-open-off-sidebar:hover i {
  background-color: #5945e6;
}

.mobile-navigation-icon:hover i:before, .page-open-off-sidebar:hover i:before {
  width: 80%;
  background-color: #5945e6;
}

.mobile-navigation-icon:hover i:after, .page-open-off-sidebar:hover i:after {
  background-color: #5945e6;
  width: 60%;
}

.mobile-navigation-icon i, .page-open-off-sidebar i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #6D70A6;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:before, .page-open-off-sidebar i:before {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #6D70A6;
  content: "";
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:after, .page-open-off-sidebar i:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #6D70A6;
  content: "";
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.page-open-off-sidebar:hover i {
  width: 100%;
}

.page-open-off-sidebar:hover i:before {
  width: 100%;
}

.page-open-off-sidebar:hover i:after {
  width: 100%;
}

.page-open-off-sidebar i {
  width: 100%;
  background-color: #333;
}

.page-open-off-sidebar i:before {
  width: 70%;
  background-color: #333;
}

.page-open-off-sidebar i:after {
  width: 70%;
  background-color: #333;
}

.hidden-icons-menu {
  margin-left: 15px;
}

.hidden-icons-menu a {
  color: #6D70A6;
  font-size: 20px;
}

.hidden-icons-menu a:hover {
  color: #5945e6;
}

/*================================ 
    - Mobile Menu Overlay Css
==================================*/
.mobile-menu-overlay, .page-oppen-off-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  overflow: auto;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  cursor: url(../images/icons/light-close.png) 16 16, pointer;
  visibility: hidden;
  opacity: 0;
}

.mobile-menu-overlay__inner, .page-oppen-off-sidebar__inner {
  transform: translateX(-100%);
  width: 400px;
  height: 100%;
  cursor: default;
  background-color: #5E61E7;
  background-image: linear-gradient(138deg, #5E61E7 0, #9C7AF2 100%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: auto;
}

@media only screen and (max-width: 479px) {
  .mobile-menu-overlay__inner, .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.mobile-menu-overlay.active, .page-oppen-off-sidebar.active {
  visibility: visible;
  opacity: 1;
}

.mobile-menu-overlay.active .mobile-menu-overlay__inner, .page-oppen-off-sidebar.active .mobile-menu-overlay__inner {
  transform: translateX(0);
}

.mobile-menu-overlay__header, .page-oppen-off-sidebar__header {
  background-color: #ffffff;
  padding: 15px 0;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon, .page-oppen-off-sidebar__header .mobile-navigation-close-icon {
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:before, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:before {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:after, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:after {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover {
  color: #5945e6;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover:before, .mobile-menu-overlay__header .mobile-navigation-close-icon:hover:after, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover:before, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover:after {
  transform: none;
}

.mobile-menu-overlay__body, .page-oppen-off-sidebar__body {
  padding: 20px 40px 100px;
}

.mobile-menu-overlay__body .offcanvas-navigation, .page-oppen-off-sidebar__body .offcanvas-navigation {
  /* onepage style */
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li > a {
  display: block;
  color: #ffffff;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li > a:hover {
  color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li:last-child, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before {
  content: '\f106';
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 12px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 18px;
  font-family: "Font Awesome 5 Pro";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu {
  padding: 12px 0 14px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li a {
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover {
  color: #ffffff;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 6px;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation--onepage > ul > li.active > a, .page-oppen-off-sidebar__body .offcanvas-navigation--onepage > ul > li.active > a {
  font-weight: 700;
}

/*======================================
    - offset Sidebar
======================================*/
.page-oppen-off-sidebar {
  right: 0;
}

.page-oppen-off-sidebar__inner {
  transform: translateX(100%);
  width: 400px;
  float: right;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.page-oppen-off-sidebar.active .page-oppen-off-sidebar__inner {
  transform: translateX(0%);
}

.page-oppen-off-sidebar__header {
  background: #ffffff;
  padding: 15px 35px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item {
  display: flex;
  margin-bottom: 12px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item .icon {
  color: #6d70a6;
  min-width: calc(1em + 18px);
  font-size: 16px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info--list .item a:hover {
  color: #38cb89;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list {
  overflow: hidden;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li {
  width: 50%;
  float: left;
  margin-bottom: 8px;
}

.page-oppen-off-sidebar .off-sidebar-contact-info .off-sidebar-widget__list li a {
  line-height: 17px;
}

/*===============================
    - search overlay
===============================*/
.search-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  overflow: auto;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  cursor: url(../images/icons/light-close.png) 16 16, pointer;
  visibility: hidden;
  opacity: 0;
}

.search-overlay__inner {
  transform: translateX(-100%);
  width: 400px;
  height: 100%;
  cursor: default;
  background-color: #5E61E7;
  background-image: linear-gradient(138deg, #5E61E7 0, #9C7AF2 100%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: auto;
}

@media only screen and (max-width: 479px) {
  .search-overlay__inner {
    width: 300px;
  }
}

.search-overlay.active {
  visibility: visible;
  opacity: 1;
}

.search-overlay.active .search-overlay__inner {
  transform: translateX(0);
}

.search-overlay__header {
  background-color: #ffffff;
  padding: 15px 0;
}

.search-overlay__header .mobile-navigation-close-icon {
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
}

.search-overlay__header .mobile-navigation-close-icon:before {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__header .mobile-navigation-close-icon:after {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #333333;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__header .mobile-navigation-close-icon:hover {
  color: #5945e6;
}

.search-overlay__header .mobile-navigation-close-icon:hover:before, .search-overlay__header .mobile-navigation-close-icon:hover:after {
  transform: none;
}

.search-overlay__body {
  padding: 30px 40px;
}

.search-overlay__form {
  position: relative;
}

.search-overlay__form input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0;
  padding: 15px 50px 15px 0;
  width: 100%;
  color: #ffffff;
}

.search-overlay__form input::placeholder {
  color: #ffffff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__form input[type="text"]:focus {
  color: #ffffff;
  border-color: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__form button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #ffffff;
}

/*=====  End of Header  ======*/
/*=============================================
=            06. Footer Css           =
=============================================*/
.reveal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .reveal-footer {
    position: static;
  }
}

.footer-widget-wrapper {
  margin-bottom: -35px;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget__title {
  margin-top: -3px;
  color: #333333;
}

.footer-widget__list li {
  margin-bottom: 10px;
}

.footer-widget__list li:last-child {
  margin-bottom: 0;
}

.app-showcase_footer .heading {
  font-size: 48px;
  line-height: 1.34;
  font-weight: 300;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .app-showcase_footer .heading {
    font-size: 32px;
  }
}

.app-showcase_footer .sub-title {
  line-height: 1.78;
  font-weight: 400;
}

.app-showcase_footer .showcase-background__links img {
  border-radius: 5px;
  margin-right: 10px;
}

@media only screen and (max-width: 575px) {
  .app-showcase_footer .showcase-background__links img {
    margin-right: 0px;
    margin-bottom: 5px;
  }
}

.app-landing_footer .heading {
  z-index: 2;
  position: relative;
}

.app-landing_footer .app-landing-background__links img {
  border-radius: 5px;
  margin-right: 10px;
}

@media only screen and (max-width: 575px) {
  .app-landing_footer .app-landing-background__links img {
    margin-right: 0px;
    margin-bottom: 5px;
  }
}

.social-group__list .ht-social-networks.tilte-style .link-text {
  color: #CCCCCC;
}

.social-group__list .ht-social-networks.tilte-style .link-text::before {
  background: rgba(255, 255, 255, 0.2);
}

.social-group__list .ht-social-networks.tilte-style .link-text:hover {
  color: #ffffff;
}

.social-group__list .ht-social-networks.tilte-style .social-link::before {
  color: #ccc;
}

.copyright-text {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .copyright-text {
    margin-bottom: 15px;
  }
}

.bg_drak-footer {
  background: #242659;
  border-top: 3px solid #5556ca;
}

.bg_drak-footer .footer-widget__title {
  color: #ffffff;
}

.bg_drak-footer .footer-widget__list li {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
}

.bg_drak-footer .footer-widget__list li:last-child {
  margin-bottom: 0;
}

.bg_drak-footer .copyright-text {
  color: rgba(255, 255, 255, 0.7);
}

.bg_drak-footer .border-top {
  border-top: 1px solid rgba(237, 237, 237, 0.1) !important;
}

.bg_drak-footer .ht-social-networks.solid-rounded-icon .social-link {
  color: #fff;
}

.bg_drak-footer .tweet__text {
  background-color: #fff;
}

.bg_drak-footer .tweet__heading {
  color: #fff;
}

.app-landing_footer-share {
  margin-top: 30px;
}

.app-landing_footer-share .ht-social-networks.large-icon .social-link {
  color: #ffffff;
}

.light-logo img {
  width: 180px;
}

.footer-widget-agency .footer-widget:nth-child(1) {
  align-items: flex-end;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-widget-agency .footer-widget:nth-child(1) {
    align-items: flex-start;
  }
}

.footer-widget-agency .footer-widget:nth-child(2) {
  padding-left: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-widget-agency .footer-widget:nth-child(2) {
    padding-left: 15px;
  }
}

.footer-widget-agency .footer-widget:nth-child(3) {
  padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-widget-agency .footer-widget:nth-child(3) {
    padding-left: 15px;
  }
}

.footer-widget-clinc .footer-widget:nth-child(2) {
  padding-left: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-widget-clinc .footer-widget:nth-child(2) {
    padding-left: 15px;
  }
}

.footer-widget-clinc .footer-widget:nth-child(3) {
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-widget-clinc .footer-widget:nth-child(3) {
    padding-left: 15px;
  }
}

.ebook-footer .horizontal-list ul li {
  color: #6d70a6;
}

.ebook-footer .horizontal-list ul li::before {
  content: "";
  position: absolute;
  top: 8px;
  left: -2px;
  content: '';
  height: 16px;
  width: 1px;
  background: #6d70a6;
}

.ebook-footer .ht-social-networks.tilte-style .link-text::before {
  background: rgba(89, 69, 230, 0.2);
}

.ebook-footer .ht-social-networks.tilte-style .link-text:hover {
  color: #5945e6;
}

.ebook-footer .ht-social-networks.tilte-style .link-text:hover::after {
  background-color: rgba(89, 69, 230, 0.2);
}

.ebook-footer .ht-social-networks.tilte-style .social-link {
  padding: 10px 14px;
}

.ebook-footer .ht-social-networks.tilte-style .social-link:hover .link-text::after {
  background-color: #5945e6;
}

.body-gradient {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F6F5F9), color-stop(60%, #FFF));
  background: -moz-linear-gradient(top, #F6F5F9 0, #FFF 60%);
  background: -webkit-linear-gradient(top, #F6F5F9 0, #FFF 60%);
  background: -o-linear-gradient(top, #F6F5F9 0, #FFF 60%);
  background: -ms-linear-gradient(top, #F6F5F9 0, #FFF 60%);
  background: linear-gradient(top, #F6F5F9 0, #FFF 60%);
}

/*=====  End of footer ======*/
/*=============================================
=            twitter feed            =
=============================================*/
.tweet__text {
  position: relative;
  font-size: 14px;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 19px 20px 25px;
  margin-bottom: 25px;
  word-wrap: break-word;
  background-color: rgba(89, 69, 230, 0.05);
}

.tweet__text a {
  color: #38cb89;
}

.tweet__text a:hover {
  color: #5945e6;
}

.tweet__text:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 33px;
  bottom: -10px;
  border-color: transparent #f7fbfe transparent transparent;
  border-width: 10px;
}

.tweet__text:before {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 30px;
  bottom: -12px;
  border-color: transparent #ededed transparent transparent;
  border-width: 12px;
  content: '';
}

.tweet__info {
  position: relative;
  padding-left: 55px;
}

.tweet__info:before {
  content: '\f099';
  font-size: 18px;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 23px;
  display: block;
  font-family: "Font Awesome 5 Brands";
  color: #5945e6;
}

.tweet__heading {
  font-size: 14px;
  color: #333;
}

.tweet__date {
  font-size: 14px;
  color: #ababab;
}

/*=====  End of twitter feed  ======*/
/*=======================================
=            07. Sidebar Css           =
=========================================*/
.page-sidebar-right {
  padding-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-sidebar-right {
    padding-left: 0;
  }
}

.page-sidebar-left {
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-sidebar-left {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-sidebar-right, .page-sidebar-left {
    margin-top: 60px;
  }
}

.sidebar-widget + .sidebar-widget {
  margin-top: 40px;
}

.widget-title {
  margin-bottom: 30px;
}

.widget-search {
  position: relative;
}

.widget-search input {
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px 20px;
  padding-right: 20px;
  height: 56px;
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  padding-right: 72px;
}

.widget-search .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: 0;
  border-radius: 0 5px 5px 0;
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: none;
  box-shadow: none;
  transform: none;
}

.widget-search .search-submit:hover {
  color: #ffffff;
  background: #5945e6;
}

.widget-categories .cat-item {
  text-transform: uppercase;
  line-height: 1.64;
  font-weight: 500;
  letter-spacing: 3px;
  padding: 16px 0px 16px 0;
  border-bottom: 1px solid #f0f2f7;
}

.widget-categories .cat-item a {
  display: block;
}

.widget-categories .cat-item .count {
  float: right;
  background-color: #38cb89;
  font-size: 12px;
  font-weight: 700;
  border-radius: 20px;
  line-height: 1;
  color: #fff;
  padding: 5px 11px;
  letter-spacing: 0;
}

.widget-blog-post .post-item + .post-item {
  margin-top: 15px;
  border-top: 1px solid #f0f2f7;
  padding-top: 20px;
}

.widget-blog-post .post-info .post-categories {
  margin-bottom: 6px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #b6b7d2;
}

.widget-blog-post .post-title a {
  color: #696969;
}

.widget-blog-post .post-title a:hover {
  color: #5945e6;
}

.widget-tag .ht-btn {
  color: #b6b7d2;
  background: #f5f7fd;
  margin-right: 10px;
  font-weight: 400;
  margin-bottom: 10px;
}

.widget-tag .ht-btn:hover {
  color: #ffffff;
  background-image: linear-gradient(43deg, #5E61E7 0, #9C7AF2 100%, #5E61E7 100%);
  background-position: right center;
  transform: translateY(0px);
}

.widget-product-item {
  overflow: hidden;
  margin-bottom: 20px;
}

.widget-product-item .thumbnail {
  max-width: 150px;
  float: left;
  margin-right: 20px;
  margin-left: 0;
  width: 80px;
}

.widget-product-item .info .product-title {
  font-weight: 400;
  font-size: 16px;
}

.widget-product-item .info .star-rating a {
  display: inline-block;
  color: #ffb805;
  line-height: 1;
  font-size: 14px;
  margin: 0;
}

.widget-product-item .info .price-box {
  margin-top: 5px;
}

.widget-product-item .info .price-box .old-price {
  font-size: .88em;
  font-weight: 400;
  color: #b6b7d2;
  text-decoration: line-through;
}

.widget-product-item .info .price-box .new-price {
  font-size: 14px;
  font-weight: 500;
  color: #6d70a6;
}

/*=====  End of Sidebar  ======*/
/*=============================================
=            08. Button Css           =
=============================================*/
.ht-btn {
  font-size: 15px;
  font-weight: 500;
  border-style: solid;
  border-radius: 5px;
  border-color: transparent;
  padding: 0 36px;
  text-align: center;
  color: #ffffff;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-size: 200% auto;
  background-position: left center;
  border-width: 1px;
  transform: translateY(0px);
}

.ht-btn:hover, .ht-btn:active, .ht-btn:focus {
  color: #ffffff;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-position: right center;
  transform: translateY(-3px);
}

.ht-btn.ht-btn-xs {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 20px;
}

.ht-btn.ht-btn-sm {
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
}

.ht-btn.ht-btn-md {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 54px;
  height: 54px;
}

.ht-btn.ht-btn-lg {
  padding: 0 40px;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
}

.ht-btn.ht-btn-custom {
  min-width: 280px;
}

.ht-btn.ht-btn-full {
  width: auto;
  display: block;
}

.ht-btn--outline {
  border-width: 2px;
  line-height: 54px;
  background: transparent;
  border-color: #5945e6;
  color: #5945e6;
}

.ht-btn--outline:hover, .ht-btn--outline:focus, .ht-btn--outline:active {
  transform: translateY(-3px);
  border-color: transparent;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
}

.ht-btn--circle {
  border-radius: 30px;
}

.ht-btn--no-round {
  border-radius: 0px;
}

.ht-btn.ht-btn-default {
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  width: 180px;
}

.ht-btn .btn-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  font-size: 20px;
  margin-left: 10px;
}

.btn {
  font-size: 15px;
  font-weight: 500;
  border-style: solid;
  border-radius: 5px;
  border-color: transparent;
  border: 1px solid transparent;
  padding: 0 36px;
  text-align: center;
  transition: all 0.4s ease-in-out;
  height: 54px;
  line-height: 54px;
  letter-spacing: 0em;
  color: #ffffff;
  background: #5945e6;
  transform: translateY(0px);
}

.btn:hover, .btn:active, .btn:focus {
  font-weight: 500;
  color: #ffffff;
  background: #5945e6;
  transform: translateY(-3px);
}

.btn--green {
  background-image: none;
  background-color: #38cb89;
}

.btn--green:hover {
  background-image: none;
  background: #38cb89;
}

.btn--black {
  background-image: none;
  background-color: #000000;
}

.btn--black:hover {
  background-image: none;
  background: #000000;
}

.btn--white {
  background-image: none;
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
}

.btn--white:hover, .btn--white:focus {
  background-image: none;
  background: #ffffff;
  color: #000000;
}

.btn--gradient {
  background-image: none;
  background: linear-gradient(150deg, #FF3F85 0, #FAA720 100%) !important;
}

.btn--gradient:hover {
  background-image: none;
  background: linear-gradient(150deg, #FF3F85 0, #FAA720 100%);
}

.btn--link {
  color: #5945e6;
}

.btn--link:hover {
  transform: translateY(-5px);
}

.btn.ht-btn-default {
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  width: 180px;
}

.hover-style-link {
  position: relative;
}

.hover-style-link:after {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #5945e6;
}

.hover-style-link:hover {
  color: #5945e6;
}

.hover-style-link:hover:after {
  left: 0;
  width: 100%;
  right: auto;
}

.hover-style-link--green {
  color: #38cb89;
}

.hover-style-link--green:after {
  background-color: #38cb89;
}

.hover-style-link--green:hover {
  color: #38cb89;
}

.hover-style-link--white {
  color: #ffffff;
}

.hover-style-link--white:after {
  background-color: #ffffff;
}

.hover-style-link--white:hover {
  color: #ffffff;
}

.hover-style-link--sub-color {
  color: #6d70a6;
}

.hover-style-link--sub-color:after {
  background-color: #6d70a6;
}

.hover-style-link--sub-color:hover {
  color: #6d70a6;
}

/* Images Button */
.image-btn {
  border: 0;
  box-shadow: none;
  background: none;
  border-radius: 5px;
  overflow: hidden;
}

/* video pay link */
.video-play i {
  color: #38cb89;
}

.video-play span {
  font-weight: 500;
  letter-spacing: 3px;
  margin-left: 10px;
}

/* button wrapper */
.button-wrapper > a {
  border: none;
}

@media only screen and (max-width: 479px) {
  .button-wrapper > a {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .button-wrapper > a:last-child {
    margin-bottom: 0;
  }
}

.single-button-group h5 {
  margin-bottom: 10px;
}

.single-button-group .button-group-wrap {
  margin-left: -10px;
  margin-right: -10px;
}

.single-button-group a {
  margin: 10px;
}

/*=====  End of Button  ======*/
/*=============================================
=            09. Accordions Css           =
=============================================*/
.faq-wrapper .panel.panel-default {
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.faq-wrapper .card, .faq-wrapper .card-header {
  border-radius: 0;
}

.faq-wrapper .card {
  border-bottom: none;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
}

.faq-wrapper .card:last-child {
  margin-bottom: 0;
}

.faq-wrapper .card-header {
  padding: 0;
  border-bottom: 0;
  background-color: #ffffff;
}

.faq-wrapper .card-header h5 button {
  border: none;
  width: 100%;
  text-align: left;
  color: #6d70a6;
  padding: 20px 30px;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  border-radius: 5px;
  background: #ffffff;
}

@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button {
    padding-left: 15px;
    padding-right: 20px;
  }
}

.faq-wrapper .card-header h5 button span {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button span {
    right: 15px;
  }
}

.faq-wrapper .card-header h5 button span i {
  font-size: 20px;
  visibility: hidden;
  opacity: 0;
  display: none;
}

.faq-wrapper .card-header h5 button:hover, .faq-wrapper .card-header h5 button:focus {
  text-decoration: none;
  color: #5945e6;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] {
  color: #ffffff;
  background-color: #9C7AF2;
  background-image: linear-gradient(-129deg, #9C7AF2 0, #5E61E7 100%);
  opacity: 0.7;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"] span i:nth-child(2) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-wrapper .card-header h5 button[aria-expanded="true"]:before {
  height: 100%;
}

.faq-wrapper .card-header h5 button[aria-expanded="false"] span i:nth-child(1) {
  visibility: visible;
  opacity: 1;
  display: inline;
}

.faq-wrapper .card-body {
  padding: 23px 30px 29px;
}

/*=============================================
=              10. Box Icon Css              =
=============================================*/
.ht-box-icon.style-01 .icon-box-wrap {
  display: flex;
  padding: 37px 30px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.ht-box-icon.style-01 .icon-box-wrap .image {
  position: relative;
  flex-shrink: 0;
  margin: 0 27px 0 0;
  border: 3px solid #f5f5f5;
  height: 114px;
  width: 114px;
  line-height: 114px;
  text-align: center;
  border-radius: 50%;
}

.ht-box-icon.style-01 .icon-box-wrap .content .heading {
  color: #6d70a6;
  margin-bottom: 15px;
}

.ht-box-icon.style-01 .icon-box-wrap .content .service_text {
  color: #7e7e7e;
}

.ht-box-icon.style-01:hover .icon-box-wrap {
  transform: translateY(-5px);
  background: #fff;
  box-shadow: 0 10px 50px 5px rgba(89, 69, 230, 0.1);
}

@media only screen and (max-width: 575px) {
  .ht-box-icon.style-01 .icon-box-wrap {
    padding: 37px 10px;
  }
  .ht-box-icon.style-01 .icon-box-wrap .image {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
}

.ht-box-icon.style-02 {
  padding: 30px;
  border-radius: 5px;
  text-align: center;
  align-items: center;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.ht-box-icon.style-02 .icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 26px;
  font-size: 30px;
  color: #fff;
  margin-bottom: 19px;
}

.ht-box-icon.style-02 .icon.react {
  color: #2bc1db;
  border-color: #2bc1db;
  background-color: #eaf9ff;
}

.ht-box-icon.style-02 .icon.window {
  color: #ffa301;
  border-color: #ffa301;
  background-color: #fff6dc;
}

.ht-box-icon.style-02 .icon.macos {
  color: #524ce8;
  border-color: #524ce8;
  background-color: #f5f4fe;
}

.ht-box-icon.style-02 .icon.java {
  color: #ff595d;
  border-color: #ff595d;
  background-color: #fff5f6;
}

.ht-box-icon.style-02 .icon.android {
  color: #8dc938;
  border-color: #8dc938;
  background-color: #f5faed;
}

.ht-box-icon.style-02 .icon.php {
  color: #3066f0;
  border-color: #3066f0;
  background-color: #eaf0fe;
}

.ht-box-icon.style-02 .content .heading {
  font-size: 15px;
  color: #696969;
  margin-bottom: 0;
}

.ht-box-icon.style-02:hover {
  transform: translateY(-5px);
  background: #fff;
  box-shadow: 0 10px 50px 5px rgba(89, 69, 230, 0.1);
}

.start-ups-feature-wrap {
  width: 970px;
  max-width: 100%;
  margin: auto;
}

.modern-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .modern-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* ============== */
.service-grid {
  margin-bottom: 25px;
}

.service-grid .ht-service-box--one > a {
  padding: 35px 40px 49px;
  box-shadow: 0 4px 20px rgba(51, 51, 51, 0.05);
  background: #ffffff;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.service-grid .ht-service-box--one > a:before {
  z-index: -1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 5px;
  background-color: #9C7AF2;
  background-image: linear-gradient(-138deg, #9C7AF2 0, #5E61E7 100%);
  opacity: 0;
  visibility: hidden;
}

.service-grid .ht-service-box--one > a:hover {
  transform: translateY(-5px);
}

.service-grid .ht-service-box--one > a:hover:before {
  opacity: .7;
  visibility: visible;
}

.service-grid .ht-service-box--one > a:hover .image__media {
  background: rgba(246, 245, 249, 0.2);
}

.service-grid .ht-service-box--one > a:hover .heading, .service-grid .ht-service-box--one > a:hover .service_text {
  color: #ffffff;
}

.service-grid .ht-service-box--one .image__media {
  position: relative;
  background: #f6f5f9;
  border-radius: 50%;
  height: 60px;
  line-height: 60px;
  width: 60px;
  margin-bottom: 16px;
  text-align: center;
}

.service-grid .ht-service-box--two > a {
  padding: 35px 40px 49px;
  background: #ffffff;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.service-grid .ht-service-box--two > a:hover {
  transform: translateY(-5px);
  box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
}

.service-grid .ht-service-box--two .image__media {
  position: relative;
  background: #f6f5f9;
  border-radius: 50%;
  height: 60px;
  line-height: 60px;
  width: 60px;
  margin-bottom: 16px;
  text-align: center;
}

.service-grid .sub-heading {
  color: #6d70a6;
  margin-bottom: 10px;
  font-size: 15px;
}

.service-grid .heading {
  color: #6d70a6;
  margin-bottom: 15px;
}

.service-grid .service_text {
  color: #7e7e7e;
}

.ht-service-box--three {
  padding: 37px 30px 37px 17px;
  box-shadow: 0 0 40px 5px rgba(51, 51, 51, 0.1);
  background: #fff;
  border-radius: 10px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-service-box--three:hover {
  transform: translateY(-5px);
  box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
}

.ht-service-box--three .service-box-wrap {
  display: flex;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 575px) {
  .ht-service-box--three .service-box-wrap {
    flex-direction: column;
  }
}

.ht-service-box--three .image__media {
  position: relative;
  flex-shrink: 0;
  margin: 0 13px 0 0;
  min-width: 185px;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .ht-service-box--three .image__media {
    margin-bottom: 20px;
  }
}

.ht-service-box--three .heading {
  color: #5945e6;
  margin-bottom: 15px;
}

.ht-service-box--three .service_text {
  color: #7e7e7e;
}

.ht-clinic-service-box {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-clinic-service-box .content-header {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.ht-clinic-service-box .content-header .icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 8px;
  font-size: 30px;
  color: #fff;
  background-image: linear-gradient(-135deg, #f8c59b 0%, #e68c60 50%, #f8c59b 100%);
  background-position: left center;
  background-size: 200% auto;
  flex-shrink: 0;
  margin-right: 19px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-clinic-service-box .content-header .heading {
  color: #6d70a6;
}

.ht-clinic-service-box .content-body .service_text {
  color: #7e7e7e;
}

.ht-clinic-service-box:hover {
  transform: translateY(-5px);
}

.ht-clinic-service-box:hover .content-header .icon {
  background-position: right center;
}

.ht-clinic-service-box:hover .content-header .heading {
  color: #2e3280;
}

.ht-product-service-box {
  margin-bottom: 30px;
}

.ht-product-service-box .content-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.ht-product-service-box .content-header .icon {
  border-radius: 8px;
  font-size: 34px;
  flex-shrink: 0;
  margin-right: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #5945e6;
}

.ht-product-service-box .content-header .heading {
  font-size: 18px;
  color: #333;
}

.ht-product-service-box .content-body .service_text {
  color: #7e7e7e;
}

/*=============================================
=           11. Call To Action Css             =
=============================================*/
.cta-image-area_one {
  margin-top: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-image-area_one {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .cta-image-area_one {
    margin-top: 50px;
  }
}

.cta-image-bg {
  background: url("../images/patterns/aeroland-sass-section-download-bg.png") 55% 100% no-repeat, linear-gradient(-138deg, #5E60E7 0, #9C7AF2 50%, #5E60E7 100%);
  border-radius: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-image-bg {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .cta-image-bg {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.cta-image-bg_02 {
  background: url("../images/patterns/aeroland-sass-section-download-bg.png") 55% 100% no-repeat, linear-gradient(-138deg, #5E60E7 0, #9C7AF2 50%, #5E60E7 100%);
}

.cta-image-bg-03 {
  background: url("../images/patterns/aeroland-section-call-to-action-03-bg.png");
  background-color: #f6fafe;
  background-repeat: no-repeat;
  background-position: top right 200px;
}

.cta-image__content {
  padding-left: 70px;
}

@media only screen and (max-width: 767px) {
  .cta-image__content {
    padding-left: 30px !important;
    padding-right: 30px;
  }
}

.cta-image__text {
  font-size: 18px;
}

.cta-image__media {
  margin-top: -50px;
  margin-bottom: -110px;
  margin-right: -140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-image__media {
    margin-top: 0;
    margin-bottom: -60px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .cta-image__media {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.cta-image__content {
  padding-left: 70px;
}

.cta-image__text {
  font-size: 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .cta-image-area {
    padding-top: 50px;
    padding-bottom: 80px;
  }
}

.cta-image_two {
  margin-bottom: -10px;
}

@media only screen and (max-width: 767px) {
  .cta-image_two {
    margin-bottom: 0px;
  }
}

/*=====  End of cta   ======*/
/*=============================================
=           12. Attribute Css             =
=============================================*/
.attribute_list_one {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 54px 30px;
  width: 100%;
}

.attribute_list_one li .name h6 {
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 14px;
}

.attribute_list_one li .value {
  margin-top: 15px;
}

.attribute-content-wrap {
  padding: 40px 30px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.attribute_list_two {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.attribute_list_two li {
  border-top: 1px solid #eee;
  display: table-row;
}

.attribute_list_two li:first-child {
  border-top: none;
}

.attribute_list_two li div {
  display: table-cell;
  padding: 12px 0;
}

.attribute_list_two li .name h6 {
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 14px;
}

.attribute_list_two li .value {
  text-align: right;
}

/*======================================
    13. Contact Form And Newsletter Css 
=======================================*/
.contact-input {
  margin: 0 -10px;
}

.contact-input .contact-inner {
  float: left;
  margin: 0 0 20px;
  padding: 0 10px;
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .contact-input .contact-inner {
    width: 100%;
  }
}

.contact-inner {
  margin: 0 0 20px;
}

.contact-inner input, .contact-inner textarea {
  width: 100%;
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  border: none;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-top-color: #eeeeee;
  border-right-color: #eeeeee;
  border-bottom-color: #eeeeee;
  border-left-color: #eeeeee;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
}

.contact-inner textarea {
  height: 150px;
  padding: 15px;
}

.peatures_image-wrap {
  margin-right: -168px !important;
  margin-left: 80px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .peatures_image-wrap {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

/* Newsletter Css */
.newsletter-form--one input {
  border-color: transparent;
  background: rgba(0, 0, 0, 0.07);
  min-width: 275px;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
  margin-right: 15px;
  border: 1px solid transparent;
  margin-bottom: 20px;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-form--one input {
    min-width: 245px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-form--one input {
    min-width: 165px;
  }
}

@media only screen and (max-width: 575px) {
  .newsletter-form--one input {
    min-width: 100%;
  }
}

.newsletter-form--one input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}

.newsletter-form--one input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}

.newsletter-form--one input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}

.newsletter-form--one input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}

.newsletter-form--one input[type="text"]:focus, .newsletter-form--one input[type="email"]:focus {
  color: #ffffff;
  border-color: #ffffff;
}

.newsletter-form--two input {
  border-color: transparent;
  background: rgba(0, 0, 0, 0.07);
  min-width: 275px;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
  margin-right: 15px;
  border: 1px solid transparent;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-form--two input {
    min-width: 245px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-form--two input {
    min-width: 165px;
  }
}

@media only screen and (max-width: 575px) {
  .newsletter-form--two input {
    min-width: 100%;
  }
}

.btn-text-theme {
  color: #5945e6;
}

/*======================================
    14. Countdown Css 
=======================================*/
.countdown_bg {
  background-image: url(../images/bg/aeroland-countdown-bg-image-01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.countdown-deals {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 767px) {
  .countdown-deals {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 575px) {
  .countdown-deals {
    grid-template-columns: repeat(2, 1fr);
  }
}

.countdown-deals.counter-style--one .single-countdown {
  display: inline-block;
}

.countdown-deals.counter-style--one .single-countdown .single-countdown__time {
  display: block;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(-188deg, #ffbe00 0%, #f76b1c 100%);
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 40px;
  }
}

.countdown-deals.counter-style--one .single-countdown .single-countdown__text {
  display: block;
  color: #6d70a6;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.countdown-deals.counter-style--two .single-countdown {
  display: inline-block;
}

.countdown-deals.counter-style--two .single-countdown .single-countdown__time {
  display: block;
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .countdown-deals.counter-style--two .single-countdown .single-countdown__time {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--two .single-countdown .single-countdown__time {
    font-size: 40px;
  }
}

.countdown-deals.counter-style--two .single-countdown .single-countdown__text {
  display: block;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.countdown-deals.counter-style--four .single-countdown {
  display: inline-block;
  width: 70px;
  margin: auto;
}

.countdown-deals.counter-style--four .single-countdown .single-countdown__time {
  display: block;
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 90px;
  line-height: 90px;
  color: #000000;
}

.countdown-deals.counter-style--four .single-countdown .single-countdown__text {
  display: block;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.time_circles {
  position: relative;
  width: 100%;
  height: 100%;
}

.time_circles > div {
  position: absolute;
  text-align: center;
  top: 0% !important;
  transform: translateY(50%);
}

.time_circles .textDiv_Days, .textDiv_Hours, .textDiv_Minutes, .textDiv_Seconds {
  color: #222222;
  font-size: 30px !important;
}

@media only screen and (max-width: 575px) {
  .time_circles .textDiv_Days, .textDiv_Hours, .textDiv_Minutes, .textDiv_Seconds {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .time_circles .textDiv_Days, .textDiv_Hours, .textDiv_Minutes, .textDiv_Seconds {
    font-size: 20px !important;
  }
}

.time_circles > div > h4 {
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-transform: capitalize;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  font-size: 13px !important;
  color: #6d70a6;
}

@media only screen and (max-width: 575px) {
  .time_circles > div > h4 {
    font-size: 11px !important;
  }
}

/*======================================
    15. Counters Css 
=======================================*/
.fun-fact--one .fun-fact__title {
  color: #6d70a6;
}

.fun-fact--one .fun-fact__count {
  font-size: 64px;
  color: #ee7455;
}

.fun-fact--one .fun-fact__text {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: rgba(109, 112, 166, 0.5);
}

.fun-fact--two .fun-fact__count {
  font-size: 56px;
  color: #ffffff;
  margin-bottom: 20px;
}

.fun-fact--two .fun-fact__text {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}

.fun-fact--three {
  position: relative;
  padding-left: 15px;
}

.fun-fact--three::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  height: 65px;
  width: 1px;
  background: #d8d8d8;
}

.fun-fact--three .fun-fact__count {
  color: #38cb89;
  font-size: 48px;
  margin-bottom: 12px;
}

.fun-fact--three .fun-fact__text {
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(109, 112, 166, 0.5);
}

.fun-fact--three .number-suffix {
  position: relative;
}

.fun-fact--three .number-suffix::after {
  position: absolute;
  content: "k";
  right: -30px;
  top: 0;
  font-size: 48px;
}

.fun-fact--four .fun-fact__title {
  color: #6d70a6;
}

.fun-fact--four .fun-fact__count {
  font-size: 54px;
  color: #ee7455;
}

.fun-fact--four .fun-fact__text {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: rgba(109, 112, 166, 0.5);
}

/*======================================
    16. Listing Css 
=======================================*/
.separator-list .list-item {
  position: relative;
  margin-bottom: 6px;
  padding-bottom: 6px;
}

.separator-list .list-item::before {
  position: absolute;
  bottom: 0px;
  left: 0;
  content: '';
  height: 1px;
  width: 100%;
  background: #eee;
}

.separator-list .list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0px;
}

.separator-list .list-item:last-child::before {
  display: none;
}

.separator-list .list-item a {
  line-height: 16px;
}

.check-list .list-item {
  position: relative;
  padding-left: 32px;
  margin-bottom: 10px;
}

.check-list .list-item::before {
  color: #5945e6;
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 0;
  display: block;
  font-size: 13px;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.check-list .list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0px;
}

.check-list .list-item a {
  line-height: 16px;
}

.check-list.green-check-list .list-item::before {
  color: #38cb89;
}

.circle-list .list-item {
  position: relative;
  padding-left: 14px;
  margin-bottom: 12px;
}

.circle-list .list-item::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #999;
}

.circle-list .list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0px;
}

.circle-list .list-item a {
  line-height: 16px;
}

.icon-list .list-item {
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
}

.icon-list .list-item .list__icon {
  color: #5945e6;
  font-size: 16px;
  margin-right: 10px;
}

.icon-list .list-item a {
  line-height: 16px;
}

ol {
  min-width: 45px;
  margin: 0;
  padding-left: 30px;
}

.automatic-numbered-list .list-item {
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
  font-size: 24px;
  color: #333;
  line-height: 1.2;
  font-weight: 400;
}

.automatic-numbered-list .list-item a {
  line-height: 16px;
}

.horizontal-list ul li {
  display: inline-block;
  color: #ffffff;
  padding: 3px 14px;
  position: relative;
}

.horizontal-list ul li::before {
  content: "";
  position: absolute;
  top: 8px;
  left: -2px;
  content: '';
  height: 16px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
}

.horizontal-list ul li:first-child::before {
  display: none;
}

/*=============================================
=        17. Gradation Process Css            =
=============================================*/
.icon-process-wrapper {
  display: flex;
  text-align: center;
  margin-bottom: -60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .icon-process-wrapper {
    flex-direction: column;
  }
}

.icon-process-single {
  padding: 0 10px;
  position: relative;
  flex-grow: 1;
  margin-bottom: 60px;
}

.icon-process-single__line {
  position: absolute;
  top: 60px;
  left: 100%;
  transform: translate(-50%, -5px);
  height: 10px;
  width: 100%;
  line-height: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .icon-process-single__line {
    left: 50%;
    bottom: -30px;
    top: auto;
    transform: translate(-50%, 0);
  }
}

.icon-process-single__line .dot {
  display: inline-block;
  vertical-align: middle;
  background: #e4e8f6;
  border-radius: 50%;
  margin: 0 8px;
}

.icon-process-single__line .dot-1 {
  width: 2px;
  height: 2px;
}

.icon-process-single__line .dot-2 {
  width: 4px;
  height: 4px;
}

.icon-process-single__line .dot-3 {
  width: 6px;
  height: 6px;
}

.icon-process-single__line .dot-4 {
  width: 8px;
  height: 8px;
}

.icon-process-single__line .dot-5 {
  width: 10px;
  height: 10px;
}

.icon-process-single:last-child .icon-process-single__line {
  display: none;
}

.icon-process-single__content-wrap {
  position: relative;
  text-align: center;
  margin: 0 auto;
  max-width: 320px;
}

.icon-process-single__media {
  position: relative;
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 40px;
  font-size: 34px;
  background: #ffffff;
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  margin: 0 auto 52px;
  color: #38cb89;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .icon-process-single__media {
    margin-bottom: 30px;
  }
}

.icon-process-single__media i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-process-single__title {
  color: #6d70a6;
  margin-bottom: 14px;
}

.icon-process-single:hover .icon-process-single__media {
  background-color: #38cb89;
  color: #ffffff;
}

/*=====  End of icon process  ======*/
/*=============================================
=        18. Instagram Css            =
=============================================*/
.instagram-feed-thumb {
  overflow: hidden;
}

.instagram-wrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-wrap {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 575px) {
  .instagram-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

.instagram-item a {
  overflow: hidden;
  position: relative;
}

.instagram-item a::after {
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 100%;
  width: 100%;
}

.instagram-item a img {
  transition: all 1.5s ease-in-out;
  transform: scale(1);
  max-width: 100%;
}

.instagram-item a:hover img {
  transform: scale(1.2);
}

.instagram-item a .instagram-hvr-content {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.instagram-item a .instagram-hvr-content .tottallikes, .instagram-item a .instagram-hvr-content .totalcomments {
  color: #ffffff;
  margin: 0 5px;
}

.instagram-item a .instagram-hvr-content .tottallikes i, .instagram-item a .instagram-hvr-content .totalcomments i {
  margin-right: 5px;
}

.instagram-item a:hover::after {
  opacity: 1;
  visibility: visible;
}

.instagram-item a:hover .instagram-hvr-content {
  opacity: 1;
  visibility: visible;
}

/*========================================
=        19. Message Box Css            =
==========================================*/
.ht-message-box {
  background: #eee;
  padding: 27px 30px 27px 50px;
  font-weight: 500;
  border-radius: 5px;
}

.ht-message-box .icon {
  margin-right: 10px;
}

.ht-message-box.style-info {
  color: #5b63fe;
  background: rgba(91, 99, 254, 0.1);
}

.ht-message-box.style-success {
  background: rgba(56, 203, 137, 0.1);
  color: #38cb89;
}

.ht-message-box.style-error {
  background: rgba(254, 40, 84, 0.1);
  color: #fe2854;
}

.ht-message-box.style-warning {
  color: #fb0;
  background: rgba(255, 187, 0, 0.1);
}

/*========================================
=        20. Video Popup Css            =
==========================================*/
.single-popup-wrap {
  position: relative;
}

.video-link {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.video-link .ht-popup-video.video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.video-link .ht-popup-video.video-overlay .video-button__one {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-link .ht-popup-video.video-overlay .video-button__one .video-play {
  width: 60px;
  height: 60px;
  background: transparent;
  border: 4px solid #fff;
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
}

.video-link .ht-popup-video.video-overlay .video-button__one .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__one .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
}

.video-link .ht-popup-video.video-overlay .video-button__two {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-link .ht-popup-video.video-overlay .video-button__two .video-play {
  width: 72px;
  height: 72px;
  background: transparent;
  border: 6px solid #fff;
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
}

.video-link .ht-popup-video.video-overlay .video-button__two .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__two .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
}

.video-link .ht-popup-video.video-overlay .video-button__three {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-link .ht-popup-video.video-overlay .video-button__three .video-play {
  width: 120px;
  height: 120px;
  background: #ffffff;
  border: 6px solid #fff;
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
}

.video-link .ht-popup-video.video-overlay .video-button__three .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__three .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 19px;
  border-left-color: #ee7455;
}

.video-link .ht-popup-video.video-overlay .video-button__four {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1499px) {
  .video-link .ht-popup-video.video-overlay .video-button__four {
    left: 50%;
  }
}

.video-link .ht-popup-video.video-overlay .video-button__four .video-play {
  width: 120px;
  height: 120px;
  background: #ffffff;
  border: 6px solid #fff;
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}

.video-link .ht-popup-video.video-overlay .video-button__four .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__four .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 19px;
  border-left-color: #5945e6;
}

.video-link .ht-popup-video.video-overlay .video-button__five {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-link .ht-popup-video.video-overlay .video-button__five .video-play {
  width: 78px;
  height: 78px;
  background-image: linear-gradient(-221deg, #e17643 0%, #f0a86b 100%);
  box-shadow: 0 2px 41px 0 rgba(91, 99, 254, 0.36);
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}

.video-link .ht-popup-video.video-overlay .video-button__five .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video.video-overlay .video-button__five .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 19px;
  border-left-color: #ffffff;
}

.video-link .ht-popup-video.video-overlay .video-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%, -50%);
  pointer-events: none;
}

.video-link .ht-popup-video.video-overlay .video-mark .wave-pulse {
  width: 1px;
  height: 0;
  margin: 0 auto;
}

.video-link .ht-popup-video.video-overlay .video-mark .wave-pulse::after, .video-link .ht-popup-video.video-overlay .video-mark .wave-pulse::before {
  opacity: 0;
  content: '';
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 3px solid #ee7455;
  animation: zoomBig 3.25s linear infinite;
  animation-delay: 0s;
}

.video-link .ht-popup-video.video-overlay .video-mark .wave-pulse::before {
  animation-delay: .75s;
}

.video-link:hover .video-play {
  transform: scale3d(1.15, 1.15, 1.15);
}

@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

/*=============================================
=            21. Pricing table Css           =
==============================================*/
.pricing-table-wrapper {
  margin-bottom: -30px;
}

.pricing-table-one .pricing-table, .pricing-table-four .pricing-table {
  margin-bottom: 30px;
}

.pricing-table-one .pricing-table__inner, .pricing-table-four .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 34px 20px 40px;
  border: 1px solid #dce6ed;
  border-radius: 5px;
  background-color: #ffffff;
}

.pricing-table-one .pricing-table__header, .pricing-table-four .pricing-table__header {
  margin-bottom: 25px;
}

.pricing-table-one .pricing-table__image, .pricing-table-four .pricing-table__image {
  margin-bottom: 17px;
}

.pricing-table-one .pricing-table__title, .pricing-table-four .pricing-table__title {
  color: #333;
  margin-bottom: 19px;
}

.pricing-table-one .pricing-table__price-wrap, .pricing-table-four .pricing-table__price-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.pricing-table-one .pricing-table__price-wrap .currency, .pricing-table-four .pricing-table__price-wrap .currency {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
}

.pricing-table-one .pricing-table__price-wrap .price, .pricing-table-four .pricing-table__price-wrap .price {
  font-size: 56px;
  line-height: .8;
  margin: 0 4px;
  font-weight: 300;
  color: #333;
}

.pricing-table-one .pricing-table__price-wrap .period, .pricing-table-four .pricing-table__price-wrap .period {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
  align-self: flex-end;
}

.pricing-table-one .pricing-table__list li, .pricing-table-four .pricing-table__list li {
  position: relative;
  display: block;
  padding: 5px 0;
}

.pricing-table-one .pricing-table__list li .featured, .pricing-table-four .pricing-table__list li .featured {
  font-weight: 500;
}

.pricing-table-one .pricing-table__footer, .pricing-table-four .pricing-table__footer {
  margin-top: 30px;
}

.pricing-table-one .pricing-table__footer .ht-btn, .pricing-table-four .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-one .pricing-table__footer .ht-btn--outline, .pricing-table-four .pricing-table__footer .ht-btn--outline {
  border-width: 1px;
}

.pricing-table-one .pricing-table__feature-mark, .pricing-table-four .pricing-table__feature-mark {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 0;
  height: 0;
  border-top: 88px solid #ee7455;
  border-bottom: 88px solid transparent;
  border-left: 88px solid transparent;
}

.pricing-table-one .pricing-table__feature-mark span, .pricing-table-four .pricing-table__feature-mark span {
  position: absolute;
  top: -72px;
  right: 6px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.19;
  display: block;
  color: #fff;
  transform: rotate(45deg);
}

.pricing-table-one .pricing-table:hover .pricing-table__inner, .pricing-table-four .pricing-table:hover .pricing-table__inner {
  box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
  border: 0 solid transparent;
  padding: 35px 21px 41px;
  transform: translateY(-5px);
}

.pricing-table-one .pricing-table--popular .pricing-table__inner, .pricing-table-four .pricing-table--popular .pricing-table__inner {
  box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
  border: 0 solid transparent;
  padding: 35px 21px 41px;
}

.pricing-table-one .pricing-table--popular .pricing-table__footer .ht-btn, .pricing-table-four .pricing-table--popular .pricing-table__footer .ht-btn {
  color: #ffffff;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-size: 200% auto;
  background-position: left center;
  border-color: transparent;
}

.pricing-table-one .pricing-table--popular .pricing-table__footer .ht-btn:hover, .pricing-table-four .pricing-table--popular .pricing-table__footer .ht-btn:hover {
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-position: right center;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__inner, .pricing-table-four .pricing-table--popular__bg .pricing-table__inner {
  border: 0px solid transparent;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__title, .pricing-table-four .pricing-table--popular__bg .pricing-table__title {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__price-wrap .currency, .pricing-table-four .pricing-table--popular__bg .pricing-table__price-wrap .currency {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__price-wrap .price, .pricing-table-four .pricing-table--popular__bg .pricing-table__price-wrap .price {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__price-wrap .period, .pricing-table-four .pricing-table--popular__bg .pricing-table__price-wrap .period {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__list li, .pricing-table-four .pricing-table--popular__bg .pricing-table__list li {
  color: #ffffff;
}

.pricing-table-one .pricing-table--popular__bg .pricing-table__feature-mark, .pricing-table-four .pricing-table--popular__bg .pricing-table__feature-mark {
  border-top: 88px solid #38cb89;
}

.pricing-table-four .pricing-table__inner {
  border: 0px solid #dce6ed;
  border: 0px solid #dce6ed;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-table-four .pricing-table__header {
  margin-bottom: 15px;
}

.pricing-table-four .pricing-table__image {
  margin-bottom: 25px;
}

.pricing-table-four .pricing-table__title {
  color: #2e3280;
  margin-bottom: 15px;
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.pricing-table-four .pricing-table__list li {
  color: #6d70a6;
}

.pricing-table-four .pricing-table__footer {
  margin-top: 30px;
}

.pricing-table-four .pricing-table__footer .hover-style-link {
  background: transparent !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.23;
  position: relative;
  z-index: 1;
}

.pricing-table-four .pricing-table__footer .hover-style-link .btn-icon {
  margin-left: 5px;
}

.pricing-table-two, .pricing-table-three {
  align-items: center;
}

.pricing-table-two .pricing-table, .pricing-table-three .pricing-table {
  margin-bottom: 30px;
}

.pricing-table-two .pricing-table__inner, .pricing-table-three .pricing-table__inner {
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  padding: 49px 20px 51px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 30px rgba(51, 51, 51, 0.1);
}

.pricing-table-two .pricing-table__header, .pricing-table-three .pricing-table__header {
  margin-bottom: 25px;
}

.pricing-table-two .pricing-table__image, .pricing-table-three .pricing-table__image {
  margin-bottom: 17px;
}

.pricing-table-two .pricing-table__title, .pricing-table-three .pricing-table__title {
  color: #ccc;
  margin-bottom: 19px;
  font-size: 15px;
  text-transform: uppercase;
}

.pricing-table-two .pricing-table__price-wrap, .pricing-table-three .pricing-table__price-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.pricing-table-two .pricing-table__price-wrap .price, .pricing-table-three .pricing-table__price-wrap .price {
  font-size: 48px;
  line-height: .8;
  margin: 0 4px;
  font-weight: 500;
  color: #333;
}

.pricing-table-two .pricing-table__list, .pricing-table-three .pricing-table__list {
  margin: 29px auto 0;
  max-width: 270px;
}

.pricing-table-two .pricing-table__list li, .pricing-table-three .pricing-table__list li {
  position: relative;
  display: block;
  padding: 7px 0 7px 31px;
}

.pricing-table-two .pricing-table__list li::before, .pricing-table-three .pricing-table__list li::before {
  content: '\f00c';
  color: #41b663;
  position: absolute;
  top: 12px;
  left: 0;
  display: inline-block;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.pricing-table-two .pricing-table__list li .featured, .pricing-table-three .pricing-table__list li .featured {
  font-weight: 500;
}

.pricing-table-two .pricing-table__footer, .pricing-table-three .pricing-table__footer {
  margin-top: 30px;
  padding-top: 8px;
}

.pricing-table-two .pricing-table__footer .ht-btn, .pricing-table-three .pricing-table__footer .ht-btn {
  width: 180px;
  max-width: 100%;
}

.pricing-table-two .pricing-table__footer .ht-btn--outline, .pricing-table-three .pricing-table__footer .ht-btn--outline {
  border-width: 1px;
}

.pricing-table-two .pricing-table__feature-mark, .pricing-table-three .pricing-table__feature-mark {
  position: absolute;
  top: 0;
  right: 0;
  height: 92px;
  width: 92px;
  background-image: linear-gradient(-188deg, #ffbe00 0%, #f76b1c 100%);
}

.pricing-table-two .pricing-table__feature-mark::after, .pricing-table-three .pricing-table__feature-mark::after {
  transform: rotate(-45deg);
  background: none repeat scroll 0 0 #fff;
  content: "";
  height: 159px;
  right: 23px;
  position: absolute;
  top: 10px;
  width: 100%;
}

.pricing-table-two .pricing-table__feature-mark span, .pricing-table-three .pricing-table__feature-mark span {
  position: absolute;
  top: 20px;
  right: 7px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.19;
  display: block;
  color: #fff;
  transform: rotate(45deg);
  width: 50px;
}

.pricing-table-two .pricing-table:hover .pricing-table__inner, .pricing-table-three .pricing-table:hover .pricing-table__inner {
  box-shadow: 0 41px 43px rgba(51, 51, 51, 0.07);
  border: 0 solid transparent;
  transform: translateY(-5px);
}

.pricing-table-two .pricing-table--popular .pricing-table__inner, .pricing-table-three .pricing-table--popular .pricing-table__inner {
  border: 0 solid transparent;
  padding: 49px 20px 51px;
}

.pricing-table-three .pricing-table__inner {
  background-color: transparent;
  box-shadow: none;
  z-index: 1;
}

.pricing-table-three .pricing-table__inner::before {
  transition: opacity .3s, visibility .3s;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 101%;
  display: block;
  background-image: linear-gradient(-180deg, #681f9d 0%, #32178a 50%, #040747 100%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.pricing-table-three .pricing-table__title {
  font-size: 24px;
  line-height: 1.5;
  color: #ffff;
  margin-bottom: 18px;
}

.pricing-table-three .pricing-table__body p {
  color: rgba(255, 255, 255, 0.7);
}

.pricing-table-three .pricing-table__feature-mark {
  height: auto;
  width: auto;
}

.pricing-table-three .pricing-table__feature-mark::before {
  content: '';
  position: absolute;
  top: -30px;
  right: -52px;
  height: 90px;
  width: 140px;
  transform: rotate(45deg);
  background-image: linear-gradient(60deg, #ffbe00 0%, #f76b1c 100%);
}

.pricing-table-three .pricing-table:hover .pricing-table__inner {
  box-shadow: 0 41px 43px rgba(51, 51, 51, 0.07);
  border: 0 solid transparent;
  transform: translateY(-5px);
}

.pricing-table-three .pricing-table:hover .pricing-table__inner::before {
  opacity: 1;
  visibility: visible;
}

.pricing-table-three .pricing-table--popular .pricing-table__inner {
  border: 0 solid transparent;
  padding: 49px 20px 51px;
}

.pricing-table-three .pricing-table--popular .pricing-table__inner::before {
  opacity: 1;
  visibility: visible;
}

.price-plan-list .list-item {
  color: rgba(255, 255, 255, 0.7);
}

.price-plan-list .list-item::before {
  color: #61fded;
}

.price-plan-wrap .sub-heading {
  font-size: 15px;
  color: #ffffff;
}

/*=====  End of pricing table  ======*/
/*=============================================
=            22. Pricing Plans Css           =
==============================================*/
.pricing-plans__inner {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.06);
}

.pricing-plans__inner table {
  table-layout: fixed;
  margin: 0;
}

.pricing-plans__inner th {
  padding: 0;
  min-width: 266px;
}

.pricing-plans__inner th:first-child {
  text-align: left;
  width: 370px;
  border-left: 0;
}

.pricing-plans__inner th:last-child {
  border-right: 0;
}

.pricing-plans__inner th .pricing-header {
  position: relative;
  overflow: hidden;
  padding: 52px 30px 32px;
}

.pricing-plans__inner th .pricing-header .ht-pricing-feature-mark {
  position: absolute;
  top: 0;
  right: 0;
  height: 90px;
  width: 140px;
}

.pricing-plans__inner th .pricing-header .ht-pricing-feature-mark::before {
  content: '';
  position: absolute;
  top: -30px;
  right: -52px;
  height: 90px;
  width: 140px;
  transform: rotate(45deg);
  background-image: linear-gradient(60deg, #ffbe00 0%, #f76b1c 100%);
}

.pricing-plans__inner th .pricing-header .ht-pricing-feature-mark span {
  position: absolute;
  top: 20px;
  right: 7px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.19;
  display: block;
  color: #fff;
  transform: rotate(45deg);
  width: 50px;
}

.pricing-plans__inner th .pricing-header.heading {
  font-size: 24px;
  color: #2e3280;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}

.pricing-plans__inner th .pricing-header .title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 34px;
  color: #2e3280;
  font-weight: 500;
}

.pricing-plans__inner th .pricing-header .price-wrap-inner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.pricing-plans__inner th .pricing-header .price-wrap-inner .currency {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
}

.pricing-plans__inner th .pricing-header .price-wrap-inner .price {
  font-size: 36px;
  line-height: .9;
  margin: 0 4px;
  font-weight: 300;
  color: #333;
  font-weight: 500;
}

.pricing-plans__inner th .pricing-header .price-wrap-inner .period {
  font-size: 15px;
  margin-bottom: 0;
  color: #ababab;
  align-self: flex-end;
}

.pricing-plans__inner td, .pricing-plans__inner th {
  border: 0;
  border-left: 1px solid rgba(51, 51, 51, 0.1);
  border-right: 1px solid rgba(51, 51, 51, 0.1);
  text-align: center;
}

.pricing-plans__inner .tbody th {
  padding: 0 30px;
}

.pricing-plans__inner td:first-child {
  border-left: 0;
}

.pricing-plans__inner td:last-child {
  border-right: 0;
}

.pricing-plans__inner td .pricing-feature-labels {
  text-align: left;
}

.pricing-plans__inner td .pricing-feature-labels li {
  padding-left: 20px;
}

.pricing-plans__inner tbody ul li {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  padding: 9px 0;
}

.pricing-plans__inner tbody ul li .item-checked {
  color: #7ed321;
  font-size: 16px;
}

.pricing-plans__inner tfoot td {
  padding: 30px 10px 40px;
}

.table-responsive {
  overflow-y: hidden;
}

/*=============================================
=            23. progress Bar Css           =
==============================================*/
.progress-bar--one .progress-charts {
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

.progress-bar--two .progress-charts {
  padding-bottom: 20px;
  overflow: hidden;
}

.progress-charts .heading {
  color: #6d70a6;
  margin-bottom: 12px;
  font-weight: 400;
}

.progress-charts .progress {
  height: 4px;
  overflow: visible;
  font-size: 14px;
  background-color: #f5f5f5;
  border-radius: 0;
}

.progress-charts .progress .progress-bar {
  position: relative;
  background-color: #9C7AF2;
  background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
  color: #5E61E7;
}

.progress-charts .progress .progress-bar::after, .progress-charts .progress .progress-bar::before {
  content: '';
  position: absolute;
  right: -4px;
  top: 50%;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

.progress-charts .progress .progress-bar::after {
  height: 13px;
  width: 13px;
  border: 3px solid currentColor;
}

.progress-charts .progress .progress-bar::before {
  height: 28px;
  width: 28px;
  border: 1px solid currentColor;
  opacity: .3;
}

.progress-charts .progress .progress-bar span.percent-label {
  position: absolute;
  right: -45px;
  font-size: 14px;
  font-weight: 500;
  top: -40px;
  color: #2e3280;
  line-height: 2;
}

.progress-charts .progress .progress-bar.gradient-1 {
  color: #61fded;
  background-color: #0d8abc;
  background-image: linear-gradient(-224deg, #0d8abc 0px, #61fded 100%);
}

.progress-charts .progress .progress-bar.gradient-2 {
  color: #eece90;
  background-color: #d45529;
  background-image: linear-gradient(-224deg, #d45529 0px, #eece90 100%);
}

.progress-charts .progress .progress-bar.gradient-3 {
  color: #5c51ff;
  background-color: #f646a9;
  background-image: linear-gradient(-224deg, #f646a9 0px, #5c51ff 100%);
}

.progress-charts .progress .progress-bar.gradient-4 {
  color: #e5529a;
  background-color: #e77654;
  background-image: linear-gradient(-224deg, #e77654 0px, #e5529a 100%);
}

.progress-charts .progress__two .progress, .progress-charts .progress__three .progress, .progress-charts .progress__four .progress {
  height: 16px;
  overflow: visible;
  font-size: 14px;
  border-radius: 0;
  height: 32px;
  padding: 6px;
  border-radius: 30px;
  box-shadow: none;
  background-color: #f2f2f2;
}

.progress-charts .progress__two .progress-bar, .progress-charts .progress__three .progress-bar, .progress-charts .progress__four .progress-bar {
  background-color: #9C7AF2;
  background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  border-radius: inherit;
}

.progress-charts .progress__two .progress-bar::after, .progress-charts .progress__two .progress-bar::before, .progress-charts .progress__three .progress-bar::after, .progress-charts .progress__three .progress-bar::before, .progress-charts .progress__four .progress-bar::after, .progress-charts .progress__four .progress-bar::before {
  display: none;
}

.progress-charts .progress__two .progress-bar span.percent-label, .progress-charts .progress__three .progress-bar span.percent-label, .progress-charts .progress__four .progress-bar span.percent-label {
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.progress-charts .progress__two .progress.gradient-1, .progress-charts .progress__three .progress.gradient-1, .progress-charts .progress__four .progress.gradient-1 {
  color: #61fded;
  background-color: #0d8abc;
  background-image: linear-gradient(-224deg, #0d8abc 0px, #61fded 100%);
}

.progress-charts .progress__two .progress.gradient-2, .progress-charts .progress__three .progress.gradient-2, .progress-charts .progress__four .progress.gradient-2 {
  color: #eece90;
  background-color: #d45529;
  background-image: linear-gradient(-224deg, #d45529 0px, #eece90 100%);
}

.progress-charts .progress__two .progress.gradient-3, .progress-charts .progress__three .progress.gradient-3, .progress-charts .progress__four .progress.gradient-3 {
  color: #5c51ff;
  background-color: #f646a9;
  background-image: linear-gradient(-224deg, #f646a9 0px, #5c51ff 100%);
}

.progress-charts .progress__two .progress.gradient-4, .progress-charts .progress__three .progress.gradient-4, .progress-charts .progress__four .progress.gradient-4 {
  color: #e5529a;
  background-color: #e77654;
  background-image: linear-gradient(-224deg, #e77654 0px, #e5529a 100%);
}

.progress-charts .progress__three .progress {
  background-color: transparent;
  border: 2px solid #f2f2f2;
}

.progress-charts .progress__four .progress {
  background-color: transparent;
  border: 2px solid #f2f2f2;
}

.progress-charts .progress__four .progress-bar {
  background-image: radial-gradient(ellipse at center, #5945e6 50%, transparent 55%);
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  background-color: transparent !important;
  background-size: 22px 22px;
  background-repeat: repeat-x;
  background-position: -3px center;
}

.progress-charts .progress__four .progress-bar span.percent-label {
  transform: translateY(-50%);
  right: -30px;
  display: block;
  font-size: 12px;
  color: #5E61E7;
}

/*=============================================
=          24. Circle Progress Css            =
=============================================*/
.circle-progress-wrapper {
  position: relative;
}

.feature-list-progress {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 575px), only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-list-progress {
    flex-direction: column;
    align-items: center;
  }
}

.feature-list-progress__info {
  margin-left: 40px;
  flex-basis: calc(100% - 170px);
}

@media only screen and (max-width: 575px), only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-list-progress__info {
    margin-top: 20px;
    margin-left: 0;
  }
}

.feature-list-progress__counter {
  flex-basis: 170px;
}

.feature-list-progress__list li {
  margin-bottom: 10px;
}

.chart-progress {
  position: relative;
  width: 160px;
  height: 160px;
}

.chart-progress canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.chart-progress.cart-pogress-small {
  width: 140px;
  height: 140px;
}

.chart-progress__inner-wrapper {
  width: 100%;
  height: 100%;
  padding: 13px;
  position: relative;
}

.chart-progress__inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f76b1c;
  background-image: linear-gradient(-224deg, #fad961 0, #f76b1c 100%);
}

.chart-progress__inner--blue {
  background-color: #9C7AF2;
  background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
}

.chart-progress__inner--bg-blue {
  background: #5945e6;
}

.chart-progress__inner--bg-green {
  background: #38cb89;
}

.chart-progress__inner--white {
  background: transparent;
}

.chart-progress__inner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chart-progress__inner-text {
  color: #ffffff;
}

.chart-progress__inner-text.text-black {
  color: #000000;
  font-size: 24px;
  font-weight: 400;
}

.chart-progress__inner-text.chart-icon {
  font-size: 34px;
}

.circle-design {
  position: absolute;
  border-radius: 50%;
  animation: jump infinite 2s;
  animation-direction: alternate;
  background-color: #f76b1c;
  background-image: linear-gradient(-224deg, #fad961 0, #f76b1c 100%);
}

.circle-design.one {
  width: 43px;
  height: 43px;
  left: 0;
  bottom: 28px;
}

.circle-design.two {
  width: 17px;
  height: 17px;
  right: -20px;
  top: 50%;
  margin-top: -8px;
  animation-delay: 1s;
}

.circle-design--blue {
  background-color: #9C7AF2;
  background-image: linear-gradient(-224deg, #9C7AF2 0, #5E61E7 100%);
}

.circle-design--bg-blue {
  background: #5945e6;
  box-shadow: 0 2px 17px rgba(89, 69, 230, 0.27);
}

.circle-design--bg-green {
  background: #38cb89;
  box-shadow: 0 2px 17px rgba(89, 69, 230, 0.27);
}

@keyframes jump {
  0% {
    transform: translateY(-10px) scale(0.9, 0.9);
  }
  100% {
    transform: translateY(10px) scale(1, 1);
  }
}

.feature-list-progress {
  z-index: 3;
  position: relative;
}

/*=====  End of circle progress  ======*/
/*=============================================
=          25. Restaurant Menu Css            =
=============================================*/
.restaurant-menu--inner {
  margin-right: 0;
  margin-left: 0;
  padding-top: 85px;
  padding-right: 53px;
  padding-bottom: 63px;
  padding-left: 53px;
  box-shadow: -1px 0 68px rgba(187, 187, 187, 0.4);
  background-color: #fff;
}

.restaurant-menu--inner .menu-list {
  padding: 0 26px 0 0;
}

.restaurant-menu--inner .menu-header {
  position: relative;
  margin-bottom: 12px;
}

.restaurant-menu--inner .menu-header::after {
  position: absolute;
  right: 0;
  bottom: 7px;
  left: 0;
  display: block;
  border-top: 1px solid #eee;
  content: "";
}

.restaurant-menu--inner .menu-header .menu-title {
  position: relative;
  z-index: 1;
  display: inline;
  background: #fff;
  font-size: 16px;
  padding-right: 10px;
}

.restaurant-menu--inner .menu-price {
  position: relative;
  z-index: 1;
  float: right;
  background-color: #fff;
  color: #1f1f1f;
  font-size: 16px;
}

.restaurant-menu--inner .menu-text {
  background: none;
  font-style: italic;
  font-size: 15px;
}

.restaurant-menu--inner li + li {
  margin-top: 38px;
}

.restaurant-menu--inner .menu-badge.new {
  position: absolute;
  top: -26px;
  right: -35px;
  width: 40px;
  height: 20px;
  background: #de4630;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 9px;
  line-height: 20px;
  z-index: 2;
}

.restaurant-menu--inner .menu-badge.new::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: -1px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #de4630;
  transform: rotate(45deg);
}

/*=============================================
=        26. Row Separators Css            =
=============================================*/
.row-separators-wrap {
  position: relative;
}

.separators-space {
  padding-top: 300px;
  padding-bottom: 300px;
}

.row-separators-images {
  background-image: url(../images/bg/aeroland-countdown-bg-image-01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.row-separators-bg {
  background: #69afed;
}

.vc_row-separator.bottom {
  bottom: 0;
}

.vc_row-separator svg {
  height: 100px;
  width: 100%;
}

.vc_row-separator {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  line-height: 0;
}

.row-separators-style .vc_row-separator svg {
  fill: #fff;
}

.square-style .vc_row-separator.square.bottom {
  transform: translate(-50%, 50%);
}

.square-style .vc_row-separator.square {
  width: auto;
  left: 50%;
}

.square-style .vc_row-separator svg {
  height: 90px;
}

.square-style .vc_row-separator svg {
  fill: #f7be68;
}

.big-triangle-style .big_triangle.bottom {
  transform: scaleY(-1);
}

.big-triangle-style .vc_row-separator svg {
  fill: #fff;
}

.tilt-right-style .tilt_right.bottom {
  transform: scale(-1, 1);
}

.tilt-right-style .vc_row-separator svg {
  fill: #69afed;
}

.tilt-left-style .vc_row-separator.top {
  top: 0;
}

.tilt-left-style .vc_row-separator.tilt_left.top {
  transform: scale(1, -1);
}

.tilt-left-style .vc_row-separator svg {
  height: 100px;
}

.tilt-left-style .vc_row-separator svg {
  fill: #69afed;
}

.triangle-bottom .vc_row-separator.triangle.bottom {
  transform: scaleY(-1);
}

.triangle-bottom .vc_row-separator.triangle.bottom {
  transform: scaleY(-1);
}

.triangle-bottom .vc_row-separator svg {
  fill: #fff;
}

.triangle-bottom .vc_row-separator svg {
  height: 40px;
}

.curve-style .vc_row-separator.bottom {
  bottom: 0;
}

.curve-style .vc_row-separator svg {
  fill: #fff;
}

.curve-style .vc_row-separator svg {
  height: 50px;
}

.waves-style .vc_row-separator.bottom {
  bottom: 0;
}

.waves-style .vc_row-separator svg {
  height: 30px;
}

.waves-style .vc_row-separator svg {
  fill: #69afed;
}

.circle-style .vc_row-separator.top {
  top: 0;
}

.circle-style .vc_row-separator svg {
  height: 50px;
}

.circle-style .vc_row-separator svg {
  fill: #69afed;
}

.particles-area {
  position: relative;
}

.constellation-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.vc_row-separator.bottom {
  bottom: 0;
}

.triangle-one-style .vc_row-separator svg, .triangle-two--style .vc_row-separator svg {
  fill: #69afed;
}

.triangle-one-style .vc_row-separator svg, .triangle-two--style .vc_row-separator svg {
  height: 200px;
}

.triangle-two--style .vc_row-separator.tilt_right.top {
  transform: scale(-1, -1);
}

/*=============================================
=        27. Social Networks Css            =
=============================================*/
.ht-social-networks .item {
  display: inline-block;
}

.ht-social-networks .item .social-link {
  display: block;
  padding: 5px 8px;
}

.ht-social-networks.large-icon .social-link {
  padding: 5px 13px;
  font-size: 20px;
}

.ht-social-networks.extra-large-icon .social-link {
  padding: 5px 15px;
  font-size: 30px;
}

.ht-social-networks.flat-round .item, .ht-social-networks.solid-rounded-icon .item {
  margin: 8px;
}

.ht-social-networks.flat-round .social-link, .ht-social-networks.solid-rounded-icon .social-link {
  font-size: 18px;
  display: block;
  text-align: center;
  height: 48px;
  width: 48px;
  line-height: 40px;
  background: #ededed;
  color: #696969;
  border-radius: 50%;
  position: relative;
}

.ht-social-networks.flat-round .social-link:hover, .ht-social-networks.solid-rounded-icon .social-link:hover {
  color: #ffffff;
  background: #5945e6;
}

.ht-social-networks.solid-rounded-icon .social-link {
  background: transparent;
  border: 1px solid #ededed;
}

.ht-social-networks.solid-rounded-icon .social-link:hover {
  border: 1px solid #5945e6;
  color: #ffffff;
}

.ht-social-networks.icon-tilte .link-icon {
  font-size: 16px;
  min-width: 16px;
  margin-right: 10px;
}

.ht-social-networks.tilte-style .social-link {
  position: relative;
}

.ht-social-networks.tilte-style .social-link::before {
  content: '-';
  position: absolute;
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
  color: #333;
}

.ht-social-networks.tilte-style .social-link:hover .link-text::after {
  background-color: #38cb89;
  width: 100%;
  left: 0;
}

.ht-social-networks.tilte-style .item:first-child .social-link::before {
  display: none;
}

.ht-social-networks.tilte-style .link-text {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: #333;
  position: relative;
  padding: 5px 0;
  z-index: 1;
}

.ht-social-networks.tilte-style .link-text::before {
  content: '';
  height: 2px;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -2;
  background: rgba(0, 0, 0, 0.2);
}

.ht-social-networks.tilte-style .link-text::after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
}

/*=============================================
=        28. Tabs Css            =
=============================================*/
.ht-tab-menu li {
  margin: 0 10px;
}

.ht-tab-menu li a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #ededed !important;
  border: #ededed;
  background: #fff;
  color: #696969;
  padding: 21px 20px 19px;
  min-width: 200px;
  border-radius: 5px !important;
}

.ht-tab-menu li a.active {
  color: #fff;
  background: #7c82fe;
  border-color: transparent !important;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-tab-menu li a {
    padding: 12px 13px 12px;
    min-width: 150px;
    margin-bottom: 10px;
  }
}

.pro-large-img img {
  border-radius: 20px;
}

.tab-content-inner .tab-button .ht-btn {
  min-width: 170px;
}

.tab-content-inner .ht-service-box--three {
  padding: 41px 30px 45px 42px;
  border: 1px solid rgba(182, 183, 210, 0.5);
  box-shadow: 0 10px 40px 5px rgba(89, 69, 230, 0.05);
  background: #fff;
  border-radius: 5px;
}

.tab-content-inner .ht-service-box--three:hover {
  box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
  border-color: #fff;
}

.tab-content-inner .ht-service-box--three .image__media {
  min-width: 120px;
  text-align: left;
}

.tab-content-inner .ht-service-box--three .content .sub-heading {
  font-size: 15px;
  color: #ababab;
  letter-spacing: 2px;
}

.tab-content-inner .ht-service-box--three .content .heading {
  color: #333;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-content-inner {
    margin-top: 30px;
  }
}

.tab-slider-nav--list {
  display: block;
  margin-bottom: 50px;
  opacity: 0.6;
}

.tab-slider-nav--list:hover {
  opacity: 0.9;
}

.tab-slider-nav--list .ht-slider-tab-icon {
  background: rgba(255, 255, 255, 0.2);
  display: inline-block;
  float: left;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 32px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  margin-right: 30px;
}

.tab-slider-nav--list h5 {
  display: block;
  color: #fff;
  max-width: 280px;
}

.tab-slider-nav--list p {
  margin-top: 26px;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-slider-nav--list:last-child {
    margin-bottom: 0;
  }
}

.tab-slider-nav .slick-current .tab-slider-nav--list {
  opacity: 1;
}

.tab-slider-nav .slick-current .tab-slider-nav--list .ht-slider-tab-icon {
  background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%);
}

.start-ups-left-tab .tab-slider-nav--list {
  display: block;
  margin-bottom: 50px;
  opacity: 0.6 !important;
}

.start-ups-left-tab .tab-slider-nav--list:hover {
  opacity: 0.9;
}

.start-ups-left-tab .tab-slider-nav .slick-current .tab-slider-nav--list {
  opacity: 1 !important;
}

.start-ups-left-tab .tab-slider-nav .slick-current .tab-slider-nav--list .ht-slider-tab-icon {
  background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%) !important;
}

.tab-slider-images .slick-dots {
  text-align: center;
  margin-top: 30px;
}

.tab-slider-images .slick-dots li {
  display: inline-block;
  margin: 0 15px;
}

.tab-slider-images .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 8px;
  height: 8px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.tab-slider-images .slick-dots li button:hover {
  width: 10px;
  height: 10px;
}

.tab-slider-images .slick-dots li.slick-active button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0 0 9px #ededed;
}

/*=============================================
=        29. Team Member Css            =
=============================================*/
.ht-team-member .team-image {
  position: relative;
  margin-bottom: 31px;
}

.ht-team-member .team-info .name {
  color: #333;
  line-height: 1.5;
  margin-bottom: 12px;
}

.ht-team-member .team-info .position {
  font-size: 14px;
  color: #6d70a6;
}

.ht-team-member .team-info .social-networks {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ht-team-member .team-info .social-networks a {
  display: inline-block;
  font-size: 18px;
  color: #696969;
  margin: 14px;
}

.ht-team-member:hover .social-networks {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.ht-team-member--inner {
  background: #fff;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(51, 51, 51, 0.1);
}

.ht-team-member--inner .team-image {
  margin-bottom: 24px;
}

/*----------------------------
    tooltip library
----------------------------*/
[class*="hint--"] {
  position: relative;
  display: inline-block;
  /**
	 * tooltip arrow
	 */
  /**
	 * tooltip body
	 */
}

[class*="hint--"]:before, [class*="hint--"]:after {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms;
}

[class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
  visibility: visible;
  opacity: 1;
}

[class*="hint--"]:hover:before, [class*="hint--"]:hover:after {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  transition-delay: 100ms;
}

[class*="hint--"]:before {
  content: '';
  position: absolute;
  background: transparent;
  border: 7px solid transparent;
  z-index: 1000001;
}

[class*="hint--"]:after {
  background: #5945e6;
  color: white;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

[class*="hint--"][aria-label]:after {
  content: attr(aria-label);
}

[class*="hint--"][data-hint]:after {
  content: attr(data-hint);
}

[aria-label='']:before, [aria-label='']:after,
[data-hint='']:before,
[data-hint='']:after {
  display: none !important;
}

/**
 * source: hint-position.scss
 *
 * Defines the positoning logic for the tooltips.
 *
 * Classes added:
 * 	1) hint--top
 * 	2) hint--bottom
 * 	3) hint--left
 * 	4) hint--right
 */
/**
 * set default color for tooltip arrows
 */
.hint--top-left:before {
  border-top-color: #5945e6;
}

.hint--top-right:before {
  border-top-color: #5945e6;
}

.hint--top:before {
  border-top-color: #5945e6;
}

.hint--bottom-left:before {
  border-bottom-color: #5945e6;
}

.hint--bottom-right:before {
  border-bottom-color: #5945e6;
}

.hint--bottom:before {
  border-bottom-color: #5945e6;
}

.hint--left:before {
  border-left-color: #5945e6;
}

.hint--right:before {
  border-right-color: #5945e6;
}

.hint--white:after {
  background: #ffffff;
  color: #333;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 5px;
}

.hint--white:before {
  border-top-color: #ffffff;
}

.hint--black:after {
  background: #000000;
  color: #ffffff;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 5px;
}

.hint--black:before {
  border-top-color: #000000;
}

.hint--black.hint--bottom::before {
  border-bottom-color: #000000;
}

.hint--black.hint--top-left::before {
  border-top-color: #000000;
}

.hint--black.hint--top::before {
  border-top-color: #000000;
}

.hint--black.hint--left::before {
  border-left-color: #000000;
}

.hint--black:before {
  border-top-color: transparent;
}

/**
 * top tooltip
 */
.hint--top:before {
  margin-bottom: -13px;
}

.hint--top:before, .hint--top:after {
  bottom: 100%;
  left: 50%;
}

.hint--top:before {
  left: calc(50% - 7px);
}

.hint--top:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hint--top:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top:hover:after {
  -webkit-transform: translateX(-50%) translateY(-8px);
  -moz-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}

/**
 * bottom tooltip
 */
.hint--bottom:before {
  margin-top: -13px;
}

.hint--bottom:before, .hint--bottom:after {
  top: 100%;
  left: 50%;
}

.hint--bottom:before {
  left: calc(50% - 7px);
}

.hint--bottom:after {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hint--bottom:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom:hover:after {
  -webkit-transform: translateX(-50%) translateY(8px);
  -moz-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}

/**
 * right tooltip
 */
.hint--right:before {
  margin-left: -13px;
  margin-bottom: -7px;
}

.hint--right:after {
  margin-bottom: -15px;
}

.hint--right:before, .hint--right:after {
  left: 100%;
  bottom: 50%;
}

.hint--right:hover:before {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

.hint--right:hover:after {
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  transform: translateX(8px);
}

/**
 * left tooltip
 */
.hint--left:before {
  margin-right: -13px;
  margin-bottom: -7px;
}

.hint--left:after {
  margin-bottom: -15px;
}

.hint--left:before, .hint--left:after {
  right: 100%;
  bottom: 50%;
}

.hint--left:hover:before {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

.hint--left:hover:after {
  -webkit-transform: translateX(-8px);
  -moz-transform: translateX(-8px);
  transform: translateX(-8px);
}

/**
 * top-left tooltip
 */
.hint--top-left:before {
  margin-bottom: -13px;
}

.hint--top-left:before, .hint--top-left:after {
  bottom: 100%;
  left: 50%;
}

.hint--top-left:before {
  left: calc(50% - 7px);
}

.hint--top-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.hint--top-left:after {
  margin-left: 14px;
}

.hint--top-left:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(-8px);
  -moz-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}

/**
 * top-right tooltip
 */
.hint--top-right:before {
  margin-bottom: -13px;
}

.hint--top-right:before, .hint--top-right:after {
  bottom: 100%;
  left: 50%;
}

.hint--top-right:before {
  left: calc(50% - 7px);
}

.hint--top-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.hint--top-right:after {
  margin-left: -14px;
}

.hint--top-right:hover:before {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

.hint--top-right:hover:after {
  -webkit-transform: translateY(-8px);
  -moz-transform: translateY(-8px);
  transform: translateY(-8px);
}

/**
 * bottom-left tooltip
 */
.hint--bottom-left:before {
  margin-top: -13px;
}

.hint--bottom-left:before, .hint--bottom-left:after {
  top: 100%;
  left: 50%;
}

.hint--bottom-left:before {
  left: calc(50% - 7px);
}

.hint--bottom-left:after {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.hint--bottom-left:after {
  margin-left: 14px;
}

.hint--bottom-left:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom-left:hover:after {
  -webkit-transform: translateX(-100%) translateY(8px);
  -moz-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}

/**
 * bottom-right tooltip
 */
.hint--bottom-right:before {
  margin-top: -13px;
}

.hint--bottom-right:before, .hint--bottom-right:after {
  top: 100%;
  left: 50%;
}

.hint--bottom-right:before {
  left: calc(50% - 7px);
}

.hint--bottom-right:after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.hint--bottom-right:after {
  margin-left: -14px;
}

.hint--bottom-right:hover:before {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--bottom-right:hover:after {
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  transform: translateY(8px);
}

.hint--no-animate:before, .hint--no-animate:after {
  -webkit-transition-duration: 0ms;
  -moz-transition-duration: 0ms;
  transition-duration: 0ms;
}

.hint--bounce:before, .hint--bounce:after {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.3s ease, visibility 0.3s ease, -moz-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}

/*=============================================
=           30. Testimonial slider Css           =
=============================================*/
.testimonial-slider {
  margin: -15px;
  margin-bottom: 0;
}

.testimonial-slider__container {
  padding: 15px;
  padding-bottom: 0;
}

.testimonial-slider__single {
  background: #ffffff;
  padding: 51px 37px 40px;
  border-radius: 5px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.testimonial-slider__single:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
}

.testimonial-slider__media {
  margin-bottom: 20px;
}

.testimonial-slider__text {
  font-size: 18px;
  color: #6d70a6;
  margin-bottom: 25px;
}

.testimonial-slider__author .name {
  color: #333333;
  margin-bottom: 10px;
}

.testimonial-slider__author .designation {
  font-style: italic;
}

.testimonial-slider__author .designation:before {
  content: ' / ';
}

.testimonial-slider__one .testimonial-slider--info {
  display: flex;
  align-items: center;
}

.testimonial-slider__one .testimonial-slider--info .post-thumbnail {
  margin-right: 20px;
}

.testimonial-slider__thum {
  margin-bottom: 33px;
}

.testimonial-slider__dec {
  font-size: 34px;
  line-height: 1.42;
  text-align: center;
  color: #fff;
  width: 800px;
  max-width: 90%;
  margin: 0 auto;
}

.testimonial-slider__poster {
  padding-top: 45px;
  display: inline-block;
}

.testimonial-slider__three .testimonial-slider__author {
  display: inline-block;
  color: #ffffff;
  margin-top: 30px;
}

.testimonial-slider__three .testimonial-slider__author .name {
  display: inline-block;
  font-size: 18px;
  line-height: 2;
  color: #fff;
  margin-bottom: 0;
}

.testimonial-slider__three .testimonial-slider__author .designation {
  display: inline-block;
  color: #fff;
  font-size: 14px;
}

/*=====  End of testimonial slider  ======*/
/*=============================================
=           31. Timeline Css           =
=============================================*/
.ht-timeline.style-01 .tm-timeline-list {
  position: relative;
  padding-top: 50px;
}

.ht-timeline.style-01 .line {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  height: 100%;
  border-left: 2px dashed rgba(89, 69, 230, 0.5);
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .line {
    left: 26px;
  }
}

.ht-timeline.style-01 .item {
  position: relative;
  margin: 0 auto;
  width: 1px;
  list-style-type: none;
  padding-top: 40px;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .item {
    padding-left: 93px;
    width: 100%;
  }
}

.ht-timeline.style-01 .item:nth-child(2n) .content-wrap {
  left: -386px;
  transform: translateX(-100px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-timeline.style-01 .item:nth-child(2n) .content-wrap {
    left: -360px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-timeline.style-01 .item:nth-child(2n) .content-wrap {
    left: -240px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .item:nth-child(2n) .content-wrap {
    left: auto !important;
    right: auto !important;
    width: 100%;
    max-width: 100%;
    transform: translateX(0px);
  }
}

.ht-timeline.style-01 .item:nth-child(2n+1) .content-wrap {
  left: 0px;
  transform: translateX(100px);
}

.ht-timeline.style-01 .item:nth-child(2n+1) .content-wrap::after {
  right: auto;
  left: -19px;
  border-width: 0 19px 20px 0;
  border-color: transparent #fff transparent transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ht-timeline.style-01 .item:nth-child(2n+1) .content-wrap {
    left: -25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-timeline.style-01 .item:nth-child(2n+1) .content-wrap {
    left: -25px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .item:nth-child(2n+1) .content-wrap {
    left: auto !important;
    right: auto !important;
    width: 100%;
    max-width: 100%;
    transform: translateX(0px);
  }
}

.ht-timeline.style-01 .item:nth-child(2n+1) .date-wrap {
  left: -480px;
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-timeline.style-01 .item:nth-child(2n+1) .date-wrap {
    left: -380px;
  }
}

.ht-timeline.style-01 .dots {
  position: absolute;
  top: 112px;
  right: -26px;
  width: 54px;
  height: 54px;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .dots {
    top: 183px;
    right: auto;
    left: 0;
  }
}

.ht-timeline.style-01 .dots::after, .ht-timeline.style-01 .dots::before {
  content: '';
  position: absolute;
  background: #5945e6;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ht-timeline.style-01 .dots::before {
  opacity: .1;
  width: 54px;
  height: 54px;
}

.ht-timeline.style-01 .dots::after {
  opacity: .18;
  width: 32px;
  height: 32px;
}

.ht-timeline.style-01 .dots .middle-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-image: linear-gradient(-218deg, #9c7af2 0%, #5e61e7 100%);
  z-index: 2;
}

.ht-timeline.style-01 .date-wrap {
  position: absolute;
  top: 90px;
  left: 86px;
  color: rgba(17, 17, 17, 0.45);
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .date-wrap {
    position: static;
    text-align: left !important;
    margin-bottom: 20px;
  }
}

.ht-timeline.style-01 .date-wrap, .ht-timeline.style-01 .content-wrap {
  width: 400px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-timeline.style-01 .date-wrap, .ht-timeline.style-01 .content-wrap {
    width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .date-wrap, .ht-timeline.style-01 .content-wrap {
    left: auto !important;
    right: auto !important;
    width: 100%;
    max-width: 100%;
    transform: translateX(0px);
  }
}

.ht-timeline.style-01 .content-wrap {
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.1));
}

.ht-timeline.style-01 .content-wrap::after {
  position: absolute;
  top: 95px;
  right: -19px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 19px 0 0;
  border-color: #fff transparent transparent transparent;
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .content-wrap::after {
    left: -19px;
    border-width: 0 19px 20px 0;
    border-color: transparent #fff transparent transparent;
  }
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .content-wrap {
    left: auto !important;
    right: auto !important;
    width: 100%;
    max-width: 100%;
  }
}

.ht-timeline.style-01 .content-body {
  padding: 39px 30px 34px;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.1));
}

.ht-timeline.style-01 .year {
  font-size: 80px;
  line-height: 1.2;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(180deg, #ffbe00 0%, #f76b1c 100%);
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ht-timeline.style-01 .year {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .ht-timeline.style-01 .year {
    font-size: 40px;
  }
}

/* =================
    Typed Text 
======================*/
.cd-headline.clip span {
  padding: 0;
}

.cd-headline.clip .cd-words-wrapper.brown-color::after {
  background-color: #ddd;
  width: 4px;
}

.cd-headline.clip span {
  padding: 0;
}

.cd-headline.clip .cd-words-wrapper.brown-color::after {
  background-color: #f10;
  width: 4px;
}

.typed-text-wrap {
  text-align: center;
}

.typed-text-wrap h4 {
  max-width: 700px;
  font-size: 48px;
  line-height: 1.34;
  margin-bottom: 0;
  word-wrap: break-word;
  font-weight: 500;
  margin-top: -20px;
}

.typed-text-wrap h4 span b {
  font-weight: 300;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .typed-text-wrap h4 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .typed-text-wrap h4 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .typed-text-wrap h4 {
    font-size: 20px;
  }
}

/*=============================================
=           32. Gallery Css           =
=============================================*/
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .gallery-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .grid-3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gallery-grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallery-grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .gallery-grid-4 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-height-2 {
  grid-row: span 2;
}

@media only screen and (max-width: 767px) {
  .grid-height-2 {
    grid-column: span 1;
  }
}

.grid-width-2 {
  grid-column: span 2;
}

@media only screen and (max-width: 767px) {
  .grid-width-2 {
    grid-column: span 1;
  }
}

.gallery-grid.grid-gap__none {
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.masonary-item {
  overflow: hidden;
}

.single-gallery {
  display: block;
  position: relative !important;
  width: 100%;
  height: 100%;
}

.lg-actions button {
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff !important;
}

.lg-actions button:hover {
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-position: right center;
}

.single-gallery {
  overflow: hidden;
  display: block;
}

.single-gallery__item {
  position: relative;
}

.single-gallery__item .ht-overlay {
  background-color: #5945e6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-gallery__item .overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-gallery__item .overlay-icon i {
  font-size: 24px;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-gallery__item:hover .ht-overlay, .single-gallery__item:hover .overlay-icon {
  opacity: .9;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .single-gallery__thum img {
    width: 100%;
  }
}

.bg-item-images {
  position: relative;
  display: flex;
  align-items: stretch;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.bg-item-images::before {
  padding-top: 67.6%;
  content: '';
  display: block;
}

.carousel-slider__content {
  margin-top: 25px;
  padding: 0 20px;
}

.carousel-slider__content .headeing {
  margin-bottom: 10px;
}

.auto-plexible-row .swiper-slide {
  width: auto;
}

.auto-plexible-row .single-flexible-slider img {
  height: 400px;
  /*width: auto;*/
}

/*=============================================
=            33. Box image Css           =
=============================================*/
.box-image-wrapper {
  margin-bottom: -40px;
}

.box-image {
  margin-bottom: 40px;
}

.box-image__media {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-bottom: 20px;
}

.box-image__title {
  color: #333333;
}

.box-image:hover .box-image__media {
  transform: translateY(-5px);
}

.box-image:last-child:after {
  display: none;
}

@media only screen and (max-width: 767px) {
  .box-image:after {
    display: none;
  }
}

.box-image:after {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100px;
  background: #d1d9df;
  width: 1px;
}

/*=====  End of box image  ======*/
/*=============================================
=            34. Feature list Css           =
=============================================*/
.requirements-list-area {
  padding-left: 15px;
  padding-right: 15px;
}

.requirements-list {
  width: 600px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100%;
  float: right;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .requirements-list {
    margin-top: 150px;
    margin-bottom: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .requirements-list {
    padding-left: 0;
    padding-right: 0;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .requirements-list {
    margin: 0 auto;
    float: none;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .requirements-list {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 60px;
  }
}

.requirements-list__text {
  max-width: 370px;
  color: #696969;
}

.requirements-list-image {
  margin-left: -115px;
  margin-top: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .requirements-list-image {
    margin-top: 0;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .requirements-list-image {
    margin-top: 80px;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .requirements-list-image {
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.section-text {
  display: block;
  max-width: 560px;
  line-height: 2.14;
  margin: 0 auto;
}

/* feature icon list */
.feature-icon-list {
  padding-left: 15px;
  padding-right: 15px;
}

.feature-icon-list__media {
  text-align: right;
  padding-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-icon-list__media {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .feature-icon-list__media {
    padding-right: 0;
    margin-bottom: 40px;
    text-align: center;
  }
}

.feature-icon-list__content {
  width: 600px;
  padding: 0 30px;
  max-width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .feature-icon-list__content {
    padding: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-icon-list__content {
    padding: 0 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .feature-icon-list__content {
    margin: 0 auto;
    padding: 0;
  }
}

.feature-icon-list-wrapper .single-feature-icon {
  display: flex;
  padding: 41px 22px 45px;
  box-shadow: 0 10px 40px 5px rgba(89, 69, 230, 0.05);
  background: #fff;
  border-radius: 5px;
  margin-bottom: 25px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.feature-icon-list-wrapper .single-feature-icon:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 50px 5px rgba(89, 69, 230, 0.1);
}

.feature-icon-list-wrapper .single-feature-icon__media {
  flex-shrink: 0;
  margin: -19px 20px 0 0;
}

.feature-icon-list-wrapper .single-feature-icon__content .title {
  color: #333333;
  margin-bottom: 15px;
}

.feature-icon-list-wrapper .single-feature-icon:nth-child(2n) {
  margin-left: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-icon-list-wrapper .single-feature-icon:nth-child(2n) {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .feature-icon-list-wrapper .single-feature-icon:nth-child(2n) {
    margin-left: 0;
  }
}

.feature-icon-list-wrapper .single-feature-icon:last-child {
  margin-bottom: 0;
}

/*=====  End of feature list  ======*/
/*=============================================
=            35. Feature slider Css           =
=============================================*/
.feature-slider__single-slide .feature-slider__single-slide-wrapper {
  padding: 0 15px;
}

.feature-slider__single-slide .feature-slider__single-slide-wrapper .image {
  text-align: right;
  opacity: 0;
  transform: translateX(-100%);
  transition-delay: .5s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .feature-slider__single-slide .feature-slider__single-slide-wrapper .image {
    margin-right: 0;
  }
}

.feature-slider__single-slide .feature-slider__single-slide-wrapper .content {
  margin-bottom: 50px;
  width: 570px;
  max-width: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  padding: 54px 50px 52px;
  transition-delay: .5s;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (max-width: 767px) {
  .feature-slider__single-slide .feature-slider__single-slide-wrapper .content {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-slider__single-slide .feature-slider__single-slide-wrapper .content {
    padding: 30px;
  }
}

.feature-slider__single-slide .feature-slider__single-slide-wrapper .content .title {
  line-height: 1.5;
  color: #333333;
  font-weight: 400;
  margin-bottom: 15px;
}

.feature-slider__single-slide .feature-slider__single-slide-wrapper .content .text {
  font-size: 18px;
  line-height: 1.8;
}

.feature-slider__single-slide.swiper-slide-active .feature-slider__single-slide-wrapper .image {
  opacity: 1;
  transform: translateX(0);
}

.feature-slider__single-slide.swiper-slide-active .feature-slider__single-slide-wrapper .content {
  opacity: 1;
  transform: translateX(0);
}

/*=====  End of feature slider  ======*/
/*=============================================
=        36. Brand logo slider Css            =
=============================================*/
.brand-logo-wrapper {
  margin-bottom: -100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-wrapper {
    margin-bottom: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrapper {
    margin-bottom: -30px;
  }
}

.brand-logo-slider__container {
  padding: 15px 0;
}

.brand-logo {
  align-self: center;
  text-align: center;
  position: relative;
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo {
    margin-bottom: 30px;
  }
}

.brand-logo__image {
  transition: all .4s linear;
}

.brand-logo__image-hover {
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, 20px);
  transition: all .4s linear;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
}

.brand-logo:hover .brand-logo__image {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
}

.brand-logo:hover .brand-logo__image-hover {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}

.brand-logo--slider {
  margin-bottom: 0;
}

.agency-brand-logo {
  align-self: center;
  text-align: center;
  position: relative;
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .agency-brand-logo {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .agency-brand-logo {
    margin-bottom: 30px;
  }
}

.agency-brand-logo__image {
  transition: all .4s linear;
}

.agency-brand-logo:hover .brand-logo__image {
  opacity: .2;
}

/*=====  End of brand logo slider  ======*/
/*=============================================
=        37. Branding Page Css            =
=============================================*/
.branding-space--ptb {
  padding-top: 239px;
  padding-bottom: 148px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .branding-space--ptb {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .branding-space--ptb {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .branding-space--ptb {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.branding-hero-bg {
  background: url(../images/hero/aeroland-slider-branding-slide-01-bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.branding-hero-rv-inner {
  margin-left: -300px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .branding-hero-rv-inner {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .branding-hero-rv-inner {
    margin-left: -100px;
  }
}

.branding-hero-text-wrap .brand-hero-title {
  color: #333;
}

.branding-hero-text-wrap .sub-heading {
  font-size: 18px;
  max-width: 490px;
  line-height: 1.78;
  color: #696969;
}

.saas-banner-background {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 500px;
}

.saas-banner-background .banner__text {
  font-size: 18px;
  line-height: 1.78;
}

.saas-banner-background .saas-background__links img {
  border-radius: 5px;
  margin-right: 10px;
}

/*=============================================
=        38. App Showcase Css           =
=============================================*/
.app-showcase-hero-area {
  background: #5e61e7;
  background-image: url(../images/hero/aeroland-app-showcase-hero-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.app-showcase-hero-area .vc_row-separator svg {
  height: 178px;
  fill: #fff;
}

.app-showcase-hero__text {
  padding: 140px 0 90px;
}

.app-showcase-hero__text .heading {
  font-weight: 300;
}

.app-showcase-hero__text .showcase-background__links img {
  border-radius: 5px;
  margin-right: 10px;
}

@media only screen and (max-width: 575px) {
  .app-showcase-hero__text .showcase-background__links img {
    margin-right: 0px;
    margin-top: 10px;
  }
}

.app-showcase-hero__sub {
  margin-top: 15px;
  text-align: center;
}

.app-showcase-hero__sub p {
  color: #ffffff;
  font-size: 18px;
}

.app-showcase-progress__title {
  display: flex;
  justify-content: center;
  z-index: 4;
  position: relative;
}

.app-showcase-progress__title .chart-progress__inner-wrapper {
  padding: 30px;
}

.app-showcase-step-wrap {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F8F8F8), color-stop(50%, #ECEEFA), color-stop(100%, #E3E9FF));
  background: -moz-linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
  background: -webkit-linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
  background: -o-linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
  background: -ms-linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
  background: linear-gradient(180deg, #F8F8F8 0, #ECEEFA 50%, #E3E9FF 100%);
}

.feature-app-showcase .app-showcase-image__media {
  text-align: right;
  margin-right: 50px;
}

.feature-app-showcase .app-showcase__content {
  max-width: 570px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.feature-app-showcase .app-showcase__content .sub-text {
  font-size: 18px;
  line-height: 1.78;
  margin-top: 30px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-app-showcase .app-showcase__content {
    margin-top: 30px;
  }
}

.app-showcase-section__titel {
  position: relative;
}

.app-showcase-section__titel svg:not(:root) {
  overflow: hidden;
}

.app-showcase-section__titel .vc_row-separator {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  line-height: 0;
}

.app-showcase-section__titel .vc_row-separator.bottom {
  bottom: 0;
}

.app-showcase-section__titel .vc_row-separator.center_curve.bottom {
  transform: rotate(-180deg);
}

.app-showcase-section__titel .vc_row-separator svg {
  height: 130px;
}

.app-showcase-section__titel .vc_row-separator svg {
  fill: #f8f8f8;
}

.app-showcase-section__titel .vc_row-separator svg {
  height: 130px;
}

.app-showcase-section__titel .vc_row-separator.top svg {
  height: 120px;
}

.app-showcase-section__titel .vc_row-separator.center_curve.top svg {
  fill: #e3e9ff;
}

.app-showcase-process-wrapper {
  position: relative;
}

.app-showcase-process-step__content {
  max-width: 470px;
  width: 100%;
}

.app-showcase-process-step__content .sub__title {
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.34;
}

.app-showcase-process-step__content .sub__title h6 {
  color: #ee7455;
  font-size: 15px;
}

.app-showcase-process-step__content .sub-text {
  font-size: 18px;
  line-height: 1.78;
  margin-top: 20px;
}

.app-showcase-process-step__one .app-showcase-process-step__media img {
  border-radius: 37px;
}

.app-showcase-process-step__three .app-showcase-process-step__media {
  margin-left: -113px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .app-showcase-process-step__three .app-showcase-process-step__media {
    margin-left: 0;
  }
}

/*======================================
    39. Software Pages Css
======================================*/
.sofftware-progress__content {
  max-width: 600px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.software-accordion-wrapper {
  z-index: 4;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .software-contact__images .wavify-wrapper.rotate_wavify {
    transform: translateY(-60%) rotateZ(180deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .software-contact__images .wavify-wrapper.rotate_wavify {
    transform: translateY(-40%) rotateZ(180deg);
  }
}

@media only screen and (max-width: 767px) {
  .software-contact__images .wavify-wrapper.rotate_wavify {
    transform: translateY(0%) rotateZ(180deg);
  }
}

/*======================================
    Start Ups Pages Css
======================================*/
.start-ups-hero--bg__images {
  background: url(../images/bg/aeroland-startup-hero-bg-reup.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.start-ups-hero__content {
  padding-top: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .start-ups-hero__content {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .start-ups-hero__content {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .start-ups-hero__content {
    padding-top: 0px;
  }
}

.start-ups-hero__text {
  max-width: 530px;
  width: 100%;
  color: #ffffff;
}

.start-ups-hero__text h1 {
  color: #ffffff;
}

.start-ups-hero__text .text-hero {
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.78;
}

.start-ups-hero__text .hero-btn-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .start-ups-hero__text .hero-btn-wrap {
    flex-direction: column;
  }
  .start-ups-hero__text .hero-btn-wrap .sub-heading {
    margin-left: 0px;
    margin-top: 15px;
  }
}

.start-ups-hero__text .hero-btn-wrap .sub-heading {
  margin-left: 25px;
  font-size: 15px;
  color: #ffffff;
}

.start-ups-hero--images__midea {
  z-index: 2;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .start-ups-hero--images__midea {
    margin-top: 30px;
  }
}

.section-space--start-pt {
  padding-top: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space--start-pt {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--start-pt {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--start-pt {
    padding-top: 40px;
  }
}

.ht-start-up-section-bg {
  background: url(../images/bg/aeroland-startup-image-05.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.modern-grid__box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .modern-grid__box {
    justify-content: center;
  }
}

.start-ups-left-tab .tab-slider-nav--list .ht-slider-tab-icon {
  background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%);
}

.start-ups-left-tab .tab-slider-nav--list h5 {
  color: #2e3280;
}

.start-ups-left-tab .tab-slider-nav--list p {
  color: #696969;
}

.start-ups-left-tab .tab-slider-images .slick-dots li button {
  background: #B6B7D2;
}

.start-ups-left-tab .tab-slider-images .slick-dots li.slick-active button {
  background: #5945e6;
}

.banner-gradient-bg {
  background: url(../images/patterns/aeroland-startup-image-03.png) top right 170px no-repeat, linear-gradient(-259deg, #8383ED 0, #B095F5 65%);
}

.banner-content__start-up .banner__text {
  font-size: 18px;
  line-height: 1.78;
}

.banner-content__start-up .start-ups-background__links img {
  border-radius: 5px;
  margin-right: 10px;
}

/*======================================
    Payment Pages Css
======================================*/
.payment-hero__slider {
  overflow: hidden;
}

.payment-hero--bg__images {
  background: url(../images/hero/aeroland-payment-hero-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.payment-hero__text {
  max-width: 665px;
  width: 100%;
  color: #ffffff;
  margin-top: 150px;
  z-index: 3;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .payment-hero__text {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .payment-hero__text {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-hero__text {
    margin-top: 0px;
    text-align: center;
    margin: auto;
  }
}

.payment-hero__text h1 {
  color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .payment-hero__text h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .payment-hero__text h1 {
    font-size: 34px;
  }
}

.payment-hero__text .text-hero {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.78;
  max-width: 535px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-hero__text .text-hero {
    text-align: center;
    margin: 25px auto;
  }
}

.payment-hero__text .hero-btn-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .payment-hero__text .hero-btn-wrap {
    flex-direction: column;
  }
  .payment-hero__text .hero-btn-wrap .sub-heading {
    margin-left: 0px;
    margin-top: 15px;
  }
}

.payment-hero__text .hero-btn-wrap .sub-heading {
  margin-left: 25px;
  font-size: 15px;
  color: #ffffff;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-hero__text .hero-btn-wrap {
    justify-content: center;
  }
}

.payment-hero--images__midea {
  z-index: 2;
  position: relative;
  margin-left: -243px;
  margin-right: -205px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .payment-hero--images__midea {
    margin-left: -30px;
    margin-right: -30px;
    padding-top: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .payment-hero--images__midea {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-hero--images__midea {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 30px;
  }
}

.payment-feature {
  z-index: 1;
  position: relative;
}

.payment_feature_svg {
  z-index: -0;
}

.payment_feature_svg.vc_row-separator svg {
  height: 520px;
  width: 100%;
}

.payment_feature_svg.vc_row-separator.tilt_right.top {
  transform: scale(-1, -1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .payment_feature_svg.vc_row-separator.bottom {
    bottom: -25px;
  }
}

.payment-typed-text-wrap {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5E60E7), color-stop(100%, #9C7AF2));
  background: -webkit-linear-gradient(left, #5E60E7 0, #9C7AF2 100%);
  background: -o-linear-gradient(left, #5E60E7 0, #9C7AF2 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #5E60E7), to(#9C7AF2));
  background: linear-gradient(left, #5E60E7 0, #9C7AF2 100%);
  background: -ms-linear-gradient(left, #5E60E7 0, #9C7AF2 100%);
}

.payment-typed-text-wrap .typed-text-wrap {
  display: flex;
  justify-content: center;
}

.payment-typed-text-wrap .typed-text-wrap h4 {
  max-width: 100%;
  color: #ffffff;
  margin-top: 0;
}

.payment-typed-text-wrap .typed-text-wrap h4 span b {
  font-weight: 400;
}

.payment-process-step__one .payment-process-step__content {
  margin-top: 200px;
  position: relative;
}

.payment-process-step__one .payment-process-step__content sup {
  right: 40px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-process-step__one .payment-process-step__content {
    margin-top: 60px;
  }
}

.payment-process-step__two .payment-process-step__content {
  position: relative;
}

.payment-process-step__two .payment-process-step__content sup {
  left: -55px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-process-step__two .payment-process-step__content {
    margin-top: 60px;
  }
  .payment-process-step__two .payment-process-step__content sup {
    left: 5px;
  }
}

.payment-process-step__three .payment-process-step__content {
  position: relative;
}

.payment-process-step__three .payment-process-step__content sup {
  right: 40px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-process-step__three .payment-process-step__content {
    margin-top: 60px;
    margin-bottom: -100px;
  }
  .payment-process-step__three .payment-process-step__content sup {
    left: 5px;
  }
}

.payment-process-step__content {
  max-width: 500px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-process-step__content {
    max-width: 100%;
  }
}

.payment-process-step__content h4 {
  z-index: 1;
  position: relative;
}

.payment-process-step__content sup {
  position: absolute;
  top: 15px;
  transform: translateY(-50%);
  font-size: 138px;
  font-weight: 700;
  line-height: 1;
  color: #f3eff8;
  z-index: -1;
}

.payment-process-step__content .sub-text {
  font-size: 18px;
  line-height: 1.78;
}

.payment-process-step__content .payment-process-btn {
  display: flex;
  align-items: center;
}

.payment-process-step__content .payment-process-btn .sub-heading {
  margin-left: 15px;
  font-size: 15px;
}

@media only screen and (max-width: 575px) {
  .payment-process-step__content .payment-process-btn {
    flex-direction: column;
  }
  .payment-process-step__content .payment-process-btn .sub-heading {
    margin-left: 0px;
    margin-top: 15px;
  }
}

.payment-process-step__content .payment-process-btn .image-btn {
  margin-right: 20px;
}

@media only screen and (max-width: 575px) {
  .payment-process-step__content .payment-process-btn .image-btn {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

.section-table_gradeient {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #8481EC), color-stop(100%, #A990F2));
  background: -webkit-linear-gradient(left, #8481EC 0, #A990F2 100%);
  background: -o-linear-gradient(left, #8481EC 0, #A990F2 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #8481EC), to(#A990F2));
  background: linear-gradient(left, #8481EC 0, #A990F2 100%);
  background: -ms-linear-gradient(left, #8481EC 0, #A990F2 100%);
}

.section-spartor_svg_02.vc_row-separator.bottom {
  bottom: 0;
}

.section-spartor_svg_02.vc_row-separator svg {
  height: 350px;
}

.section-spartor_svg_02.vc_row-separator.tilt_right.top {
  transform: scale(-1, -1);
}

.section-spartor_svg_02.vc_row-separator svg {
  height: 350px;
}

.pricing-table-content-area, .payment-staps-way-wrap, .payment-staps-way-last_wrap {
  z-index: 2;
  position: relative;
}

.payment-pricing-table-bg {
  background: url(../images/bg/aeroland-payment-image-06.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom 466px center;
  z-index: -0;
}

/*======================================
   40. App Landing Pages Css
======================================*/
.feature-app-landing {
  background: #2d1b6e;
}

.feature-app-landing__wrap {
  max-width: 400px;
  width: 100%;
  margin-left: 100px;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-app-landing__wrap {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-app-landing__wrap {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-app-landing__wrap {
    margin-left: 0px;
    max-width: 100%;
  }
}

.feature-app-landing__inner {
  margin-bottom: 60px;
  margin-top: 30px;
}

.feature-app-landing__inner .section-title {
  margin-top: 90px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-app-landing__inner {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .feature-app-landing__inner .section-title {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-app-landing__inner:last-child {
    margin-bottom: 0px;
  }
}

.feature-app-landing__icon {
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border-radius: 40px;
  font-size: 34px;
  color: #fff;
  background-image: linear-gradient(-221deg, #e17643 0%, #f0a86b 100%);
  box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
  margin-bottom: 52px;
}

.feature-app-landing .app-landing__content {
  max-width: 600px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .feature-app-landing .app-landing__content {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-app-landing .app-landing__content {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-app-landing .app-landing__content {
    margin-left: 0px;
    max-width: 100%;
  }
}

.feature-app-landing .app-landing__content .sub-text {
  font-size: 18px;
  line-height: 1.78;
}

.app-landing--center-slider-wrapper {
  background: #33237A;
}

.app-landing-title-contet {
  display: flex;
}

.app-landing-title-contet .title-details_one {
  max-width: 287px;
  width: 100%;
  text-align: center;
}

.app-landing-title-contet .title-details_one h2 {
  font-size: 80px;
  line-height: 1;
  font-weight: 700;
}

.app-landing-title-contet .title-details_one h5 {
  font-size: 15px;
  line-height: 2;
  letter-spacing: 1em;
  font-weight: 500;
  text-transform: uppercase;
}

.app-landing-title-contet .title-details_two {
  margin-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app-landing-title-contet .title-details_one {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .app-landing-title-contet {
    flex-direction: column;
  }
  .app-landing-title-contet .title-details_one {
    text-align: left;
  }
  .app-landing-title-contet .title-details_two {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .app-landing-title-contet .title-details_two {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
  }
}

.sc-outer {
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app-landing-testimonial-slider__wrap {
  max-width: 830px;
  width: 100%;
  margin: auto;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.app-landing-testimonial-slider__wrap .post-thumbnail {
  flex-shrink: 0;
  margin-right: 90px;
}

.app-landing-testimonial-slider__wrap .testimonial-slider__text {
  font-size: 24px;
  line-height: 1.67;
  color: #fff;
}

.app-landing-testimonial-slider__wrap .testimonial-slider__author {
  padding-top: 15px;
  display: inline-block;
}

.app-landing-testimonial-slider__wrap .testimonial-slider__author .name {
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 9px;
}

@media only screen and (max-width: 767px) {
  .app-landing-testimonial-slider__wrap {
    flex-direction: column;
  }
  .app-landing-testimonial-slider__wrap .post-thumbnail {
    margin-bottom: 30px;
    margin-right: 0px;
  }
}

.app-landing-brand-logo-area {
  background: #161953;
  padding-bottom: 300px;
  position: relative;
}

.brand-details-wrap {
  max-width: 500px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.brand-details-wrap h4 {
  line-height: 1.42;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-details-wrap {
    margin-bottom: 30px;
  }
}

.app-landing-brand-logo__image {
  opacity: 0.3;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.app-landing-brand-logo__image:hover {
  opacity: 1;
}

.app-bottom-bg {
  background-image: url(../images/bg/aeroland-payment-image-02.png);
  background-repeat: no-repeat;
  background-position: top center;
}

.app-landing-bottom-banner-area {
  position: relative;
}

.app-landing-bottom-banner-area .vc_row-separator svg {
  fill: #161953;
}

.app-landing-bottom-banner-area .bottom-banner-images {
  margin-top: -220px;
  z-index: 4;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .applanding-ts.testimonial-slider-area.section-space--pt_120 {
    padding-top: 0;
  }
}

/*======================================
   41. Digital Agency Pages Css
======================================*/
.digital-agency-section-title h1, .digital-agency-section-title h2, .digital-agency-section-title h3, .digital-agency-section-title h4, .digital-agency-section-title h5, .digital-agency-section-title h6 {
  color: #333;
}

.digital-agency-section-title h3 {
  line-height: 1.4;
}

.digital-agency-section-title .section-sub-title {
  color: #ee7455;
  font-size: 14px;
}

.digital-agency-assistance__warap,
.digital-agency-working__warap {
  max-width: 370px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.digital-agency-assistance__warap:hover .digital-agency-assistance__box,
.digital-agency-working__warap:hover .digital-agency-assistance__box {
  transform: translateY(-5px);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .digital-agency-assistance__warap,
  .digital-agency-working__warap {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.digital-agency-assistance__box,
.digital-agency-working__box {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.digital-agency-assistance__images,
.digital-agency-working__images {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.digital-agency-assistance__images .images-midea,
.digital-agency-working__images .images-midea {
  text-align: center;
}

.digital-agency-assistance__images .images-midea img,
.digital-agency-working__images .images-midea img {
  width: 100%;
  transition: transform 1s;
}

.digital-agency-assistance__images .images-midea::after,
.digital-agency-working__images .images-midea::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .5;
  background-image: linear-gradient(-180deg, transparent 39%, #1c0f69 100%);
}

.digital-agency-assistance__images .images-midea:hover img,
.digital-agency-working__images .images-midea:hover img {
  transform: scale(1.1, 1.1);
}

.digital-agency-assistance__images .images-midea .heading,
.digital-agency-working__images .images-midea .heading {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
  width: 250px;
  margin: 0 auto;
  z-index: 1;
}

.digital-agency-assistance__content p,
.digital-agency-working__content p {
  color: #7e7e7e;
}

.digital-agency-assistance__content p strong,
.digital-agency-working__content p strong {
  color: #333;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .digital-agency-working__warap {
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .digital-agency-working__warap {
    align-items: center;
    max-width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.digital-agency-working__images .images-midea .heading {
  font-size: 18px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .digital-agency-working__images .images-midea .heading {
    margin-left: 15px;
  }
}

.how-it-works-bg {
  background-image: url(../images/patterns/aeroland-digital-agency-image-04.png);
  background-repeat: no-repeat;
  background-position: 42% center;
}

.how-it-works__content {
  max-width: 470px;
  width: 100%;
  margin-left: 115px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .how-it-works__content {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .how-it-works__content {
    margin-left: 0;
    margin-top: 40px;
  }
}

.how-it-works__content .section-title {
  color: #333;
  margin-bottom: 30px;
}

.how-it-works__content .text {
  font-size: 18px;
}

.how-it-works__content .check-list .list-item {
  font-size: 18px;
}

.agency-projects-gradient {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F6F5F9), color-stop(71%, #FFF));
  background: -moz-linear-gradient(top, #F6F5F9 0, #FFF 71%);
  background: -webkit-linear-gradient(top, #F6F5F9 0, #FFF 71%);
  background: -o-linear-gradient(top, #F6F5F9 0, #FFF 71%);
  background: -ms-linear-gradient(top, #F6F5F9 0, #FFF 71%);
  background: linear-gradient(top, #F6F5F9 0, #FFF 71%);
  z-index: -0;
}

.degital-agency-projects-wrap {
  margin-top: -30px;
}

.degital-project-text-bg {
  background: url(../images/agency/aeroland-digital-agency-image-06.png);
  background-repeat: no-repeat;
  background-position: top 240px center;
}

.agency-projects--wrap {
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.agency-projects--wrap a {
  display: block;
}

.agency-projects--wrap:hover .post-overlay-title {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
}

.agency-projects--wrap:hover .post-overlay-read-more {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.agency-projects__images img {
  border-radius: 5px;
}

.agency-projects__content {
  position: absolute;
  right: 30px;
  left: 30px;
  bottom: 21px;
}

.agency-projects__content .post-overlay-title {
  font-size: 18px;
  line-height: 1.78;
  color: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.agency-projects__content .post-overlay-read-more {
  font-size: 18px;
  line-height: 1.78;
  color: #fff;
  margin: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.agency-projects__content .post-overlay-read-more span {
  margin-left: 5px;
}

.agency-newsletter-section .newsletter-form--two input {
  background: #fff;
}

.degital-agency-start-working .contact-button a {
  font-size: 18px;
  line-height: 1.23;
  font-weight: 500;
}

.degital-agency-free-mode-wrap {
  overflow: hidden;
}

.degital-agency-free-mode-wrap .digital-agency-section-title-area .agency-free-mode-button {
  margin-top: 30px;
  text-align: right;
}

.degital-agency-free-mode-wrap .digital-agency-section-title-area .agency-free-mode-button a {
  min-width: 170px;
}

.degital-agency-free-mode-wrap .digital-agency-section-title-area .contact-button a {
  font-size: 18px;
  line-height: 1.23;
  font-weight: 500;
}

.degital-agency-free-mode-wrap .auto-plexible-row .swiper-slide {
  width: auto;
}

.degital-agency-free-mode-wrap .auto-plexible-row .single-flexible-slider img {
  height: auto;
}

.degital-agency-free-mode-wrap .flexible-image-slider-wrap {
  margin-right: -375px !important;
}

.agency-free-mode-bg {
  background: url(../images/patterns/aeroland-digital-agency-image-05.png);
  background-repeat: no-repeat;
  background-position: left -200px bottom 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .how-it-works__content.section-space--mb_60 {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .degital-agency-free-mode-wrap.section-space--pt_60 {
    padding-top: 0;
  }
}

/*=============================
    42. Ebook Css
==============================*/
.box-page {
  background: url(../images/patterns/body-special-bg-shape-01.png) right top no-repeat, url(../images/patterns/body-special-bg-shape-02.png) left top 570px no-repeat, url(../images/patterns/body-special-bg-shape-03.png) left bottom no-repeat, url(../images/patterns/body-special-bg-shape-04.png) right bottom no-repeat, #e4e8f6;
}

.site-wrapper-box {
  max-width: 1820px;
  margin: 0px auto;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.site-wrapper {
  background: #fff;
}

.ebook-chanters-bg {
  background: url(../images/patterns/aeroland-ebook-image-02.png);
  background-repeat: no-repeat;
  background-position: top left 45%;
}

.ebook-chanters-images {
  margin-top: 100px;
}

.ebook-chanters-images img {
  border-radius: 25px;
  border: 5px solid #e8e8e8;
  box-shadow: 40px 40px 100px rgba(0, 0, 0, 0.1);
}

.section-space-custom-pt {
  padding-top: 220px;
  margin-top: -100px;
}

@media only screen and (max-width: 767px) {
  .section-space-custom-pt {
    padding-top: 180px;
  }
}

.ebook-mailchip-title .heading {
  color: #6d70a6;
}

.ebook-mailchip-title .heading mark {
  color: #38cb89;
  background: none;
  text-decoration: none;
}

.newsletter-form--ebook input {
  width: 100%;
  background: transparent !important;
  border: 0;
  border-radius: 0;
  border-bottom: 3px solid #000;
  border-bottom-color: black;
  text-align: center;
  font-size: 48px;
  padding: 14px 0 23px;
  border-color: rgba(89, 69, 230, 0.2);
  color: #5945e6;
}

@media only screen and (max-width: 767px) {
  .newsletter-form--ebook input {
    font-size: 20px;
  }
}

/*======================================
    43. Clinic Pages Css
======================================*/
.clinic-hero-wrapper {
  overflow: hidden;
  padding: 205px 0 110px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFF), color-stop(80%, #ECEEFA));
  background: -webkit-linear-gradient(top, #FFF 0, #ECEEFA 80%);
  background: -o-linear-gradient(top, #FFF 0, #ECEEFA 80%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFF), color-stop(80%, #ECEEFA));
  background: linear-gradient(top, #FFF 0, #ECEEFA 80%);
  background: -ms-linear-gradient(top, #FFF 0, #ECEEFA 80%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clinic-hero-wrapper {
    padding: 150px 0 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clinic-hero-wrapper {
    padding: 100px 0 110px;
  }
}

@media only screen and (max-width: 767px) {
  .clinic-hero-wrapper {
    padding: 60px 0 110px;
  }
}

.clinic-hero-title .heading {
  line-height: 1.29;
  font-weight: 700;
}

.clinic-hero-title .sub-text {
  line-height: 1.78;
  color: #6d70a6;
  font-size: 18px;
}

.clinic-hero-bottom {
  margin-bottom: -115px;
  position: relative !important;
}

.clinic-hero-bottom .clinic-hero-image img {
  position: relative;
  z-index: 4;
}

.clinic-hero-bottom .vc_row-separator svg {
  height: 220px;
}

.clinic-hero-bottom .vc_row-separator svg {
  fill: #fff;
  z-index: 0;
}

.ht-contact-hero-box--clinic {
  background: #fff;
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
  padding: 12px 10px;
  border-radius: 8px;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

@media only screen and (max-width: 767px) {
  .ht-contact-hero-box--clinic {
    flex-direction: column;
    text-align: center;
  }
}

.ht-contact-hero-box--clinic .clinic-form-item {
  padding: 10px;
  position: relative;
  flex-grow: 1;
}

.ht-contact-hero-box--clinic .clinic-form-item .form-icon {
  left: 28px;
  right: auto;
  top: 25px;
  z-index: 2;
  font-size: 15px;
  position: absolute;
}

.ht-contact-hero-box--clinic .clinic-form-item input {
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  background: #f6f5f9;
  border-color: #f6f5f9;
  color: inherit;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px 20px;
  padding-left: 45px;
  height: 56px;
}

.ht-contact-hero-box--clinic .clinic-form-item.form-submit {
  flex-grow: 0;
  flex-shrink: 0;
}

.ht-contact-hero-box--clinic .clinic-form-item input:focus {
  border-color: #5945e6;
}

.clinic-work-step-wrap {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #F5F7FD), color-stop(63%, #FFF));
  background: -moz-linear-gradient(top, #F5F7FD 10%, #FFF 63%);
  background: -webkit-linear-gradient(top, #F5F7FD 10%, #FFF 63%);
  background: -o-linear-gradient(top, #F5F7FD 10%, #FFF 63%);
  background: -ms-linear-gradient(top, #F5F7FD 10%, #FFF 63%);
  background: linear-gradient(top, #F5F7FD 10%, #FFF 63%);
}

.clinic-work-process-step__content {
  max-width: 470px;
  width: 100%;
}

.clinic-work-process-step__content .sub__title {
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.34;
}

.clinic-work-process-step__content .sub__title h6 {
  color: #38cb89;
  font-size: 15px;
}

.clinic-work-process-step__content .sub-text {
  font-size: 18px;
  line-height: 1.78;
  margin-top: 20px;
}

.images-left-ml {
  margin-left: -115px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .images-left-ml {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .images-left-ml {
    margin-bottom: 30px;
  }
}

.images-right-mr {
  margin-right: -115px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .images-right-mr {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .images-right-mr {
    margin-bottom: 30px;
  }
}

.clinic-fun-fact h5 {
  color: #6d70a6;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clinic-fun-fact h5 {
    font-size: 20px;
  }
  .clinic-fun-fact h5 br {
    display: none;
  }
}

.clinic-fun-fact .fun-fact--three::before {
  height: 100%;
  background: #d8d8d8;
}

@media only screen and (max-width: 575px) {
  .clinic-fun-fact .fun-fact--three::before {
    display: none;
  }
}

.clinic-fun-fact .col-md-3:first-child .fun-fact--three::before {
  display: none;
}

@media only screen and (max-width: 767px) {
  .clinic-fun-fact .col-md-3:nth-child(3) .fun-fact--three::before {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clinic-fun-fact .fun-fact__text {
    font-size: 13px;
  }
}

/*======================================
    44. Digital Store Pages Css
======================================*/
.digital-store-hero-area {
  background-image: url(../images/hero/aeroland-digital-store-hero-bg-reup.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.digital-store-hero__text {
  padding: 200px 0 90px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .digital-store-hero__text {
    padding: 100px 0 90px;
  }
}

.dighial-store-hero__sub {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
}

.dighial-store-hero__sub a {
  padding: 10px;
}

.dighial-store-hero__sub a:hover {
  transform: translateY(-5px);
}

.dighial-store-hero__sub p {
  color: #ffffff;
  font-size: 18px;
}

.digital-store-hero__image_meida {
  z-index: 1;
  position: relative;
}

.digital-store-main-content {
  background: url(../images/bg/aeroland-digital-store-image-01.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom 180px center;
}

.single-digital-product__wrap {
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-digital-product__wrap:hover {
  transform: translateY(-5px);
}

.single-digital-product__wrap .s-product-badge {
  position: absolute;
  top: 0;
  right: 0;
  height: 90px;
  width: 140px;
}

.single-digital-product__wrap .s-product-badge::before {
  content: '';
  position: absolute;
  top: -30px;
  right: -52px;
  height: 90px;
  width: 140px;
  transform: rotate(45deg);
  background-image: linear-gradient(60deg, #ffbe00 0%, #f76b1c 100%);
}

.single-digital-product__wrap .s-product-badge span {
  position: absolute;
  top: 22px;
  right: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.19;
  display: block;
  color: #fff;
  transform: rotate(45deg);
  width: 50px;
}

.single-digital-product__content {
  padding: 30px 40px 40px;
}

.single-digital-product__content .product-category {
  color: #6d70a6;
  margin-top: 5px;
}

.single-digital-product__content .product-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 26px;
}

.single-digital-product__content .product-footer .regular-price {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 18px;
  color: #ababab;
  padding: 0 5px;
  margin-right: 10px;
  text-decoration: line-through;
}

.single-digital-product__content .product-footer .s-product-price {
  margin-left: 12px;
}

.single-digital-product__content .product-footer .s-product-price .price {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  color: #38cb89;
  font-weight: 500;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .digital-store-countdown-wrapper.section-space--pt_120 {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .digital-store-countdown-wrapper.section-space--pt_120 {
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .digital-store-countdown-wrapper.section-space--pt_120 {
    padding-top: 0;
  }
}

/*======================================
    45. Product Showcase Pages Css
======================================*/
.attribute-image {
  margin-right: -15px;
}

.product-showcase-video-title {
  margin-left: 60px;
  margin-top: 30px;
}

.product-showcase-video-title .sub-heading {
  line-height: 2.14;
  letter-spacing: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #6d70a6;
  font-size: 15px;
  margin-bottom: 25px;
}

.product-showcase-video-title h3 {
  position: relative;
}

.product-showcase-video-title h3::before {
  content: '';
  position: absolute;
  top: 27px;
  left: -150px;
  height: 3px;
  width: 100px;
  background: currentColor;
}

@media only screen and (max-width: 767px) {
  .product-showcase-video-title {
    margin-left: 20px;
  }
}

.product-showcase-how-works .sub_text {
  max-width: 400px;
  font-size: 18px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .product-showcase-how-works-images {
    margin-top: 30px;
  }
}

.product-showcase-brand-logo.brand-logo--slider {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  text-align: center;
  position: relative;
  margin-bottom: 0px;
}

/*======================================
    46. Revolution Slider Css
======================================*/
#rev_slider_10_1_wrapper .uranus.tparrows {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0);
}

#rev_slider_10_1_wrapper .uranus.tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

#rev_slider_10_1_wrapper .uranus.tparrows:hover:before {
  opacity: 0.75;
}

#rev_slider_9_1_wrapper .zeus.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
}

#rev_slider_9_1_wrapper .zeus.tparrows:before {
  font-family: 'revicons';
  font-size: 20px;
  color: #ffffff;
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative;
}

#rev_slider_9_1_wrapper .zeus.tparrows.tp-leftarrow:before {
  content: '\e824';
}

#rev_slider_9_1_wrapper .zeus.tparrows.tp-rightarrow:before {
  content: '\e825';
}

#rev_slider_9_1_wrapper .zeus .tp-title-wrap {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border-radius: 50%;
}

#rev_slider_9_1_wrapper .zeus .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  transform: translatex(-100%);
  -webkit-transform: translatex(-100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
}

#rev_slider_9_1_wrapper .zeus.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(100%);
  -webkit-transform: translatex(100%);
}

#rev_slider_9_1_wrapper .zeus.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0);
  -webkit-transform: translatex(0);
  opacity: 1;
}

#rev_slider_9_1_wrapper .zeus.tparrows:hover .tp-title-wrap {
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}

#rev_slider_11_1_wrapper .zeus.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
}

#rev_slider_11_1_wrapper .zeus.tparrows:before {
  font-family: 'revicons';
  font-size: 20px;
  color: #ffffff;
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative;
}

#rev_slider_11_1_wrapper .zeus.tparrows.tp-leftarrow:before {
  content: '\e824';
}

#rev_slider_11_1_wrapper .zeus.tparrows.tp-rightarrow:before {
  content: '\e825';
}

#rev_slider_11_1_wrapper .zeus .tp-title-wrap {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border-radius: 50%;
}

#rev_slider_11_1_wrapper .zeus .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  transform: translatex(-100%);
  -webkit-transform: translatex(-100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
}

#rev_slider_11_1_wrapper .zeus.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(100%);
  -webkit-transform: translatex(100%);
}

#rev_slider_11_1_wrapper .zeus.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0);
  -webkit-transform: translatex(0);
  opacity: 1;
}

#rev_slider_11_1_wrapper .zeus.tparrows:hover .tp-title-wrap {
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}

/* digital-agency Css */
#rev_slider_2_1_wrapper .uranus.tparrows {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0);
}

#rev_slider_2_1_wrapper .uranus.tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

#rev_slider_2_1_wrapper .uranus.tparrows:hover:before {
  opacity: 0.75;
}

/* ebook Css */
#rev_slider_7_1_wrapper .zeus.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
}

#rev_slider_7_1_wrapper .zeus.tparrows:before {
  font-family: 'revicons';
  font-size: 20px;
  color: #ffffff;
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative;
}

#rev_slider_7_1_wrapper .zeus.tparrows.tp-leftarrow:before {
  content: '\e824';
}

#rev_slider_7_1_wrapper .zeus.tparrows.tp-rightarrow:before {
  content: '\e825';
}

#rev_slider_7_1_wrapper .zeus .tp-title-wrap {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border-radius: 50%;
}

#rev_slider_7_1_wrapper .zeus .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  transform: translatex(-100%);
  -webkit-transform: translatex(-100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
}

#rev_slider_7_1_wrapper .zeus.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(100%);
  -webkit-transform: translatex(100%);
}

#rev_slider_7_1_wrapper .zeus.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0);
  -webkit-transform: translatex(0);
  opacity: 1;
}

#rev_slider_7_1_wrapper .zeus.tparrows:hover .tp-title-wrap {
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}

/*====================================
    47. Blog Pages Css
======================================*/
.single-blog-item.lg-blog-item {
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.single-blog-item.lg-blog-item:last-child {
  padding-bottom: 60px;
  margin-bottom: 0px;
}

.single-blog-item.xs-list-blog-item {
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.single-blog-item.xs-list-blog-item:last-child {
  margin-bottom: 0;
}

.single-blog-item .post-feature img {
  border-radius: 5px;
}

.single-blog-item .post-quote {
  position: relative;
  padding: 78px 40px 53px;
  background: #f5f7fd;
  text-align: center;
}

.single-blog-item .post-quote .post-quote-text {
  line-height: 1.5;
  margin-bottom: 39px;
}

.single-blog-item .post-quote::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 54px;
  transform: translateX(-50%);
  height: 132px;
  width: 150px;
  background: url(../images/icons/quote.png) no-repeat;
}

.single-blog-item .post-quote .post-quote-name {
  color: #333;
}

.single-blog-item .post-link {
  background-color: #38cb89;
  position: relative;
  padding: 68px 20px 62px;
  text-align: center;
}

.single-blog-item .post-link a {
  color: #ffffff;
  word-wrap: anywhere;
}

.single-blog-item .post-link::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 112px;
  width: 112px;
  background: url(../images/icons/link.png) no-repeat;
}

.single-blog-item .post-audio-box {
  width: 100%;
  border: none;
}

.single-blog-item .post-info {
  margin-top: 20px;
}

.single-blog-item .post-info .post-categories {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.single-blog-item .post-info .post-title {
  margin-bottom: 15px;
}

.single-blog-item .post-info .post-meta {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.single-blog-item .post-info .post-meta .post-author {
  padding: 5px 10px 0;
}

.single-blog-item .post-info .post-meta .post-author .avatar-96 {
  width: 32px;
  vertical-align: middle;
  margin-right: 6px;
  border-radius: 50%;
}

.single-blog-item .post-info .post-meta .post-date {
  padding: 5px 10px;
}

.single-blog-item .post-info .post-meta .post-date .meta-icon {
  margin-right: 6px;
}

.single-blog-item .post-info .post-meta .post-comments {
  padding: 5px 10px;
}

.single-blog-item .post-info .post-meta .post-comments .meta-icon {
  margin-right: 6px;
}

.single-blog-item.blog-grid {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 32px rgba(51, 51, 51, 0.07);
}

.single-blog-item.blog-grid .post-info {
  flex-grow: 1;
  padding: 0px 28px 25px;
}

.single-blog-item.blog-grid .post-info .post-title {
  margin-bottom: 40px;
  line-height: 1.5;
}

.single-blog-item.blog-masonry {
  margin-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-blog-item.blog-masonry {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog-item.blog-masonry {
    margin-bottom: 30px;
  }
}

.single-blog-item.blog-masonry .post-info .post-title {
  line-height: 1.5;
}

.single-blog-item .post-read-more .btn-read-more {
  color: #6d70a6;
  font-weight: 500;
}

.single-blog-item .post-read-more .btn-read-more::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 1px;
  width: 40px;
  background: #ccc;
  margin-right: 15px;
}

.single-blog-item .post-read-more .btn-read-more:hover {
  color: #38cb89;
}

.post-list-wrapper {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .post-list-wrapper {
    flex-direction: column;
  }
}

.post-list-wrapper .post-feature {
  width: 270px;
  margin-right: 30px;
  flex-shrink: 0;
}

.post-list-wrapper .post-info {
  flex-grow: 1;
  margin-top: 0px;
}

@media only screen and (max-width: 767px) {
  .post-list-wrapper .post-info {
    margin-top: 20px;
  }
}

.quote-post-wrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%;
}

.quote-post-wrapper::before {
  content: '';
  padding-top: 100%;
}

.quote-post-wrapper .post-overlay {
  background: #f5f7fd;
  transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.quote-post-wrapper .post-info {
  padding: 74px 64px 51px;
}

.quote-post-wrapper .post-quote-text {
  font-size: 24px;
  line-height: 1.5;
}

.quote-post-wrapper .post-content {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
}

.quote-post-wrapper .post-content ::before {
  content: '';
  position: absolute;
  top: 36px;
  left: 0;
  background: url(../images/icons/metro-quote.png) no-repeat;
  width: 150px;
  height: 132px;
}

.quote-post-wrapper .post-quote-name {
  font-size: 24px;
  color: #333;
  margin: 170px 0 0;
}

.single-valid-post-wrapper {
  overflow: hidden;
}

.single-valid-post-wrapper:hover .single-valid__thum {
  transform: scale(1.05, 1.05);
}

.single-valid-post-wrapper .post-content {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
}

.single-valid-post-wrapper .post-info {
  padding: 30px 30px 48px;
  width: 100%;
  color: #fff;
}

.single-valid-post-wrapper .post-info .post-meta {
  display: flex;
  flex-wrap: wrap;
}

.single-valid-post-wrapper .post-info .post-meta .post-date {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.single-valid-post-wrapper .post-info .post-meta .post-date .meta-icon {
  margin-right: 6px;
}

.single-valid-post-wrapper .post-info .post-meta .post-categories {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.single-blog__item {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
}

.single-blog__item::before {
  content: '';
}

.single-blog__item::before {
  padding-top: 50%;
}

.single-blog__item .single-valid__thum {
  transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single-blog__item .single-valid__thum::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(-180deg, transparent 27%, rgba(0, 0, 0, 0.28) 100%);
}

blockquote {
  margin: 54px 65px 44px 70px !important;
  padding: 0 0 0 20px;
  font-size: 18px;
  font-style: italic;
  line-height: 1.78;
  border-left: 2px solid #eee;
  border-left-color: #38cb89;
  position: relative;
  color: #6d70a6;
}

@media only screen and (max-width: 767px) {
  blockquote {
    margin: 54px 65px 44px 30px !important;
  }
}

.entry-post-tags {
  display: flex;
}

.entry-post-tags .tagcloud-icon {
  font-size: 13px;
  margin-right: 9px;
  margin-bottom: 0;
}

.entry-post-tags .tagcloud a:hover {
  color: #38cb89;
}

.entry-post-share-wrap {
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e8f6;
  margin-bottom: 50px;
}

.entry-post-share {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .entry-post-share {
    justify-content: flex-start;
    margin-top: 20px;
  }
}

.entry-post-share .share-media .share-icon {
  color: #fff;
  background-color: #9C7AF2;
  background-image: linear-gradient(-129deg, #9C7AF2 0, #5E61E7 100%);
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 15px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  color: #fff;
  background-color: #9c7af2;
}

.entry-post-share .share-media {
  position: relative;
  margin-left: 10px;
}

.entry-post-share .share-list {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -12px);
  width: auto;
  white-space: nowrap;
  padding: 0 4px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
  user-select: none;
  filter: drop-shadow(0 2px 20px rgba(0, 0, 0, 0.06));
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .entry-post-share .share-list {
    transform: translate(-75%, -12px);
  }
}

.entry-post-share .share-list::before {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  border-top: 8px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .entry-post-share .share-list::before {
    transform: translateX(100%);
  }
}

.entry-post-share .share-list a {
  display: inline-block;
  font-size: 13px;
  padding: 12px;
  color: #555;
}

.entry-post-share.opened .share-list {
  transform: translate(-50%, -22px);
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .entry-post-share.opened .share-list {
    transform: translate(-75%, -12px);
  }
}

.entry-author .author-info {
  display: flex;
}

.entry-author .author-avatar {
  flex-shrink: 0;
  width: 170px;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .entry-author .author-avatar {
    width: 120px;
  }
}

.entry-author .author-social-networks .inner {
  display: inline-block;
  margin: 22px -9px 0;
}

.entry-author .author-social-networks .inner a {
  display: block;
  float: left;
  padding: 6px 9px;
  font-size: 13px;
  line-height: 1;
  color: #ababab;
}

.entry-author .author-biographical-info {
  margin-top: 15px;
  font-size: 14px;
  font-style: italic;
  color: #ababab;
}

.related-posts-wrapper {
  margin-top: 66px;
}

.related-posts-wrapper .single-valid-post-wrapper {
  position: relative;
  height: 100%;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  overflow: hidden;
}

.related-posts-wrapper .single-valid-post-wrapper:hover .single-valid__thum {
  opacity: 1;
  visibility: visible;
  transform: scale(1.1, 1.1);
}

.related-posts-wrapper .single-valid-post-wrapper:hover .post-info {
  opacity: 0;
  visibility: hidden;
}

.related-posts-wrapper .single-valid__thum {
  transition: all .3s, transform 1s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  visibility: hidden;
  border-radius: 5px;
}

.related-posts-wrapper .single-valid__thum::before {
  opacity: 0;
}

.related-posts-wrapper .post-info {
  padding: 85px 30px 10px;
  margin-top: 0px;
}

.related-posts-wrapper .post-info .post-categories {
  color: #b6b7d2;
}

.comment-list-wrapper {
  margin-top: 66px;
}

.comment-list {
  margin: 0;
  padding: 0;
}

.comment-list .comment {
  list-style-type: none;
}

.comment-list .comment-author {
  float: left;
}

.comment-list .comment-content {
  position: relative;
  overflow: hidden;
  margin-left: 100px;
}

.comment-list .meta {
  margin-bottom: 12px;
}

.comment-list .meta .fn {
  font-size: 15px;
  color: #333;
  display: inline-block;
  margin-bottom: 0;
}

.comment-list .meta .comment-datetime {
  position: relative;
  display: inline-block;
  padding-left: 15px;
  margin-left: 10px;
  font-size: 14px;
  font-style: italic;
  color: #ababab;
}

.comment-list .meta .comment-datetime::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  width: 3px;
  height: 3px;
  background: #d8d8d8;
  border-radius: 50%;
}

.comment-list .comment-actions a {
  margin-right: 20px;
  font-weight: 500;
  color: #6d70a6;
}

.comment-list .comment-actions a::before {
  content: '\f3e5';
  opacity: .5;
  margin-right: 10px;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.comment-list .comment-actions a:hover {
  color: #38cb89;
}

.comment-list .children {
  margin: 40px 0 20px 100px;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comment-list .children {
    margin: 40px 0 20px 40px;
  }
}

.comment-list .children li + li {
  margin-top: 35px;
}

.comment-list .comment-actions {
  margin-top: 16px;
}

.comment-submit-btn .ht-btn {
  padding: 0 54px;
}

.post-feature-details.post-quote {
  position: relative;
  padding: 48px 40px;
  background: #5945e6;
  text-align: center;
}

.post-feature-details.post-quote::before {
  display: none;
}

.post-feature-details.post-quote .quote-icon {
  color: #fff;
  font-size: 26px;
  margin-bottom: 35px;
}

.post-feature-details.post-quote .post-quote-text {
  line-height: 1.5;
  margin-bottom: 25px;
  color: #ffffff;
}

.post-feature-details.post-quote .post-quote-name {
  color: #ffffff;
}

.post-feature-details.post-link {
  padding: 82px 100px;
  background: #f7fbfe;
  font-size: 24px;
  line-height: 1.5;
}

.post-feature-details.post-link a {
  color: #38cb89;
  word-wrap: anywhere;
  font-weight: 400;
}

.post-feature-details.post-link::before {
  display: none;
}

/*=============================================
=          48. Portfolio Pages Css            =
=============================================*/
.single-portfolio-item, .single-portfolio-metro, .single-portfolio-metro-two {
  display: block;
  position: relative;
}

.single-portfolio-item:hover, .single-portfolio-metro:hover, .single-portfolio-metro-two:hover {
  transform: translateY(-5px);
}

.single-portfolio-item:hover .post-overlay, .single-portfolio-metro:hover .post-overlay, .single-portfolio-metro-two:hover .post-overlay {
  visibility: visible;
  opacity: 1;
}

.single-portfolio-item:hover .single-portfolio__content, .single-portfolio-metro:hover .single-portfolio__content, .single-portfolio-metro-two:hover .single-portfolio__content {
  visibility: visible;
  opacity: 1;
}

.single-portfolio-item:hover .overlay-categories, .single-portfolio-metro:hover .overlay-categories, .single-portfolio-metro-two:hover .overlay-categories {
  opacity: 1;
  transition: .5s ease-out .2s;
  transform: translateY(0);
}

.single-portfolio-item:hover .post-overlay-title, .single-portfolio-metro:hover .post-overlay-title, .single-portfolio-metro-two:hover .post-overlay-title {
  opacity: 1;
  transition: .5s ease-out .2s;
  transform: translateY(0);
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-portfolio-item img, .single-portfolio-metro img, .single-portfolio-metro-two img {
    width: 100%;
  }
}

.single-portfolio-item:hover {
  transform: translateY(0px);
}

.single-portfolio-metro:hover, .single-portfolio-metro-two:hover {
  transform: translateY(0px);
}

.single-portfolio-metro .post-overlay.gradient-background, .single-portfolio-metro-two .post-overlay.gradient-background {
  border-radius: 0px;
}

.single-portfolio__thumbnail .border-radus-5 {
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .single-portfolio__thumbnail img {
    width: 100%;
  }
}

.post-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  border-radius: inherit;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.post-overlay.gradient-background {
  color: #fff;
  background-color: #9C7AF2;
  background-image: linear-gradient(-129deg, #9C7AF2 0, #5E61E7 100%);
  border-radius: 5px;
}

.single-portfolio__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  visibility: hidden;
  opacity: 0;
}

.single-portfolio__content .post-overlay-info {
  padding: 25px;
  text-align: center;
}

.single-portfolio__content .overlay-categories {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0;
  transition: .3s ease-out 0s;
  transform: translateY(-10px);
  margin-bottom: 10px;
  color: #fff;
}

.single-portfolio__content .post-overlay-title {
  opacity: 0;
  transition: .3s ease-out 0s;
  transform: translateY(10px);
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
  margin: 0;
}

.portfolio-grid-caption {
  border-radius: 5px;
  background: #fff;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.portfolio-grid-caption a {
  display: block;
}

.portfolio-grid-caption .post-info {
  padding: 32px 30px 39px;
}

.portfolio-grid-caption .post-info .post-categories {
  margin-bottom: 13px;
  color: #b6b7d2;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.portfolio-grid-caption:hover {
  box-shadow: 0 22px 32px rgba(51, 51, 51, 0.1);
  transform: translateY(-5px);
}

.loading-item, .loading-item-4 {
  display: none;
}

.load-more-button .ht-btn {
  margin-top: 30px;
}

.load-more-button .ht-btn.noContent {
  line-height: 12px;
  height: 0;
  border-width: none;
  background: transparent;
  border-color: transparent;
  color: #555;
  margin-top: 0px;
}

.single-portfolio-metro {
  display: block;
  position: relative !important;
  width: 100%;
  height: 100%;
}

.single-portfolio-metro::before {
  padding-top: 50%;
}

.single-portfolio-metro .bg-item-images::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.single-portfolio-metro-two::before {
  padding-top: 50%;
}

.single-portfolio-metro-two .bg-item-images::before {
  content: '';
  display: block;
  padding-top: 78%;
}

.single-portfolio-list-item {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.single-portfolio-list-item .post-info .post-categories {
  margin-bottom: 15px;
  color: #b6b7d2;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.single-portfolio-list-item .post-info.list-item-space {
  padding-left: 30px;
  padding-top: 31px;
}

@media only screen and (max-width: 767px) {
  .single-portfolio-list-item .post-info.list-item-space {
    padding-left: 0px;
  }
}

.single-portfolio-list-item .post-info.caption-item-space {
  margin-top: 30px;
  margin-bottom: 50px;
}

.single-portfolio-list-item:last-child {
  padding-bottom: 0px;
  margin-bottom: 0;
}

/*=============================================
=      49. Portfolio Details Pages Css         =
=============================================*/
.portfolio-details-section {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .portfolio-details-section {
    padding-top: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-details-section {
    padding-top: 10px;
  }
}

.portfolio-details-one-images {
  max-width: 540px;
}

.portfolio-details-grid-masonry {
  max-width: 720px;
}

.portfolio-details-content .portfolio-categories {
  margin-bottom: 10px;
  color: #b6b7d2;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.portfolio-details-table table {
  margin: 0 0 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  display: inline-table;
  overflow: hidden;
}

.portfolio-details-table .label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  color: #333;
  margin-bottom: 12px;
}

.portfolio-details-table td:last-child {
  text-align: right;
  padding-right: 0;
}

.portfolio-details-table td {
  border: 0;
  background: none !important;
  padding: 17px 10px;
  padding-right: 10px;
  text-align: left;
}

.portfolio-details-table td:first-child {
  padding-left: 0;
}

.portfolio-details-table tr + tr {
  border-top: 1px solid #ededed;
}

.portfolio-details-table .portfolio-details-share a {
  padding: 0 10px;
  font-size: 15px;
  color: #ababab;
}

.portfolio-details-grid-masonry {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media only screen and (max-width: 767px) {
  .portfolio-details-grid-masonry {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-details-grid-masonry {
    margin-bottom: 30px;
  }
}

.portfolio-details-grid-masonry .portfolio-details-grid-image img {
  width: 100%;
}

.portfolio-details-five-images {
  position: relative;
}

.portfolio-details-five-images .caption-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 14px;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  text-align: center;
}

.entry-portfolio-return-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 4px 32px rgba(51, 51, 51, 0.07);
  font-size: 18px;
}

.portfolio-nav-links .nav-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

@media only screen and (max-width: 767px) {
  .portfolio-nav-links .nav-list {
    grid-template-columns: auto;
  }
}

.portfolio-nav-links .nav-list .next {
  text-align: right;
}

.portfolio-nav-links .nav-list .inner div {
  display: flex;
  align-items: center;
  min-height: 190px;
  padding: 20px 0;
}

.portfolio-nav-links .nav-list .prev img {
  margin-right: 28px;
}

.portfolio-nav-links .nav-list .next img {
  margin-left: 28px;
  order: 2;
}

.portfolio-nav-links .nav-list .next a {
  padding-right: 30px;
}

.portfolio-nav-links .nav-list .prev a {
  padding-left: 30px;
}

.portfolio-nav-links .nav-list .inner h6::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}

.portfolio-nav-links .nav-list .prev h6::before {
  left: 15px;
  content: '\f104';
}

.portfolio-nav-links .nav-list .next h6::before {
  right: 15px;
  content: '\f105';
}

/*=============================================
=             50. Shop Pages Css               =
=============================================*/
.archive-shop-actions-wrap {
  padding-bottom: 23px;
  border-bottom: 1px solid #ededed;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .shop-filter-left .shop-result-count {
    margin-bottom: 10px;
  }
}

.shop-filter-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.shop-view-switcher {
  font-size: 14px;
  margin: 0 -10px 0 30px;
  display: flex;
  align-items: center;
}

.shop-view-switcher .switcher-item {
  color: #ccc;
  display: block;
  padding: 10px;
  line-height: 1;
}

.shop-view-switcher .switcher-item.active {
  color: #333;
}

.modern-grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .modern-grid-5 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modern-grid-5 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .modern-grid-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 575px) {
  .modern-grid-5 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.single-product-wrap .product-thumbnail {
  position: relative;
  overflow: hidden;
}

.single-product-wrap .product-thumbnail a {
  display: block;
}

.single-product-wrap .product-thumbnail a img {
  width: 100%;
}

.single-product-wrap .product-hover-image {
  transition: all .6s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
}

.single-product-wrap .product-thumbnail-box img {
  transition: all .6s;
  width: 100%;
}

.single-product-wrap .product-badges {
  position: absolute;
  top: 10px;
  right: 8px;
  z-index: 99;
  display: flex;
}

.single-product-wrap .product-badges > span {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin: 0 2px;
  padding: 6px 6px 4px;
  min-height: 0;
  min-width: 56px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.single-product-wrap .product-badges .hot {
  color: #fff;
  background-color: #d3122a;
}

.single-product-wrap .product-badges .onsale {
  color: #fff;
  background-color: #f6b500;
}

.single-product-wrap .product-actions {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  color: #fff;
  padding-right: 8px;
  background-color: #9C7AF2;
  background-image: linear-gradient(-129deg, #9C7AF2 0, #5E61E7 100%);
  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-product-wrap .product-actions .p-action-btn {
  padding: 0px 8px;
  color: #ffffff;
}

.single-product-wrap:hover .product-actions {
  transform: none;
  visibility: visible;
  opacity: 1;
}

.single-product-wrap:hover .product-thumb-image img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.single-product-wrap:hover .product-hover-image {
  z-index: 3;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.single-product-wrap.list-style {
  display: flex;
  border-bottom: 1px solid #e4e8f6;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.single-product-wrap.list-style .product-thumbnail {
  position: relative;
  overflow: hidden;
  width: 260px;
  flex-shrink: 0;
}

.single-product-wrap.list-style .product-info {
  flex-grow: 1;
  padding-left: 34px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .single-product-wrap.list-style {
    flex-direction: column;
  }
  .single-product-wrap.list-style .product-thumbnail {
    width: 100%;
  }
  .single-product-wrap.list-style .product-info {
    padding-left: 0px;
  }
}

.single-product-wrap .product-cart-btn {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px;
  color: #fff;
  background: none;
  transform: none;
  line-height: 56px;
  height: 56px;
  font-weight: 500;
}

.single-product-wrap .product-cart-btn::before {
  content: '\f290' !important;
  font-size: 15px;
  content: '\f360';
  position: absolute;
  left: 0;
  top: 19px;
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-product-wrap .product-cart-btn:hover {
  padding-left: 35px;
  color: #ffffff;
}

.single-product-wrap .product-cart-btn:hover::before {
  left: 15px;
  visibility: visible;
  opacity: 1;
  color: #ffffff;
}

.single-product-wrap .product-info {
  margin-top: 20px;
  text-align: center;
}

.single-product-wrap .product-info .product__title {
  font-weight: 400;
  font-size: 16px;
}

.single-product-wrap .product-info .price-box {
  margin-top: 5px;
}

.single-product-wrap .product-info .price-box .old-price {
  font-size: .88em;
  font-weight: 400;
  color: #b6b7d2;
  text-decoration: line-through;
}

.single-product-wrap .product-info .price-box .new-price {
  font-size: 14px;
  font-weight: 500;
  color: #6d70a6;
}

.modal-wrapper.modal {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: -9;
}

.modal-wrapper.modal.show {
  visibility: visible;
  opacity: 1;
  z-index: 99999999;
}

.modal-wrapper {
  display: block !important;
}

.modal-wrapper .modal-content {
  display: block !important;
}

.modal-wrapper .modal-dialog {
  max-width: 1170px;
}

.modal-wrapper .modal-body {
  padding: 40px 40px 40px 40px;
}

.modal-wrapper .close {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 7px;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  font-style: normal;
  font-size: 28px;
  font-weight: 400;
}

.product-details-view-content {
  margin-right: 30px;
  margin-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .product-details-view-content {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-view-content {
    margin-top: 25px;
  }
}

.product-list-view .price-box {
  margin-top: 15px;
}

.product-list-view .price-box .old-price {
  font-size: 16px;
  font-weight: 400;
  color: #b6b7d2;
  text-decoration: line-through;
}

.product-list-view .price-box .new-price {
  font-size: 18px;
  line-height: 1.34;
  font-weight: 500;
  color: #6d70a6;
}

.product-list-view .product-list-view-actions {
  margin-top: 28px;
}

.product-list-view .product-list-view-actions .item {
  display: inline-block;
  margin-right: 13px;
}

@media only screen and (max-width: 575px) {
  .product-list-view .product-list-view-actions .item {
    margin-top: 15px;
    margin-right: 5px;
  }
}

.product-list-view .product-list-view-actions .item .p-action-btn {
  border: 1px solid #888;
  line-height: 38px;
  height: 54px;
  padding: 8px 20px;
  display: block;
  border-radius: 5px;
  background-size: 200% auto;
  background-position: left center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.product-list-view .product-list-view-actions .item .p-action-btn:hover {
  color: #ffffff;
  background-image: linear-gradient(218deg, #5E61E7 0, #9C7AF2 50%, #5E61E7 100%);
  background-position: right center;
  transform: translateY(-3px);
  border: 1px solid transparent;
}

.product-list-view .quantity-wrap {
  margin: 25px 0 0px 0;
}

.product-list-view .quantity > label {
  display: inline-block;
  margin: 12px 0 0 0;
  min-width: 81px;
  padding-right: 15px;
  font-weight: 500;
  color: #333;
}

.product-list-view .quantity-box {
  position: relative;
  width: 100px;
}

.product-list-view .input-text.qty {
  padding: 3px 15px 3px 10px;
  width: 100%;
  height: 48px;
  font-size: 15px;
  font-weight: 500;
  color: #333;
  text-align: center;
  border: none;
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 5px;
  color: #333;
}

.product-list-view .single-product-meta {
  border-top: 1px solid #f3f3f3;
  padding-top: 25px;
  margin-top: 30px;
  font-size: 14px;
  color: #ababab;
}

.product-list-view .single-product-meta .meta-item {
  display: flex;
}

.product-list-view .single-product-meta .meta-item h6 {
  font-size: 14px;
  min-width: 100px;
  flex-shrink: 0;
  margin: 0 15px 0 0;
  color: #333;
  font-weight: 400;
}

.product-list-view .single-product-meta .meta-item ~ .meta-item {
  margin-top: 9px;
}

.product-list-view .single-product-share a {
  padding: 0 7px;
  font-size: 13px;
}

.product-large-slider .large-img img, .product-large-slider .nav-thumb img, .product-nav .large-img img, .product-nav .nav-thumb img {
  cursor: pointer;
}

.product-large-slider .slick-arrow, .product-nav .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  padding: 0;
  border: none;
  background: transparent;
  color: #ffffff;
  z-index: 77;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #333;
  font-size: 28px;
  background: none;
  border: 0;
  text-align: center;
}

.product-large-slider .slick-arrow.slick-next, .product-nav .slick-arrow.slick-next {
  right: 0px;
  left: auto;
}

.product-large-slider .slick-track, .product-nav .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.product-nav {
  margin: 10px -15px 0 -15px;
}

.product-nav .nav-thumb {
  padding: 0 15px;
  border: 1px solid transparent;
}

.product-details-tab .nav {
  justify-content: center;
  border-bottom: 1px solid #ddd;
}

.product-details-tab .nav li {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: 0 55px 0 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
}

.product-details-tab .nav li:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .product-details-tab .nav li {
    margin: 0 20px 0 0;
  }
}

.product-details-tab .nav li a {
  display: block;
  padding: 10px 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  color: #888;
}

.product-details-tab .nav li a::before {
  content: "";
  background: #5945e6;
  width: 0%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  -ms-filter: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: -2px;
}

.product-details-tab .nav li a.active {
  color: #333;
}

.product-details-tab .nav li a.active::before {
  visibility: visible;
  opacity: 1;
  -ms-filter: 1;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .product-details-tab .nav li a {
    font-size: 20px;
  }
}

.comment-form-rating label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-right: 10px;
}

.comment-form-rating .star-rating a {
  display: inline-block;
  color: #ffb805;
  line-height: 1;
  font-size: 14px;
  margin: 0;
}

/*=============================================
=            51. Shop Cart Css               =
=============================================*/
.cart-table .table thead th {
  padding: 20px 0;
  padding-left: 0;
  padding-right: 10px;
  border-bottom: none;
  text-align: left;
  color: #2e3280;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
}

.cart-table .table td {
  padding: 20px 0;
  padding-right: 10px;
  padding-left: 0;
  vertical-align: middle;
  text-align: left;
}

.cart-table .table tr {
  border-bottom: 1px solid #ededed;
}

.cart-table .plantmore-product-thumbnail {
  width: 110px;
}

.cart-table .plantmore-product-thumbnail img {
  width: 80px;
}

.cart-table .plantmore-product-quantity .quantity-box {
  position: relative;
  width: 100px;
}

.cart-table .plantmore-product-quantity .input-text.qty {
  padding: 3px 15px 3px 10px;
  width: 100%;
  height: 48px;
  font-size: 15px;
  font-weight: 500;
  color: #333;
  text-align: center;
  border: none;
  color: #ababab;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  border-radius: 5px;
  color: #333;
}

.cart-table .product-subtotal .amount {
  color: #5945e6;
}

.cart-table .remove {
  display: block;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1;
  border-radius: 100%;
  color: #d8d8d8;
  text-decoration: none;
  font-weight: 700;
  border: 0;
}

.cart-coupon-box input {
  border-color: transparent;
  background: rgba(0, 0, 0, 0.07);
  min-width: 275px;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
  margin-right: 15px;
  border: 1px solid transparent;
  margin-bottom: 20px;
}

.coupon2 .submit {
  margin-right: 15px;
  margin-bottom: 15px;
}

.shop_table {
  width: 100%;
}

.shop_table tr {
  padding-right: 10px;
  border-bottom: 1px solid #ededed;
}

.shop_table th {
  vertical-align: middle;
  padding: 20px 0;
  padding-right: 10px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #2e3280;
}

.shop_table td {
  vertical-align: middle;
  padding: 20px 0;
  padding-right: 10px;
}

.shop_table td strong {
  font-weight: 500;
}

/*=============================================
=            52. Shop Checkout Css               =
=============================================*/
.coupon-message-box {
  background: #5bc0de;
  position: relative;
  width: 100%;
  border: 0;
  color: #fff;
  margin-bottom: 30px !important;
  padding: 1em 2em 1em 2em;
  list-style: none outside;
  word-wrap: break-word;
  border-radius: 5px;
}

.coupon-message-box .coupon {
  cursor: pointer;
  font-weight: 600;
}

.checkout-coupon input {
  border-color: transparent;
  background: #F8F8F8;
  min-width: 275px;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
  margin-right: 15px;
  border: 1px solid transparent;
  margin-bottom: 20px;
}

.coupon-content, .ship-box-info {
  display: none;
}

.shoping-checkboxt-title {
  font-size: 24px;
  font-weight: 500;
  color: #2e3280;
  margin-bottom: 25px;
}

.checkout-box-wrap .check-box {
  margin-right: 15px;
}

.single-form-row input, .single-form-row .checkout-mess {
  border-color: transparent;
  background: #F8F8F8;
  width: 100%;
  border-radius: 5px;
  padding: 3px 20px;
  height: 56px;
  margin-right: 15px;
  border: 1px solid transparent;
  margin-bottom: 20px;
}

.single-form-row .checkout-mess {
  height: 140px;
  padding: 15px;
}

/*=====================================
=       53. Preview Page Css          =
======================================*/
.hero__purchase .btn:hover, .downlode-btn .btn:hover {
  background: #fff;
  color: #5945e6;
}

.header__actions--preview a {
  font-weight: 400;
}

.header__actions--preview a:hover, .header__actions--preview a:focus {
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header__actions--preview {
    flex-basis: 70%;
  }
}

.preview-menu .navigation-menu > ul > li > a {
  font-weight: 400;
  text-transform: uppercase;
}

.single-preview-item__thumbnail {
  position: relative;
  margin-bottom: 29px;
  box-shadow: 0 5px 43px rgba(51, 51, 51, 0.1);
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.single-preview-item__thumbnail img {
  border-radius: 8px;
}

.single-preview-item__wrap a {
  display: block;
}

.single-preview-item__wrap:hover .single-preview-item__thumbnail {
  box-shadow: 0 41px 43px rgba(51, 51, 51, 0.1);
  transform: translateY(-20px);
}

.preview-staps-way .payment-process-step__one .payment-process-step__content {
  margin-top: 0px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .preview-staps-way .payment-process-step__media {
    margin-bottom: 30px;
  }
}

.preview-staps-way .process-step-3 {
  margin-top: -200px;
  margin-left: -30px;
}

@media only screen and (max-width: 767px) {
  .preview-staps-way .process-step-3 {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.branding-preview-wrap {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFF), color-stop(80%, #F6F5F9));
  background: -moz-linear-gradient(top, #FFF 0, #F6F5F9 80%);
  background: -webkit-linear-gradient(top, #FFF 0, #F6F5F9 80%);
  background: -o-linear-gradient(top, #FFF 0, #F6F5F9 80%);
  background: -ms-linear-gradient(top, #FFF 0, #F6F5F9 80%);
  background: linear-gradient(top, #FFF 0, #F6F5F9 80%);
}

.preview-staps-way {
  background: url(../images/bg/landing-image-04.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.auto-plexible-row .single--slider {
  padding: 30px 0;
}

.auto-plexible-row .single--slider > img {
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(51, 51, 51, 0.1);
}

.branding-flexible-image-slider-wrap {
  overflow: hidden;
}

.branding-flexible-image-slider-wrap .swiper-nav-button {
  font-size: 70px;
  opacity: 1;
  visibility: visible;
  background: transparent;
}

.branding-flexible-image-slider-wrap .swiper-nav-button::before {
  background: none;
}

.branding-flexible-image-slider-wrap .swiper-nav-button:hover {
  color: #5945e6;
}

.branding-flexible-image-slider-wrap .swiper-button-prev {
  left: -50px;
}

.branding-flexible-image-slider-wrap .swiper-button-next {
  right: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .branding-flexible-image-slider-wrap .swiper-button-prev {
    left: 0px;
  }
  .branding-flexible-image-slider-wrap .swiper-button-next {
    right: 0px;
  }
}

.prv-section-footer-bg {
  background: url(../images/bg/landing-image-01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-preview-content h6 {
  letter-spacing: 7px;
}
