.saas-banner-background {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 500px;
    & .banner__text {
        font-size: 18px;
        line-height: 1.78;
    }
    & .saas-background__links {
        & img {
            border-radius: 5px;
            margin-right: 10px;
        }
    }
}