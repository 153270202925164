/*=============================================
=            51. Shop Cart Css               =
=============================================*/
.cart-table {
    & .table {
        & thead {
            & th {
                padding: 20px 0;
                padding-left: 0;
                padding-right: 10px;
                border-bottom: none;
                text-align: left;
                color: $theme-color--heading;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                line-height: 1.2;
                
            }
        }
        & td {
            padding: 20px 0;
            padding-right: 10px;
            padding-left: 0;
            vertical-align: middle;
            text-align: left;
        }
        & tr {
            border-bottom: 1px solid #ededed;
        }
    }
    & .plantmore-product-thumbnail {
        width: 110px;
        & img {
            width: 80px;
        }
    }
    & .plantmore-product-quantity {
        & .quantity-box {
            position: relative;
            width: 100px;
        }
        & .input-text.qty {
            padding: 3px 15px 3px 10px;
            width: 100%;
            height: 48px;
            font-size: 15px;
            font-weight: 500;
            color: #333;
            text-align: center;
            border: none;
            color: #ababab;
            border-color: #f8f8f8;
            background-color: #f8f8f8;
            border-radius: 5px;
            color: #333;
        }
    }
    & .product-subtotal {
        & .amount {
            color: $theme-color--default;
        }
    }
    & .remove {
        display: block;
        font-size: 1.5em;
        height: 1em;
        width: 1em;
        text-align: center;
        line-height: 1;
        border-radius: 100%;
        color: #d8d8d8;
        text-decoration: none;
        font-weight: 700;
        border: 0;
    }
    
}
.cart-coupon-box input {
    border-color: transparent;
    background: rgba(0, 0, 0, 0.07);
    min-width: 275px;
    border-radius: 5px;
    padding: 3px 20px;
    height: 56px;
    margin-right: 15px;
    border: 1px solid transparent;
    margin-bottom: 20px;
}
.coupon2 {
    & .submit {
        margin-right: 15px;
        margin-bottom: 15px;
    }
}
.shop_table{
    width: 100%;
    & tr {
        padding-right: 10px;
        border-bottom: 1px solid #ededed;
    }
    &  th {
        vertical-align: middle;
        padding: 20px 0;
        padding-right: 10px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;
        color: $theme-color--heading;
    }
    & td {
        vertical-align: middle;
        padding: 20px 0;
        padding-right: 10px;
        strong {font-weight: 500;}
        
    }
}

