
/*======================================
    Start Ups Pages Css
======================================*/



.start-ups-hero {
    &--bg__images {
        background: url(../images/bg/aeroland-startup-hero-bg-reup.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
    }
        
    &__content {
        padding-top: 150px;
        @media #{$laptop-device}{
            padding-top: 100px;
        }
        @media #{$desktop-device}{
            padding-top: 50px;
        }
        @media #{$large-mobile,$tablet-device}{
            padding-top: 0px;
        }
    }
    
    &__text {
        max-width: 530px;
        width: 100%;
        color: $white;
        & h1 {
            color: $white;
        }
        & .text-hero {
            margin-top: 10px;
            font-size: 18px;
            line-height: 1.78;
        }
        & .hero-btn-wrap {
            display: flex;
            align-items: center;
            @media #{$small-mobile}{
                flex-direction: column;
                .sub-heading {
                    margin-left: 0px;
                    margin-top: 15px;
                }
            }
            .sub-heading {
                margin-left: 25px;
                font-size: 15px;
                color: $white;
            }
        }
        
    }
    &--images__midea {
        z-index: 2;
        position: relative;
        @media #{$large-mobile}{
            margin-top: 30px;
        }
    }
    
}
.section-space--start-pt {
    padding-top: 120px;
    @media #{$desktop-device}{
        padding-top: 20px;
    }
    @media #{$tablet-device}{
        padding-top: 60px;
    }
    @media #{$large-mobile}{
        padding-top: 40px;
    }
}
.ht-start-up-section-bg {
    background: url(../images/bg/aeroland-startup-image-05.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.modern-grid__box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media #{$tablet-device,$large-mobile}{
        justify-content: center;
    }
}

.start-ups-left-tab {
    
    & .tab-slider-nav--list .ht-slider-tab-icon {
        background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%);
    }
    & .tab-slider-nav--list h5 {
        color: #2e3280;
    }
    & .tab-slider-nav--list p {
        color: #696969;
    }
    & .tab-slider-images .slick-dots li button {
        background: #B6B7D2;
    }
    
    & .tab-slider-images .slick-dots li.slick-active button {
        background: #5945e6;
    }
}

.banner-gradient-bg {
    background: url(../images/patterns/aeroland-startup-image-03.png)top right 170px no-repeat, linear-gradient(-259deg,#8383ED 0,#B095F5 65%); 
}

.banner-content__start-up {
    & .banner__text {
        font-size: 18px;
        line-height: 1.78;
    }
    & .start-ups-background__links img {
        border-radius: 5px;
        margin-right: 10px;
    }
}

