
/*=============================================
=            35. Feature slider Css           =
=============================================*/

.feature-slider{
    &__single-slide{
        .feature-slider__single-slide-wrapper{
            padding: 0 15px;
            .image{
                text-align: right;
                opacity: 0;
                transform: translateX(-100%);
                transition-delay: .5s;
                transition: $transition--default;
                margin-right: 20px;
                @media #{$large-mobile}{
                    margin-right: 0;
                }
            }
            .content{
                margin-bottom: 50px;
                width: 570px;
                max-width: 100%;
                background: #fff;
                border-radius: 8px;
                box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
                padding: 54px 50px 52px;
                transition-delay: .5s;
                opacity: 0;
                transform: translateX(100%);
                transition: $transition--default;

                @media #{$large-mobile}{
                    margin-top: 30px;
                }

                @media #{$small-mobile}{
                    padding: 30px;
                }

                .title{
                    line-height: 1.5;
                    color: $theme-color--black;
                    font-weight: 400;
                    margin-bottom: 15px;
                }
    
                .text{
                    font-size: 18px;
                    line-height: 1.8;
                }
            }
        }

        &.swiper-slide-active{
            .feature-slider__single-slide-wrapper{
                .image{
                    opacity: 1;
                    transform: translateX(0);
                }
                .content{
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
    }
}

/*=====  End of feature slider  ======*/

