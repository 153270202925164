/* =================
    Typed Text 
======================*/
.cd-headline.clip span{
    padding: 0;
}
.cd-headline.clip .cd-words-wrapper.brown-color::after{
    background-color: #ddd;
    width: 4px;
}
.cd-headline.clip span{
    padding: 0;
}
.cd-headline.clip .cd-words-wrapper.brown-color::after{
    background-color:#f10;
    width: 4px;
}
.typed-text-wrap{
    text-align: center;
    h4 {
        max-width: 700px;
        font-size: 48px;
        line-height: 1.34;
        margin-bottom: 0;
        word-wrap: break-word;
        font-weight: 500;
        margin-top: -20px;
        span{
            & b{
                font-weight: 300;
            }
        }
        @media #{$desktop-device}{
            
        }
        @media #{$tablet-device}{
            font-size: 38px;
        }
        @media #{$large-mobile}{
            font-size: 28px;
        }
        @media #{$small-mobile}{
            font-size: 20px;
        }
    }
}
