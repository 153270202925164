/*======================================
    46. Revolution Slider Css
======================================*/

#rev_slider_10_1_wrapper .uranus.tparrows {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0)
}

#rev_slider_10_1_wrapper .uranus.tparrows:before {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 40px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s
}

#rev_slider_10_1_wrapper .uranus.tparrows:hover:before {
    opacity: 0.75
}

#rev_slider_9_1_wrapper .zeus.tparrows {
    cursor: pointer;
    min-width: 70px;
    min-height: 70px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 50%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1)
}

#rev_slider_9_1_wrapper .zeus.tparrows:before {
    font-family: 'revicons';
    font-size: 20px;
    color: #ffffff;
    display: block;
    line-height: 70px;
    text-align: center;
    z-index: 2;
    position: relative
}

#rev_slider_9_1_wrapper .zeus.tparrows.tp-leftarrow:before {
    content: '\e824'
}

#rev_slider_9_1_wrapper .zeus.tparrows.tp-rightarrow:before {
    content: '\e825'
}

#rev_slider_9_1_wrapper .zeus .tp-title-wrap {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    border-radius: 50%
}

#rev_slider_9_1_wrapper .zeus .tp-arr-imgholder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    transform: translatex(-100%);
    -webkit-transform: translatex(-100%);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s
}

#rev_slider_9_1_wrapper .zeus.tp-rightarrow .tp-arr-imgholder {
    transform: translatex(100%);
    -webkit-transform: translatex(100%)
}

#rev_slider_9_1_wrapper .zeus.tparrows:hover .tp-arr-imgholder {
    transform: translatex(0);
    -webkit-transform: translatex(0);
    opacity: 1
}

#rev_slider_9_1_wrapper .zeus.tparrows:hover .tp-title-wrap {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1
}


#rev_slider_11_1_wrapper .zeus.tparrows {
    cursor: pointer;
    min-width: 70px;
    min-height: 70px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 50%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1)
}

#rev_slider_11_1_wrapper .zeus.tparrows:before {
    font-family: 'revicons';
    font-size: 20px;
    color: #ffffff;
    display: block;
    line-height: 70px;
    text-align: center;
    z-index: 2;
    position: relative
}

#rev_slider_11_1_wrapper .zeus.tparrows.tp-leftarrow:before {
    content: '\e824'
}

#rev_slider_11_1_wrapper .zeus.tparrows.tp-rightarrow:before {
    content: '\e825'
}

#rev_slider_11_1_wrapper .zeus .tp-title-wrap {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    border-radius: 50%
}

#rev_slider_11_1_wrapper .zeus .tp-arr-imgholder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    transform: translatex(-100%);
    -webkit-transform: translatex(-100%);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s
}

#rev_slider_11_1_wrapper .zeus.tp-rightarrow .tp-arr-imgholder {
    transform: translatex(100%);
    -webkit-transform: translatex(100%)
}

#rev_slider_11_1_wrapper .zeus.tparrows:hover .tp-arr-imgholder {
    transform: translatex(0);
    -webkit-transform: translatex(0);
    opacity: 1
}

#rev_slider_11_1_wrapper .zeus.tparrows:hover .tp-title-wrap {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1
}

/* digital-agency Css */
#rev_slider_2_1_wrapper .uranus.tparrows {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0)
}

#rev_slider_2_1_wrapper .uranus.tparrows:before {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 40px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s
}

#rev_slider_2_1_wrapper .uranus.tparrows:hover:before {
    opacity: 0.75
}

/* ebook Css */
#rev_slider_7_1_wrapper .zeus.tparrows {
    cursor: pointer;
    min-width: 70px;
    min-height: 70px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 50%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1)
}

#rev_slider_7_1_wrapper .zeus.tparrows:before {
    font-family: 'revicons';
    font-size: 20px;
    color: #ffffff;
    display: block;
    line-height: 70px;
    text-align: center;
    z-index: 2;
    position: relative
}

#rev_slider_7_1_wrapper .zeus.tparrows.tp-leftarrow:before {
    content: '\e824'
}

#rev_slider_7_1_wrapper .zeus.tparrows.tp-rightarrow:before {
    content: '\e825'
}

#rev_slider_7_1_wrapper .zeus .tp-title-wrap {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    border-radius: 50%
}

#rev_slider_7_1_wrapper .zeus .tp-arr-imgholder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    transform: translatex(-100%);
    -webkit-transform: translatex(-100%);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s
}

#rev_slider_7_1_wrapper .zeus.tp-rightarrow .tp-arr-imgholder {
    transform: translatex(100%);
    -webkit-transform: translatex(100%)
}

#rev_slider_7_1_wrapper .zeus.tparrows:hover .tp-arr-imgholder {
    transform: translatex(0);
    -webkit-transform: translatex(0);
    opacity: 1
}

#rev_slider_7_1_wrapper .zeus.tparrows:hover .tp-title-wrap {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1
}