/*=============================================
=            22. Pricing Plans Css           =
==============================================*/
.pricing-plans {
    &__inner {
        background: $white;
        border-radius: 5px;
        box-shadow: 0 0px 40px rgba(0, 0, 0, 0.06);
        & table {
            table-layout: fixed;
            margin: 0;
        }
        & th {
            padding: 0;
            min-width: 266px;
            &:first-child {
                text-align: left;
                width: 370px;
                border-left: 0;
            }
            &:last-child {
                border-right: 0;
            }
            
            & .pricing-header {
                position: relative;
                overflow: hidden;
                padding: 52px 30px 32px;
                
                
                & .ht-pricing-feature-mark {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 90px;
                    width: 140px;
                    &::before {
                        content: '';
                        position: absolute;
                        top: -30px;
                        right: -52px;
                        height: 90px;
                        width: 140px;
                        transform: rotate(45deg);
                        background-image: linear-gradient(60deg, #ffbe00 0%, #f76b1c 100%);
                    }
                    & span {
                        position: absolute;
                        top: 20px;
                        right: 7px;
                        text-align: center;
                        font-size: 11px;
                        font-weight: 700;
                        line-height: 1.19;
                        display: block;
                        color: #fff;
                        transform: rotate(45deg);
                        width: 50px;
                    }
                }
                
                &.heading {
                    font-size: 24px;
                    color: #2e3280;
                    font-weight: 500;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                & .title {
                    font-size: 14px;
                    text-transform: uppercase;
                    margin-bottom: 34px;
                    color: #2e3280;
                    font-weight: 500;
                }
                & .price-wrap-inner {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;

                    .currency{
                        font-size: 15px;
                        margin-bottom: 0;
                        color: #ababab;
                    }
                    .price{
                        font-size: 36px;
                        line-height: .9;
                        margin: 0 4px;
                        font-weight: 300;
                        color: #333;
                        font-weight: 500;
                    }
                    .period{
                        font-size: 15px;
                        margin-bottom: 0;
                        color: #ababab;
                        align-self: flex-end;
                    }   
                }
            }
            
        }
        & td , th {
            border: 0;
            border-left: 1px solid rgba(51, 51, 51, 0.1);
            border-right: 1px solid rgba(51, 51, 51, 0.1);
            text-align: center;
        }
        & .tbody {
            & th {
                padding: 0 30px;
            }
        }
        
        & td {
            &:first-child {
                border-left: 0;
            }
            &:last-child {
                border-right: 0;
            }
            & .pricing-feature-labels {
                text-align: left;
                & li {
                    padding-left: 20px;
                }
            }
        }
        & tbody {
            & ul {
                & li {
                    border-top: 1px solid rgba(51, 51, 51, 0.1);
                    padding: 9px 0;
                    & .item-checked {
                        color: #7ed321;
                        font-size: 16px;
                    }
                }
            }
        }
        & tfoot {
            & td {
                padding: 30px 10px 40px;
            }
        }
    }
}
.table-responsive {
    overflow-y: hidden;
}
